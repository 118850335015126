import React, { useMemo } from "react";
import { useTheme } from "../context/ThemeContext";
import Experience from "../components/Experience/Experience";

function Home() {
  const { theme } = useTheme();

  return <></>;
}

export default Home;
