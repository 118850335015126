import React, { useState, useEffect } from "react";
import { fetchCaseStudies } from "../../services/CaseStudyService";
import CaseStudyCard from "./CaseStudyCard";

const CaseStudyList = () => {
  const [caseStudies, setCaseStudies] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchCaseStudies();
        const sortedData = data.sort((a, b) => a.order - b.order);
        setCaseStudies(sortedData);
      } catch (error) {
        console.error("Error fetching experiences:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="case-study__list-wrapper">
      <h2>Case Studies</h2>

      <div className="case-studies__list">
        {caseStudies.map((caseStudy) => (
          <CaseStudyCard key={caseStudy.slug} caseStudy={caseStudy} />
        ))}
      </div>
    </div>
  );
};

export default CaseStudyList;
