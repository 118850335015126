// components/AvatarDialog/dialogueData.js
export const dialogues = [
  [
    "Hi there! I'm David.",
    "I support entrepreneurs with marketing and creative development.",
  ],
  [
    "I'm still feeling a little stumped about what to put on the homepage.",
    "So, those clouds in the background...",
  ],
  [
    "We're gonna say that's an empty brainstorm...",
    "Something's brewing in there, but lightning hasn't struck, yet.",
  ],
  [
    "Go ahead and click 'menu' in the top right corner to explore the site.",
    "I'll keep thinking about what to put here.",
  ],
];
