// styles/abstracts/themes.js

import { colors } from "./colors";

export const themes = {
  light: {
    navBg: colors.marigold100,
    navIcon: colors.marigold100,
    bodyBgPrimary: colors.marigold100,
    bodyBgSecondary: colors.balsamic100,

    primaryColor: colors.balsamic600,
    secondaryColor: colors.balsamic800,
    tertiaryColor: colors.balsamic900,

    accentColorPrimary: colors.marigold600,
    accentColorSecondary: colors.balsamic900,
    accentColorExtra: colors.balsamicBlack,

    textColorPrimary: colors.balsamic600,
    textColorSecondary: colors.balsamic100,
    textColorLight: colors.white,
    textColorDark: colors.balsamic600,
    linkColor: colors.cyprus300,

    iconColorPrimary: colors.balsamic600,
    iconColorSecondary: colors.balsamic700,
    iconColorTertiary: colors.balsamic100,
    iconColorSticker: colors.balsamic100,
    iconStrokeColor: colors.balsamic800,

    hoverColorPrimary: colors.marigold600,
    hoverColorSecondary: colors.white,

    cloudColor1: colors.balsamic900,
    cloudColor2: colors.balsamic100,
    cloudColor3: colors.balsamic900,

    thunderColor1: colors.balsamic200,
    thunderColor2: colors.balsamic200,
    thunderColor3: colors.balsamic200,

    lightColor1: colors.marigold100,
    lightColor2: colors.balsamic900,
    lightColor3: colors.balsamic100,
  },
  dark: {
    navBg: colors.raisin900,
    navIcon: colors.raisin900,
    bodyBgPrimary: colors.raisin800,
    bodyBgSecondary: colors.raisin800,

    primaryColor: colors.raisin600,
    secondaryColor: colors.raisin600,
    tertiaryColor: colors.raisin900,

    accentColorPrimary: colors.marigold600,
    accentColorSecondary: colors.marigold600,
    accentColorExtra: colors.raisinBlack,

    textColorPrimary: colors.raisin400,
    textColorSecondary: colors.raisin900,
    textColorLight: colors.raisin400,
    textColorDark: colors.raisin900,
    linkColor: colors.cyprus300,

    iconColorPrimary: colors.raisin600,
    iconColorSecondary: colors.raisin500,
    iconColorTertiary: colors.raisin400,
    iconColorSticker: colors.raisin300,
    iconStrokeColor: colors.raisin900,

    hoverColorPrimary: colors.marigold600,
    hoverColorSecondary: colors.white,

    cloudColor1: colors.raisin800,
    cloudColor2: colors.raisin900,
    cloudColor3: colors.cyprusBlack,

    thunderColor1: colors.raisin600,
    thunderColor2: colors.raisin700,
    thunderColor3: colors.raisin800,

    lightColor1: colors.raisin600,
    lightColor2: colors.raisin500,
    lightColor3: colors.raisin500,
  },
};
