import React from "react";
import Markdown from "react-markdown";
import Skeleton from "react-loading-skeleton";

function ImpactSection({ impacts, loading }) {
  return (
    <div className="case-study__impact-wrapper">
      <h2>{loading ? <Skeleton width={200} /> : "Impact:"}</h2>
      {loading ? (
        <Skeleton count={3} />
      ) : (
        impacts.map((impact) => (
          <div key={impact.id} className="case-study__impact">
            <Markdown>{impact.description}</Markdown>
          </div>
        ))
      )}
    </div>
  );
}

export default ImpactSection;
