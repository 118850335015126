import React from "react";
import SVGIcon from "../SVGIcon";
import styled from "styled-components";

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: 80%;

  .logo {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
  }

  ${({ theme, $isActive, $isHovered }) => `
    g path.nav, 
    path.nav {
      fill: ${$isActive || $isHovered ? theme.tertiaryColor : theme.navIcon};
    }
    
    g path.primary,
    polygon.primary {
      fill: ${$isActive || $isHovered ? theme.tertiaryColor : theme.textColorLight};
    }

    g path.primaryTint,
    polygon.primaryTint {
      fill: ${$isActive || $isHovered ? theme.textColorLight : theme.tertiaryColor};
    }

    g path.secondary,
    polygon.secondary {
      fill: ${$isActive || $isHovered ? theme.textColorLight : theme.tertiaryColor};
    }

    g path.secondaryTint,
    polygon.secondaryTint {
      fill: ${
        $isActive || $isHovered
          ? `${theme.tertiaryColor}B3`
          : `${theme.textColorLight}B3`
      };
    }
  `}
`;

function SlideOutLogo({ logoId, spriteSheetName, isActive, isHovered, theme }) {
  return (
    <LogoWrapper theme={theme} $isActive={isActive} $isHovered={isHovered}>
      <SVGIcon
        iconId={logoId}
        spriteSheetName={spriteSheetName}
        additionalClasses={["logo"]}
      />
    </LogoWrapper>
  );
}

export default SlideOutLogo;
