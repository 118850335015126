import React, {
  useState,
  useRef,
  useMemo,
  useEffect,
  useCallback,
} from "react";
import { useTheme } from "../../context/ThemeContext";
import { useViewport } from "../../context/ViewportContext";
import styled from "styled-components";
import ResumeLogo from "./ResumeLogo";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { breakpoints } from "../../styles/breakpoints";
import { typography } from "../../styles/abstracts/typography";
import "react-loading-skeleton/dist/skeleton.css";

import SlideOut from "../SlideOut/SlideOut";
import { createSlideOutAnimations } from "../SlideOut/anims";
import ResumeSlideOutTabs from "./ResumeSlideOutTabs";
import Scrollbar from "../Scrollbar/Scrollbar";
import { useSlideOut } from "../../context/SlideOutContext";

const SlideOutLogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0;
  grid-column: 1;
  grid-row: 2;

  ${({ $viewport }) => {
    const { width, isLandscape } = $viewport;
    if (width <= breakpoints.mobileS) {
      return `
        padding: 0.5rem 0.25rem;
      `;
    }
    if (width <= breakpoints.mobileM) {
      return `
        padding: 0.5rem 0.25rem;
      `;
    }
    if (width <= breakpoints.mobileL) {
      return `
        padding: 0.5rem 0.25rem;
      `;
    }
    if (width <= breakpoints.tabletS) {
      return `
        padding: 0.5rem 0.25rem;
      `;
    }
    if (width <= breakpoints.tabletM) {
      return `
        padding: 0.5rem 0.25rem;

        ${
          isLandscape
            ? `
              padding: 0.25rem 0.125rem;
            `
            : ""
        }
      `;
    }
    if (width <= breakpoints.tabletL) {
      return `
        padding: 0.5rem 0.25rem;
      `;
    }
    if (width <= breakpoints.laptopS) {
      return `
      `;
    }
    if (width <= breakpoints.laptopL) {
      return `

      `;
    }
    if (width <= breakpoints.desktop) {
      return `

      `;
    }
    if (width <= breakpoints.desktopL) {
      return `

      `;
    }
  }}
`;

const DetailsOuterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 2;
  grid-row: 2;
  justify-content: space-between;
  height: 100%;
  overflow: hidden;

  ${({ $viewport }) => {
    const { width, isLandscape } = $viewport;

    if (width <= breakpoints.mobileS) {
      return `

      `;
    }
    if (width <= breakpoints.mobileM) {
      return `

      `;
    }
    if (width <= breakpoints.mobileL) {
      return `


      `;
    }
    if (width <= breakpoints.tabletS) {
      return `

      `;
    }
    if (width <= breakpoints.tabletM) {
      return `
      `;
    }
    if (width <= breakpoints.tabletL) {
      return `
        height: 100%
      `;
    }
    if (width <= breakpoints.laptopS) {
      return `

      `;
    }
    if (width <= breakpoints.laptopL) {
      return `

      `;
    }
  }}
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1rem 1rem 1rem;
  overflow-y: hidden;
  grid-column: 2;
  grid-row: 2;
  opacity: ${({ isCollapsed }) => (isCollapsed ? 0 : 1)};
  transition: opacity 0.2s ease-in-out;
  height: 100%;
  flex: 1;

  ${({ $viewport }) => {
    const { width, isLandscape } = $viewport;

    if (width <= breakpoints.mobileS) {
      return `
        padding: 0.5rem;
      `;
    }
    if (width <= breakpoints.mobileM) {
      return `
        padding: 0.5rem;
      `;
    }
    if (width <= breakpoints.mobileL) {
      return `
        padding: 0.5rem;
      `;
    }
    if (width <= breakpoints.tabletS) {
      return `
        padding: 0.5rem;
      `;
    }
    if (width <= breakpoints.tabletM) {
      return `
        padding: 0.5rem;
        ${
          isLandscape
            ? `
              padding: 0.25rem 0.5rem 0.5rem 0.5rem;
            `
            : ""
        }
      `;
    }
    if (width <= breakpoints.tabletL) {
      return `
        padding: 0.5rem;

      `;
    }

    if (width <= breakpoints.laptopS) {
      return `
      `;
    }
    if (width <= breakpoints.laptopL) {
      return `
      `;
    }
    if (width <= breakpoints.desktop) {
      return `

      `;
    }
    if (width <= breakpoints.desktopL) {
      return `

      `;
    }
  }}
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme, isActive, isHovered }) =>
    isActive
      ? theme.accentColorPrimary
      : isHovered
        ? theme.tertiaryColor
        : theme.primaryColor};
  border-radius: 5px;
  height: 40px;
  width: 40px;
  padding: 0.5rem;
  margin: 0.625rem auto;
  transition: background-color 0.3s ease;
  cursor: pointer;

  ${({ $viewport }) => {
    const { width, isLandscape } = $viewport;

    if (width <= breakpoints.mobileS) {
      return `
        height: 2rem;
        width: 2rem;
        padding: 0.25rem 0.125rem;
        margin: 0.5rem auto;
        ${
          isLandscape
            ? `
              height: 1.5rem;
              width: 1.5rem;
              padding: 0.125rem;
              margin: 0.25rem auto;
            `
            : ""
        }
      `;
    }
    if (width <= breakpoints.mobileM) {
      return `
        height: 2rem;
        width: 2rem;
        padding: 0.25rem 0.125rem;
        margin: 0.5rem auto;
        ${
          isLandscape
            ? `
              height: 0.5rem;
              width: 0.5rem;
              padding: 0.125rem;
              margin: 0.125rem auto;
            `
            : ""
        }
      `;
    }
    if (width <= breakpoints.mobileL) {
      return `
        height: 2rem;
        width: 2rem;
        padding: 0.25rem 0.125rem;
        margin: 0.5rem auto;
        ${
          isLandscape
            ? `
              height: 1.5rem;
              width: 1.5rem;
              padding: 0.125rem;
              margin: 0.25rem auto;
            `
            : ""
        }
      `;
    }
    if (width <= breakpoints.tabletS) {
      return `
        height: 2.5rem;
        width: 2.5rem;
        padding: 0.25rem 0.125rem;
        margin: 0.5rem auto;
        ${
          isLandscape
            ? `
              height: 2.125rem;
              width: 2rem;
              padding: 0.25rem;
              margin: 0.5rem auto;
            `
            : ""
        }
      `;
    }
    if (width <= breakpoints.tabletM) {
      return `
        height: 2.5rem;
        width: 2.5rem;
        padding: 0.25rem 0.125rem;
        margin: 0.5rem auto;
        ${
          isLandscape
            ? `
              height: 2rem;
              width: 2rem;
              padding: 0.125rem;
              margin: 0.25rem auto;
            `
            : ""
        }
      `;
    }
    if (width <= breakpoints.tabletL) {
      return `
        height: 2.5rem;
        width: 2.5rem;
        padding: 0.25rem 0.125rem;
        margin: 0.5rem auto;
        ${
          isLandscape
            ? `
              height: 2rem;
              width: 2rem;
              padding: 0.125rem 0.25rem;
              margin: 0.25rem auto;
            `
            : ""
        }
      `;
    }
    return ""; // Default styles are already set
  }}
`;

const CompanyDetails = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme, isActive, isHovered }) =>
    isActive
      ? theme.accentColorPrimary
      : isHovered
        ? theme.tertiaryColor
        : theme.primaryColor};
  padding: 0.25rem 1rem 0.75rem 1rem;
  margin: 0.625rem 0;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  cursor: pointer;
  height: 40px;

  & h2,
  p {
    margin: 0;
    color: ${({ theme, isActive, isHovered }) =>
      isActive
        ? theme.tertiaryColor
        : isHovered
          ? theme.textColorLight
          : theme.textColorLight};
    transition: color 0.3s ease;
  }

  & p {
    font-size: ${typography.fontSizeXxs};
    font-style: italic;
    line-height: ${typography.fontHeightXs};
  }

  ${({ $viewport }) => {
    const { width, isLandscape } = $viewport;

    if (
      width <= breakpoints.mobileS ||
      width <= breakpoints.mobileM ||
      width <= breakpoints.mobileL
    ) {
      return `
        padding: 0.25rem 0.5rem;
        margin: 0.5rem 0;
        height: 2rem;
        justify-content: center;

        & h2 {
          font-size: ${typography.fontSizeXs};
          line-height: ${typography.fontHeightXxs}; 
        }

        & p {
          font-size: ${typography.fontSizeXxs};
          line-height: ${typography.fontHeightXxs}; 
        }
      `;
    }
    if (width <= breakpoints.tabletS) {
      return `
        padding: 0.5rem 0.75rem;
        margin: 0.5rem 0;
        height: 2rem;
        justify-content: center;

        & h2 {
          font-size: ${typography.fontSizeXs};
          line-height: ${typography.fontHeightXxs}; 
        }

        & p {
          font-size: ${typography.fontSizeXxs};
          line-height: ${typography.fontHeightXxs}; 
        }
      `;
    }
    if (width <= breakpoints.tabletM || width <= breakpoints.tabletL) {
      return `

        ${
          isLandscape
            ? `
              height: 2rem;
              margin: 0.25rem 0;
              flex-direction: column;
              justify-content: center;
              align-content: flex-start;
              flex-wrap: wrap;
              padding: 0.125rem 0.5rem;             
              gap: 0; 

              & h2 {
                font-size: ${typography.fontSizeXxs};
                line-height: ${typography.fontHeightXxxs}; 
              }
              & p {
                font-size: ${typography.fontSizeMin};
                line-height: ${typography.fontHeightXxxs}; 
              }
            `
            : `
              padding: 0.25rem 0.5rem;
              margin: 0.5rem 0;
              height: 2.5rem;
              justify-content: center;

              & h2 {
                font-size: ${typography.fontSizeSmall};
                line-height: ${typography.fontHeightXxxs}; 
              }

              & p {
                font-size: ${typography.fontSizeXxs};
                line-height: ${typography.fontHeightXxs}; 
              }
            `
        }
      `;
    }
    if (width <= breakpoints.laptop || width <= breakpoints.laptopL) {
      return `
       padding: 0.5rem 1rem;

        & h2 {
          font-size: ${typography.fontSizeSmall};
        }

        & p {
          font-size: ${typography.fontSizeXxs};
          font-style: italic;
        }
      `;
    }
  }}
`;

const SelectedCompanyDetails = styled(CompanyDetails)``;

const ResumeDetails = styled.div`
  padding: 0.5rem 1rem;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.tertiaryColor};
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: ${({ $showScrollbar }) => ($showScrollbar ? "hidden" : "auto")};

  & p {
    color: ${({ theme }) => theme.textColorLight};
    font-size: ${typography.fontSizeXxs};
    line-height: ${typography.fontHeightSm};
    white-space: pre-wrap;
    margin: 0;
  }

  ${({ $viewport }) => {
    const { width, isLandscape } = $viewport;

    if (width <= breakpoints.mobileS) {
      return `

      `;
    }
    if (width <= breakpoints.mobileM) {
      return `

      `;
    }
    if (width <= breakpoints.mobileL) {
      return `
        padding: 0.5rem 1rem;
        border-radius: 5px;
        
        & p {
          font-size: ${typography.fontSizeXxs};
          line-height: ${typography.fontHeightXs}; 
        }

      `;
    }
    if (width <= breakpoints.tabletS) {
      return `
        padding: 0.5rem;
        border-radius: 5px;
        
        & p {
          font-size: ${typography.fontSizeXxxs};
          line-height: ${typography.fontHeightXs}; 
        }
      `;
    }
    if (width <= breakpoints.tabletM) {
      return `      
        ${
          isLandscape
            ? `
              padding: 0.25rem 0.5rem;
              border-radius: 5px;
              
              & p {
                font-size: ${typography.fontSizeXxxs};
                line-height: ${typography.fontHeightXxs}; 
              }
            `
            : `
              padding: 1rem;
              border-radius: 5px;
              & p {
                font-size: ${typography.fontSizeXs};
                line-height: ${typography.fontHeightSm}; 
              }
            `
        }
      `;
    }
    if (width <= breakpoints.tabletL) {
      return `      
        ${
          isLandscape
            ? `
              padding: 0.25rem 0.5rem;
              border-radius: 5px;
              
              & p {
                font-size: ${typography.fontSizeXxxs};
                line-height: ${typography.fontHeightXxs}; 
              }
            `
            : `
              padding: 1rem;
              border-radius: 5px;
              & p {
                font-size: ${typography.fontSizeXs};
                line-height: ${typography.fontHeightSm}; 
              }
            `
        }
      `;
    }
    if (width <= breakpoints.laptopS) {
      return `
        padding: 0.5rem 1rem;
        border-radius: 5px;
        
        & p {
          font-size: ${typography.fontSizeXxs};
          line-height: ${typography.fontHeightXs}; 
        }
      `;
    }
    if (width <= breakpoints.laptopL) {
      return `
        padding: 0.5rem 1rem;
        border-radius: 5px;
        
        & p {
          font-size: ${typography.fontSizeXxs};
          line-height: ${typography.fontHeightXs}; 
        }
      `;
    }
  }}
`;

const ResumeContent = styled.div`
  color: ${({ theme }) => theme.textColorLight};
  font-size: ${typography.fontSizeXxs};
  line-height: ${typography.fontHeightSm};
  white-space: pre-wrap;
  margin: 0;
  padding-right: ${({ $showScrollbar }) => ($showScrollbar ? "10px" : "0")};
`;

const SkeletonLogoWrapper = styled(LogoWrapper)`
  background-color: ${({ theme }) => theme.primaryColor};
`;

const SkeletonCompanyDetails = styled(CompanyDetails)`
  background-color: ${({ theme }) => theme.primaryColor};
  height: auto;
  cursor: default;
`;

const SlideOutTabsContainer = styled.div`
  grid-column: 1 / -1;
  grid-row: 3;
  padding: 0.5rem;
  align-self: flex-end;
  margin: 1.25rem 0 0 0;
  z-index: 955;

  ${({ $viewport }) => {
    const { width, isLandscape } = $viewport;

    if (width <= breakpoints.mobileS) {
      return `
        padding: 0;
        margin: 0 0.5rem 0.5rem 0.5rem;
      `;
    }
    if (width <= breakpoints.mobileM) {
      return `
        padding: 0.375rem;
      `;
    }
  }}
`;

const ResumeSlideOut = ({
  selectedExperience,
  onExperienceSelect,
  experiences,
  onBackClick,
  isLoading,
  isCollapsed,
  onCollapse,
  selectedTab,
  onTabChange,
}) => {
  const { theme } = useTheme();
  const viewport = useViewport();
  const { width, height, isLandscape } = viewport;
  const [hoveredExperience, setHoveredExperience] = useState(null);
  const contentRef = useRef(null);
  const [showScrollbar, setShowScrollbar] = useState(false);
  const { updateSlideOutState } = useSlideOut();

  const isScrollbarAllowed = useMemo(() => {
    const isWidthBetween = (min, max) => width > min && width <= max;

    if (isWidthBetween(0, breakpoints.mobileS)) {
      return isLandscape;
    }
    if (isWidthBetween(breakpoints.mobileS, breakpoints.mobileM)) {
      return isLandscape;
    }
    if (isWidthBetween(breakpoints.mobileM, breakpoints.mobileL)) {
      return isLandscape;
    }
    if (isWidthBetween(breakpoints.mobileL, breakpoints.tabletS)) {
      return isLandscape;
    }
    if (isWidthBetween(breakpoints.tabletS, breakpoints.tabletM)) {
      return isLandscape && height <= 600;
    }
    if (isWidthBetween(breakpoints.tabletM, breakpoints.tabletL)) {
      return isLandscape && height <= 800;
    }
    if (isWidthBetween(breakpoints.tabletL, breakpoints.laptopS)) {
      return true;
    }
    return true;
  }, [width, height, isLandscape]);

  useEffect(() => {
    const checkOverflow = () => {
      if (contentRef.current) {
        const isOverflowing =
          contentRef.current.scrollHeight > contentRef.current.clientHeight;
        setShowScrollbar(isOverflowing && isScrollbarAllowed);
      }
    };

    checkOverflow();
    window.addEventListener("resize", checkOverflow);

    return () => {
      window.removeEventListener("resize", checkOverflow);
    };
  }, [selectedExperience, isScrollbarAllowed]);

  useEffect(() => {
    updateSlideOutState(true, isCollapsed);

    return () => {
      updateSlideOutState(false, true);
    };
  }, [isCollapsed, updateSlideOutState]);

  const animations = useMemo(
    () => createSlideOutAnimations(isCollapsed, theme, viewport),
    [isCollapsed, theme, viewport]
  );

  const handleExperienceClick = (experienceId) => {
    if (selectedExperience && selectedExperience.id === experienceId) {
      onBackClick();
    } else {
      onExperienceSelect(experienceId);
    }
  };

  const handleExperienceHover = (experienceId) => {
    setHoveredExperience(experienceId);
  };

  const handleExperienceLeave = () => {
    setHoveredExperience(null);
  };

  const shouldDisplayTabs = useMemo(() => {
    const { width, isLandscape } = viewport;

    if (width <= breakpoints.mobileS) return true;
    if (width <= breakpoints.mobileM) return true;
    if (width <= breakpoints.mobileL) return true;
    if (width <= breakpoints.tabletS) return true;
    if (width <= breakpoints.tabletM) return !isLandscape;
    if (width <= breakpoints.tabletL) return !isLandscape;
    if (width <= breakpoints.laptopS) return false;

    return false;
  }, [viewport]);

  const handleSlideOutTabChange = (tab) => {
    onTabChange(tab);
    onCollapse();
  };

  const handleCollapse = useCallback(() => {
    const newCollapsedState = !isCollapsed;
    onCollapse(newCollapsedState);
    updateSlideOutState(true, newCollapsedState);
  }, [isCollapsed, onCollapse, updateSlideOutState]);

  const LogoSkeleton = () => {
    let size = 30;

    if (width <= breakpoints.mobileS) {
      size = isLandscape ? 24 : 32;
    } else if (width <= breakpoints.mobileM) {
      size = isLandscape ? 8 : 32;
    } else if (width <= breakpoints.mobileL) {
      size = isLandscape ? 24 : 32;
    } else if (width <= breakpoints.tabletS) {
      size = isLandscape ? 32 : 40;
    } else if (width <= breakpoints.tabletM) {
      size = isLandscape ? 12 : 40;
    } else if (width <= breakpoints.tabletL) {
      size = isLandscape ? 20 : 40;
    }

    return (
      <SkeletonLogoWrapper theme={theme} $viewport={viewport}>
        <Skeleton circle={true} width={size} height={size} />
      </SkeletonLogoWrapper>
    );
  };

  const CompanyDetailsSkeleton = () => {
    let detailSkeletonWidth = "100";
    let detailSkeletonHeight = "20";
    let detailContainerHeight = "1.5rem";

    if (width <= breakpoints.mobileL) {
      detailSkeletonHeight = isLandscape ? 12 : 12;
      detailSkeletonWidth = isLandscape ? 100 : 100;
      detailContainerHeight = "2rem";
    } else if (width <= breakpoints.tabletS) {
      detailSkeletonHeight = isLandscape ? 12 : 12;
      detailSkeletonWidth = isLandscape ? 100 : 100;
      detailContainerHeight = "2rem";
    } else if (width <= breakpoints.tabletM) {
      detailSkeletonHeight = isLandscape ? 12 : 12;
      detailSkeletonWidth = isLandscape ? 80 : 100;
      detailContainerHeight = isLandscape ? "2rem" : "2.5rem";
    } else if (width <= breakpoints.tabletL) {
      detailSkeletonHeight = isLandscape ? 12 : 12;
      detailSkeletonWidth = isLandscape ? 100 : 100;
      detailContainerHeight = isLandscape ? "1.5rem" : "2.5rem";
    } else if (width <= breakpoints.laptopS) {
      detailSkeletonHeight = isLandscape ? 12 : 12;
      detailSkeletonWidth = isLandscape ? 100 : 100;
      detailContainerHeight = isLandscape ? "1.5rem" : "2.5rem";
    } else if (width <= breakpoints.laptopM) {
      detailSkeletonHeight = isLandscape ? 12 : 12;
      detailSkeletonWidth = isLandscape ? 100 : 100;
      detailContainerHeight = "2.5rem";
    } else if (width <= breakpoints.desktopL) {
      detailSkeletonHeight = isLandscape ? 12 : 12;
      detailSkeletonWidth = isLandscape ? 100 : 100;
      detailContainerHeight = "2.5rem";
    }

    return (
      <SkeletonCompanyDetails
        theme={theme}
        $viewport={viewport}
        style={{ height: detailContainerHeight }}
      >
        <Skeleton width={detailSkeletonWidth} height={detailSkeletonHeight} />
        <Skeleton width={detailSkeletonWidth} height={detailSkeletonHeight} />
      </SkeletonCompanyDetails>
    );
  };

  const skeletonCount = 5;

  const renderSkeletons = () => (
    <>
      <SlideOutLogoContainer $viewport={viewport}>
        {Array.from({ length: skeletonCount }).map((_, index) => (
          <LogoSkeleton key={index} />
        ))}
      </SlideOutLogoContainer>
      <DetailsContainer
        isCollapsed={isCollapsed}
        $viewport={viewport}
        className="details-container"
      >
        {Array.from({ length: skeletonCount }).map((_, index) => (
          <CompanyDetailsSkeleton key={index} />
        ))}
      </DetailsContainer>
    </>
  );

  const renderContent = ({ closeButton }) => (
    <>
      <SlideOutLogoContainer $viewport={viewport}>
        {experiences?.map((item) => (
          <LogoWrapper
            key={item.id}
            isActive={selectedExperience && selectedExperience.id === item.id}
            isHovered={hoveredExperience === item.id}
            theme={theme}
            $viewport={viewport}
            onClick={() => handleExperienceClick(item.id)}
            onMouseEnter={() => handleExperienceHover(item.id)}
            onMouseLeave={handleExperienceLeave}
          >
            <ResumeLogo
              logoId={item.logo.logo_id}
              spriteSheetName={item.logo.sprite_sheet.filename}
              isActive={selectedExperience && selectedExperience.id === item.id}
              isHovered={hoveredExperience === item.id}
              $viewport={viewport}
              theme={theme}
            />
          </LogoWrapper>
        ))}
      </SlideOutLogoContainer>
      <DetailsOuterWrapper $viewport={viewport}>
        <DetailsContainer
          isCollapsed={isCollapsed}
          $viewport={viewport}
          className="details-container"
        >
          {selectedExperience ? (
            <React.Fragment key={selectedExperience.id}>
              <SelectedCompanyDetails
                theme={theme}
                isActive={true}
                isHovered={hoveredExperience === selectedExperience.id}
                $viewport={viewport}
                onClick={() => handleExperienceClick(selectedExperience.id)}
                onMouseEnter={() =>
                  handleExperienceHover(selectedExperience.id)
                }
                onMouseLeave={handleExperienceLeave}
              >
                <h2>{selectedExperience.company}</h2>
                <p>{selectedExperience.role_title}</p>
                {closeButton}
              </SelectedCompanyDetails>
              <ResumeDetails
                theme={theme}
                $viewport={viewport}
                $showScrollbar={showScrollbar}
              >
                {showScrollbar ? (
                  <Scrollbar theme={theme}>
                    <ResumeContent
                      ref={contentRef}
                      theme={theme}
                      $showScrollbar={showScrollbar}
                    >
                      <p>{selectedExperience.description}</p>
                    </ResumeContent>
                  </Scrollbar>
                ) : (
                  <ResumeContent
                    ref={contentRef}
                    theme={theme}
                    $showScrollbar={showScrollbar}
                  >
                    <p>{selectedExperience.description}</p>
                  </ResumeContent>
                )}
              </ResumeDetails>
            </React.Fragment>
          ) : (
            experiences?.map((item) => (
              <CompanyDetails
                key={item.id}
                theme={theme}
                isActive={
                  selectedExperience && selectedExperience.id === item.id
                }
                isHovered={hoveredExperience === item.id}
                $viewport={viewport}
                onClick={() => handleExperienceClick(item.id)}
                onMouseEnter={() => handleExperienceHover(item.id)}
                onMouseLeave={handleExperienceLeave}
              >
                <h2>{item.company}</h2>
                <p>{item.role_title}</p>
              </CompanyDetails>
            ))
          )}
        </DetailsContainer>
        {selectedExperience && shouldDisplayTabs && !isCollapsed && (
          <SlideOutTabsContainer
            $viewport={viewport}
            className="slide-out-tabs-container"
          >
            <ResumeSlideOutTabs
              setSelectedTab={handleSlideOutTabChange}
              viewport={viewport}
            />
          </SlideOutTabsContainer>
        )}
      </DetailsOuterWrapper>
    </>
  );

  return (
    <SkeletonTheme
      baseColor={theme.primaryColor}
      highlightColor={theme.iconColorSecondary}
    >
      <SlideOut
        isCollapsed={isCollapsed}
        onCollapse={handleCollapse}
        animations={animations}
        selectedItem={selectedExperience}
        onItemSelect={onExperienceSelect}
        onBackClick={onBackClick}
        isLoading={isLoading}
        showCloseButton={true}
        collapseOnSelect={false}
      >
        {(props) => (isLoading ? renderSkeletons() : renderContent(props))}
      </SlideOut>
    </SkeletonTheme>
  );
};

export default ResumeSlideOut;
