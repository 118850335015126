// AppContent.jsx

import React, { Suspense } from "react";
import { useLocation, Routes, Route } from "react-router-dom";
import { useTheme } from "./context/ThemeContext";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import UI from "./components/UI/UI";
import Home from "./pages/Home";
import About from "./components/About/About";
import Projects from "./components/Project/Projects";
import Resume from "./components/Resume/Resume";
import Contact from "./components/Contact/Contact";
import CaseStudies from "./pages/CaseStudies";
import CaseStudyDetail from "./components/CaseStudy/CaseStudyDetail";
import GameDev from "./pages/GameDev";
import Experience from "./components/Experience/Experience";

function AppContent() {
  const location = useLocation();
  const { theme } = useTheme();

  return (
    <StyledThemeProvider theme={theme}>
      <div className="app-container">
        <UI>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />}>
              <Route index element={<About tab="bio" />} />
              <Route path=":tab" element={<About />} />
            </Route>
            <Route path="/projects" element={<Projects />}>
              <Route index element={<Projects />} />
              <Route path=":category" element={<Projects />} />
            </Route>
            <Route path="/experience" element={<Resume />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/case-studies" element={<CaseStudies />} />
            <Route
              path="/case-studies/:caseStudySlug"
              element={<CaseStudyDetail />}
            />
            <Route path="/game-dev" element={<GameDev />} />
          </Routes>
        </UI>
        <Suspense>
          <Experience />
        </Suspense>
      </div>
    </StyledThemeProvider>
  );
}

export default AppContent;
