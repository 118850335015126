import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const ChallengesSection = ({ challenges, loading }) => {
  return (
    <section className="case-study__challenges">
      <h2>{loading ? <Skeleton width={200} /> : "Challenges:"}</h2>
      {loading ? (
        <Skeleton count={3} />
      ) : (
        <ol>
          {challenges.map((challenge) => (
            <li key={challenge.id}>
              <p>{challenge.description}</p>
            </li>
          ))}
        </ol>
      )}
    </section>
  );
};

export default ChallengesSection;
