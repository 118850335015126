import React from "react";
import Markdown from "react-markdown";
import Skeleton from "react-loading-skeleton";

function ApproachSection({ approach, loading }) {
  return (
    <div className="case-study__approach">
      <h2>{loading ? <Skeleton width={200} /> : "My Approach:"}</h2>
      {loading ? <Skeleton count={3} /> : <Markdown>{approach}</Markdown>}
    </div>
  );
}

export default ApproachSection;
