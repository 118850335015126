import React from 'react';
import SVGIcon from './SVGIcon';

const ImageRender = ({ item, imgField, additionalClasses = [] }) => {
  const iconClasses = ['project__img', ...additionalClasses];
  const imageClasses = `project__img project__img-clipped ${additionalClasses.join(' ')}`;

  if (item.icon) {
    return (
      <SVGIcon
        iconId={item.icon.icon_id}
        spriteSheetName={item.icon.sprite_sheet.filename}
        additionalClasses={[...iconClasses, `icons--${item.icon.icon_id}`]}
      />
    );
  } else if (item[imgField]) {
    return <img className={imageClasses} src={item[imgField]} alt={item.name} />;
  }

  return null;
};

export default ImageRender;