import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function HeaderSection({ name, company, image, logo, loading }) {
  const headerStyle = image ? { backgroundImage: `url(${image})` } : {};

  return (
    <div className="case-study__header">
      <div className="header-content" style={headerStyle}>
        {!logo && (
          <>
            <h1>{name}</h1>
            <p>{company}</p>
          </>
        )}
      </div>
    </div>
  );
}

export default HeaderSection;
