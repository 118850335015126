import React from "react";
import Skeleton from "react-loading-skeleton";

const ScopeSection = ({ scope, loading }) => {
  return (
    <section className="case-study__scope">
      <h2>{loading ? <Skeleton width={200} /> : "Scope:"}</h2>
      {loading ? (
        <Skeleton count={3} />
      ) : (
        <ul>
          {scope.map((item) => (
            <li key={item.id}>{item.item}</li>
          ))}
        </ul>
      )}
    </section>
  );
};

export default ScopeSection;
