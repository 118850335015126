import React from "react";
import Markdown from "react-markdown";
import Skeleton from "react-loading-skeleton";

function GapSection({ gap, image, loading }) {
  return (
    <div className="case-study__gap">
      <div className="case-study__gap-content">
        <h2>{loading ? <Skeleton width={200} /> : "The Gap:"}</h2>
        {loading ? <Skeleton count={3} /> : <Markdown>{gap}</Markdown>}
      </div>
      {loading ? (
        <Skeleton width={500} height={300} />
      ) : (
        <figure className="case-study__gap-image">
          <img src={image} alt="gap" />
        </figure>
      )}
    </div>
  );
}

export default GapSection;
