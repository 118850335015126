import React, { useState, useEffect } from "react";
import { useTheme } from "../../context/ThemeContext";
import { animated, useSpring } from "@react-spring/web";
import styled from "styled-components";
import { breakpoints } from "../../styles/breakpoints";
import { useViewport } from "../../context/ViewportContext";

const StyledSVG = styled(animated.svg)`
  cursor: pointer;
  pointer-events: auto;
  grid-column: 1;
  grid-row: 3;
  align-self: center;
  margin: 0 auto;
  stroke: ${({ theme }) => theme.primaryColor};
  z-index: 990;

  ${({ $viewport }) => {
    const { width, isLandscape } = $viewport;

    if (width <= breakpoints.mobileS) {
      return `
        /* Styles for mobileS */
        width: 1.75rem;
        height: 1.75rem;
      `;
    }
    if (width <= breakpoints.mobileM) {
      return `
        /* Styles for mobileM and mobileL */
        width: 2rem;
        height: 2rem;
      `;
    }
    if (width <= breakpoints.mobileL) {
      return `
        /* Styles for mobileM and mobileL */
        width: 2rem;
        height: 2rem;
      `;
    }
    if (width <= breakpoints.tabletM) {
      return `
        /* Styles for tabletS and tabletM */
        width: 1.5rem;
        height: 1.5rem;
      `;
    }
    if (width <= breakpoints.tabletL) {
      return `
        /* Styles for tabletS and tabletM */
        width: 1.5rem;
        height: 1.5rem;
      `;
    }
    return `
      /* Styles for tabletL and above */
      width: 2.5rem;
      height: 2.5rem;
    `;
  }}
`;

function ThemeToggle() {
  const { theme, toggleTheme } = useTheme();
  const viewport = useViewport();
  const [isDarkMode, setDarkMode] = React.useState(theme === "dark");
  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleDarkMode = () => {
    setDarkMode((previous) => {
      const newTheme = !previous ? "dark" : "light";
      toggleTheme(newTheme);
      return !previous;
    });
  };

  const properties = {
    dark: {
      r: 9,
      transform: "rotate(40deg)",
      cx: 12,
      cy: 4,
      opacity: 0,
    },
    light: {
      r: 5,
      transform: "rotate(90deg)",
      cx: 30,
      cy: 0,
      opacity: 1,
    },
    springConfig: { mass: 4, tension: 250, friction: 35 },
  };

  const { r, transform, cx, cy, opacity } =
    properties[isDarkMode ? "dark" : "light"];

  const svgContainerProps = useSpring({
    transform,
    config: properties.springConfig,
  });
  const centerCircleProps = useSpring({ r, config: properties.springConfig });
  const maskedCircleProps = useSpring({
    cx,
    cy,
    config: properties.springConfig,
  });
  const linesProps = useSpring({ opacity, config: properties.springConfig });

  return (
    <StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke={theme.primaryColor}
      onClick={toggleDarkMode}
      style={svgContainerProps}
      $viewport={viewport}
    >
      <mask id="myMask2">
        <rect x="0" y="0" width="100%" height="100%" fill="white" />
        <animated.circle style={maskedCircleProps} r="9" fill="black" />
      </mask>

      <animated.circle
        cx="12"
        cy="12"
        style={centerCircleProps}
        fill={theme.primaryColor}
        mask="url(#myMask2)"
      />
      <animated.g stroke={theme.primaryColor} style={linesProps}>
        <line x1="12" y1="1" x2="12" y2="3" />
        <line x1="12" y1="21" x2="12" y2="23" />
        <line x1="4.22" y1="4.22" x2="5.64" y2="5.64" />
        <line x1="18.36" y1="18.36" x2="19.78" y2="19.78" />
        <line x1="1" y1="12" x2="3" y2="12" />
        <line x1="21" y1="12" x2="23" y2="12" />
        <line x1="4.22" y1="19.78" x2="5.64" y2="18.36" />
        <line x1="18.36" y1="5.64" x2="19.78" y2="4.22" />
      </animated.g>
    </StyledSVG>
  );
}

export default ThemeToggle;
