import React from "react";
import "./stylesGameDev.scss";
import { useTheme } from "../context/ThemeContext";

function GameDev() {
  const { theme } = useTheme();

  return <></>;
}

export default GameDev;
