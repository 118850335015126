import React, { useState, useEffect, Suspense, useRef } from "react";
import styled from "styled-components";
import { useTheme } from "../../context/ThemeContext";
import { fetchExperiences } from "../../services/ExperienceService";
import ResumeDetails from "./ResumeDetails";
import ResumeCanvas from "./ResumeCanvas";
import ResumeSlideOut from "./ResumeSlideOut";
import Loader from "../Loader/Loader";

const ResumeContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  grid-column: 1/-1;
  grid-row: 1/-1;
`;

function Resume() {
  const { theme } = useTheme();
  const [experiences, setExperiences] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedExperience, setSelectedExperience] = useState(null);
  const [isEmptyState, setIsEmptyState] = useState(true);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selectedTab, setSelectedTab] = useState("Highlights");

  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
    aspectRatio: window.innerWidth / window.innerHeight,
  });
  const isMounted = useRef(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const startTime = Date.now();
      try {
        const data = await fetchExperiences();
        const sortedData = data.sort((a, b) => a.order - b.order);
        setExperiences(sortedData);

        // No initial selection
        setSelectedExperience(null);
        setIsEmptyState(true);

        const elapsedTime = Date.now() - startTime;
        const minimumLoadingTime = 500; // 500ms minimum loading time
        if (elapsedTime < minimumLoadingTime) {
          setTimeout(
            () => setIsLoading(false),
            minimumLoadingTime - elapsedTime
          );
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error fetching experiences:", error);
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
        aspectRatio: window.innerWidth / window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("orientationchange", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("orientationchange", handleResize);
    };
  }, []);

  const handleExperienceSelect = (experienceId) => {
    const selected = experiences.find((e) => e.id === experienceId);
    if (selected) {
      if (selectedExperience && selected.id === selectedExperience.id) {
        // If the same experience is clicked
        setIsCollapsed(!isCollapsed);
      } else {
        // If a different experience is clicked
        setSelectedExperience(selected);
        setIsEmptyState(false);
        setSelectedTab("Highlights");
        setIsCollapsed(false); // Always expand when selecting a new experience
      }
    }
  };

  const handleBackClick = () => {
    setIsEmptyState(true);
    setSelectedExperience(null);
    setSelectedTab("Highlights");
    setIsCollapsed(false);
  };

  const handleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleTabChange = (newTab) => {
    setSelectedTab(newTab);
  };

  const handleSlideOutTabChange = (newTab) => {
    setSelectedTab(newTab);
    setIsCollapsed(true); // Close the slideout when a tab is selected in it
  };

  useEffect(() => {
    const handleOrientationChange = () => {
      const isLandscape = window.innerWidth > window.innerHeight;
      if (isLandscape) {
        setIsCollapsed(false);
      }
      // Force a re-render of ResumeDetails
      setSelectedTab((prevTab) => prevTab);
    };

    window.addEventListener("resize", handleOrientationChange);
    window.addEventListener("orientationchange", handleOrientationChange);

    // Initial check
    handleOrientationChange();

    return () => {
      window.removeEventListener("resize", handleOrientationChange);
      window.removeEventListener("orientationchange", handleOrientationChange);
    };
  }, []);

  return (
    <ResumeContainer>
      <ResumeSlideOut
        selectedExperience={selectedExperience}
        onExperienceSelect={handleExperienceSelect}
        experiences={experiences}
        onBackClick={handleBackClick}
        isLoading={isLoading}
        isCollapsed={isCollapsed}
        onCollapse={handleCollapse}
        selectedTab={selectedTab}
        onTabChange={handleSlideOutTabChange}
      />
      <ResumeDetails
        key={selectedExperience ? selectedExperience.id : "empty"}
        experience={selectedExperience}
        isEmptyState={isEmptyState}
        isCollapsed={isCollapsed}
        selectedTab={selectedTab}
        onTabChange={handleTabChange}
        isLoading={isLoading}
      />
      <Suspense
        fallback={<Loader isLoading={true} text="Loading 3D Scene..." />}
      >
        <ResumeCanvas
          experienceId={selectedExperience ? selectedExperience.id : "default"}
          isEmptyState={isEmptyState}
          isLoading={isLoading}
        />
      </Suspense>
    </ResumeContainer>
  );
}

export default Resume;
