import React from "react";
import Markdown from "react-markdown";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function BackstorySection({ backstory, image, loading }) {
  return (
    <section className="case-study__backstory">
      <div className="case-study__backstory-content">
        <h2>{loading ? <Skeleton width={200} /> : "Backstory:"}</h2>
        {loading ? <Skeleton count={3} /> : <Markdown>{backstory}</Markdown>}
      </div>
      {loading ? (
        <Skeleton width={500} height={300} />
      ) : (
        <figure className="case-study__backstory-image">
          <img src={image} alt="backstory" />
        </figure>
      )}
    </section>
  );
}

export default BackstorySection;
