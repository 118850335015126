import React from "react";
import Markdown from "react-markdown";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function MissionSection({ mission, loading }) {
  return (
    <div className="case-study__mission">
      <h2>{loading ? <Skeleton width={100} /> : "Mission:"}</h2>
      {loading ? <Skeleton count={3} /> : <Markdown>{mission}</Markdown>}
    </div>
  );
}

export default MissionSection;
