import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Grid } from "mauerwerk";
import Loader from "../Loader/Loader";
import { useTheme } from "../../context/ThemeContext";
import { useViewport } from "../../context/ViewportContext";
import { hexToRgba } from "../../utils/hexToRGBA";
import { breakpoints } from "../../styles/breakpoints";

const ProjectsContainer = styled.div`
  grid-column: 1;
  grid-row: 2;
  height: 100%;
  position: relative;
  padding: 1rem 0.5rem;

  ${({ $viewport }) => {
    if (!$viewport) return "";
    const { width, height } = $viewport;
    const isLandscape = width > height;

    if (width <= breakpoints.mobileS) {
      return `
        max-width: 185px;
        grid-column: 1;
      `;
    }
    if (width <= breakpoints.mobileM) {
      return `
        max-width: 200px;
        grid-column: 1;
      `;
    }
    if (width <= breakpoints.mobileL) {
      return `
        max-width: 270px;
        grid-column: 1;
        margin-top: -2.5rem;
      `;
    }
    if (width <= breakpoints.tabletS) {
      return `
        max-width: calc(100% - 2.5rem);
        grid-column: 1;
        margin-top: -2.5rem;
      `;
    }
    if (width <= breakpoints.tabletM) {
      return `
        max-width: 425px;
        grid-column: 1;
        margin-top: -2.5rem;
      `;
    }
    if (width <= breakpoints.tabletL) {
      return `
        max-width: 270px;
        grid-column: 2;
        margin-top: -2.5rem;

        ${
          isLandscape
            ? `
              grid-column: 2;
              justify-content: center;
              margin-top:0;
            `
            : ""
        }
      `;
    }
    if (width <= breakpoints.laptopS) {
      return `
        max-width: 270px;
        grid-column: 1;
        margin-top: -2.5rem;
      `;
    }
    if (width <= breakpoints.laptopL) {
      return `
        max-width: 90%;
        grid-column: 1;
        margin-top: -2.5rem;
        flex-wrap: nowrap;
      `;
    }
    if (width <= breakpoints.desktop) {
      return `
        max-width: 95%;
        grid-column: 1;
        margin-top: -0rem;
        flex-wrap: nowrap;
      `;
    }
    if (width <= breakpoints.desktopL) {
      return `
        max-width: 550px;
        grid-column: 1;
        margin-top: -2.5rem;
      `;
    }
    return `

    `;
  }}
`;

const GridContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ProjectWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  padding: 0.5rem;
  box-sizing: border-box;
`;

const ProjectCard = styled.div`
  flex: 1;
  background-color: ${({ theme }) => hexToRgba(theme.secondaryColor, 0.25)};
  border: 1px solid ${({ theme }) => hexToRgba(theme.secondaryColor, 0.75)};
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 10px;
  padding: 1.25rem;
  transition: all 0.3s cubic-bezier(0.87, -0.41, 0.19, 1.44);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  overflow: auto;

  &:hover {
    transform: ${({ $maximized }) => ($maximized ? "none" : "scale(1.01)")};
    background-color: ${({ theme }) => hexToRgba(theme.tertiaryColor, 0.5)};
  }
`;

const ProjectTitle = styled.h4`
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  text-align: center;
  color: ${({ theme }) => theme.textColorLight};
`;

const ProjectDescription = styled.p`
  font-size: 1rem;
  text-align: center;
  flex: 1;
  overflow-y: auto;
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 20rem;
  max-height: 7.25rem;
  border-radius: 15px;
  overflow: hidden;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: all 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045);
`;

function WIP({ projects, isLoading, error, size }) {
  const { theme } = useTheme();
  const viewport = useViewport();
  const [maximizedId, setMaximizedId] = useState(null);
  const [columns, setColumns] = useState(4);
  const [cellHeight, setCellHeight] = useState(200);
  const [itemsPerPage, setItemsPerPage] = useState(3);

  useEffect(() => {
    const handleResize = () => {
      if (viewport.width <= breakpoints.mobileL) {
        setColumns(1);
        setCellHeight(150);
        setItemsPerPage(3);
      } else if (viewport.width <= breakpoints.tabletM) {
        setColumns(2);
        setCellHeight(175);
        setItemsPerPage(4);
      } else if (viewport.width <= breakpoints.laptopS) {
        setColumns(1);
        setCellHeight(125);
        setItemsPerPage(2);
      } else {
        setColumns(3);
        setCellHeight(225);
        setItemsPerPage(9);
      }
    };

    handleResize(); // Set initial values
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (error) return <div>Error: {error}</div>;
  if (!projects) {
    console.error("Projects is undefined in WIP component");
    return <div>Error: Projects data is missing</div>;
  }

  const handleToggle = (id) => {
    setMaximizedId(maximizedId === id ? null : id);
  };

  return (
    <ProjectsContainer $viewport={viewport} className="projects-container">
      <Loader isLoading={isLoading} text="Loading Projects..." />
      {!isLoading && projects.length === 0 && <div>No projects found.</div>}
      {!isLoading && projects.length > 0 && (
        <GridContainer $viewport={viewport}>
          <Grid
            className="grid"
            data={projects}
            keys={(project) => project.id}
            columns={columns}
            heights={() => cellHeight}
            lockScroll={true}
          >
            {(project, isOpen, toggle) => (
              <ProjectWrapper
                key={project.id}
                className={isOpen ? "maximized" : ""}
                $viewport={viewport}
              >
                <ProjectCard
                  onClick={() => {
                    toggle();
                    handleToggle(project.id);
                  }}
                  theme={theme}
                  $viewport={viewport}
                  $maximized={isOpen}
                >
                  <ImageWrapper $viewport={viewport}>
                    {project.thumbnail_img && (
                      <Image
                        src={project.thumbnail_img}
                        alt={project.name}
                        $viewport={viewport}
                      />
                    )}
                  </ImageWrapper>
                  <ProjectTitle theme={theme} $viewport={viewport}>
                    {project.name}
                  </ProjectTitle>
                  <ProjectDescription $viewport={viewport}>
                    {project.description}
                  </ProjectDescription>
                  {isOpen && <></>}
                </ProjectCard>
              </ProjectWrapper>
            )}
          </Grid>
        </GridContainer>
      )}
    </ProjectsContainer>
  );
}

export default WIP;
