import { breakpoints, heightBreakpoints } from "../styles/breakpoints";

const itemConfigs = {
  // Width <= 360px (mobileS)
  [breakpoints.mobileS]: {
    [heightBreakpoints.heightMIN]: {
      landscape: { Highlights: 2, Skills: 2, Technologies: 2, Testimonials: 1 },
      portrait: {
        Highlights: 4,
        Skills: 4,
        Technologies: 4,
        Testimonials: 1,
        All: 1,
        Featured: 1,
        Projects: 1,
        Photography: 1,
        Articles: 1,
        CaseStudies: 1,
      },
    },
    [heightBreakpoints.heightXS]: {
      landscape: { Highlights: 2, Skills: 2, Technologies: 2, Testimonials: 1 },
      portrait: {
        Highlights: 4,
        Skills: 4,
        Technologies: 4,
        Testimonials: 1,
        All: 2,
        Featured: 2,
        Projects: 2,
        Photography: 2,
        Articles: 2,
        CaseStudies: 2,
      },
    },
    [heightBreakpoints.heightS]: {
      landscape: { Highlights: 3, Skills: 3, Technologies: 3, Testimonials: 1 },
      portrait: {
        Highlights: 6,
        Skills: 6,
        Technologies: 6,
        Testimonials: 1,
        All: 2,
        Featured: 2,
        Projects: 2,
        Photography: 2,
        Articles: 2,
        CaseStudies: 2,
      },
    },
    [heightBreakpoints.heightM]: {
      landscape: { Highlights: 4, Skills: 4, Technologies: 4, Testimonials: 1 },
      portrait: {
        Highlights: 8,
        Skills: 8,
        Technologies: 8,
        Testimonials: 1,
        All: 3,
        Featured: 3,
        Projects: 3,
        Photography: 3,
        Articles: 3,
        CaseStudies: 3,
      },
    },
    [heightBreakpoints.heightL]: {
      landscape: { Highlights: 4, Skills: 4, Technologies: 4, Testimonials: 1 },
      portrait: {
        Highlights: 8,
        Skills: 8,
        Technologies: 8,
        Testimonials: 1,
        All: 3,
        Featured: 3,
        Projects: 3,
        Photography: 3,
        Articles: 3,
        CaseStudies: 3,
      },
    },
    [heightBreakpoints.heightXL]: {
      landscape: { Highlights: 6, Skills: 6, Technologies: 6, Testimonials: 1 },
      portrait: {
        Highlights: 10,
        Skills: 10,
        Technologies: 10,
        Testimonials: 1,
        All: 3,
        Featured: 3,
        Projects: 3,
        Photography: 3,
        Articles: 3,
        CaseStudies: 3,
      },
    },
    [heightBreakpoints.heightXXL]: {
      landscape: { Highlights: 6, Skills: 6, Technologies: 6, Testimonials: 1 },
      portrait: {
        Highlights: 10,
        Skills: 10,
        Technologies: 10,
        Testimonials: 1,
        All: 4,
        Featured: 4,
        Projects: 4,
        Photography: 4,
        Articles: 4,
        CaseStudies: 4,
      },
    },
    [heightBreakpoints.heightMAX]: {
      landscape: { Highlights: 6, Skills: 6, Technologies: 6, Testimonials: 1 },
      portrait: {
        Highlights: 10,
        Skills: 10,
        Technologies: 10,
        Testimonials: 1,
        All: 5,
        Featured: 5,
        Projects: 5,
        Photography: 5,
        Articles: 5,
        CaseStudies: 5,
      },
    },
  },

  // Width <= 390px (mobileM)
  [breakpoints.mobileM]: {
    [heightBreakpoints.heightMIN]: {
      landscape: { Highlights: 2, Skills: 2, Technologies: 2, Testimonials: 1 },
      portrait: {
        Highlights: 6,
        Skills: 6,
        Technologies: 6,
        Testimonials: 1,
        All: 1,
        Featured: 1,
        Projects: 1,
        Photography: 1,
        Articles: 1,
        CaseStudies: 1,
      },
    },
    [heightBreakpoints.heightXXS]: {
      landscape: { Highlights: 2, Skills: 2, Technologies: 2, Testimonials: 1 },
      portrait: {
        Highlights: 6,
        Skills: 6,
        Technologies: 6,
        Testimonials: 1,
        All: 1,
        Featured: 1,
        Projects: 1,
        Photography: 1,
        Articles: 1,
        CaseStudies: 1,
      },
    },
    [heightBreakpoints.heightXS]: {
      landscape: { Highlights: 2, Skills: 2, Technologies: 2, Testimonials: 1 },
      portrait: {
        Highlights: 6,
        Skills: 6,
        Technologies: 6,
        Testimonials: 1,
        All: 2,
        Featured: 2,
        Projects: 2,
        Photography: 2,
        Articles: 2,
        CaseStudies: 2,
      },
    },
    [heightBreakpoints.heightS]: {
      landscape: { Highlights: 3, Skills: 3, Technologies: 3, Testimonials: 1 },
      portrait: {
        Highlights: 6,
        Skills: 6,
        Technologies: 6,
        Testimonials: 1,
        All: 3,
        Featured: 3,
        Projects: 3,
        Photography: 3,
        Articles: 3,
        CaseStudies: 3,
      },
    },
    [heightBreakpoints.heightM]: {
      landscape: { Highlights: 4, Skills: 4, Technologies: 4, Testimonials: 1 },
      portrait: {
        Highlights: 8,
        Skills: 8,
        Technologies: 8,
        Testimonials: 1,
        All: 3,
        Featured: 3,
        Projects: 3,
        Photography: 3,
        Articles: 3,
        CaseStudies: 3,
      },
    },
    [heightBreakpoints.heightL]: {
      landscape: { Highlights: 4, Skills: 4, Technologies: 4, Testimonials: 1 },
      portrait: {
        Highlights: 10,
        Skills: 10,
        Technologies: 10,
        Testimonials: 1,
        All: 4,
        Featured: 4,
        Projects: 4,
        Photography: 4,
        Articles: 4,
        CaseStudies: 4,
      },
    },
    [heightBreakpoints.heightXL]: {
      landscape: { Highlights: 6, Skills: 6, Technologies: 6, Testimonials: 1 },
      portrait: {
        Highlights: 10,
        Skills: 10,
        Technologies: 10,
        Testimonials: 1,
        All: 4,
        Featured: 4,
        Projects: 4,
        Photography: 4,
        Articles: 4,
        CaseStudies: 4,
      },
    },
    [heightBreakpoints.heightXXL]: {
      landscape: { Highlights: 6, Skills: 6, Technologies: 6, Testimonials: 1 },
      portrait: {
        Highlights: 10,
        Skills: 10,
        Technologies: 10,
        Testimonials: 1,
        All: 5,
        Featured: 5,
        Projects: 5,
        Photography: 5,
        Articles: 5,
        CaseStudies: 5,
      },
    },
    [heightBreakpoints.heightMAX]: {
      landscape: { Highlights: 6, Skills: 6, Technologies: 6, Testimonials: 1 },
      portrait: {
        Highlights: 10,
        Skills: 10,
        Technologies: 10,
        Testimonials: 1,
        All: 6,
        Featured: 6,
        Projects: 6,
        Photography: 6,
        Articles: 6,
        CaseStudies: 6,
      },
    },
  },

  // Width <= 425px (mobileL)
  [breakpoints.mobileL]: {
    [heightBreakpoints.heightMIN]: {
      landscape: { Highlights: 2, Skills: 2, Technologies: 2, Testimonials: 1 },
      portrait: {
        Highlights: 6,
        Skills: 6,
        Technologies: 6,
        Testimonials: 1,
        All: 1,
        Featured: 1,
        Projects: 1,
        Photography: 1,
        Articles: 1,
        CaseStudies: 1,
      },
    },
    [heightBreakpoints.heightXXS]: {
      landscape: { Highlights: 2, Skills: 2, Technologies: 2, Testimonials: 1 },
      portrait: {
        Highlights: 6,
        Skills: 6,
        Technologies: 6,
        Testimonials: 1,
        All: 1,
        Featured: 1,
        Projects: 1,
        Photography: 1,
        Articles: 1,
        CaseStudies: 1,
      },
    },
    [heightBreakpoints.heightXS]: {
      landscape: { Highlights: 2, Skills: 2, Technologies: 2, Testimonials: 1 },
      portrait: {
        Highlights: 6,
        Skills: 6,
        Technologies: 6,
        Testimonials: 1,
        All: 2,
        Featured: 2,
        Projects: 2,
        Photography: 2,
        Articles: 2,
        CaseStudies: 2,
      },
    },
    [heightBreakpoints.heightS]: {
      landscape: { Highlights: 4, Skills: 4, Technologies: 4, Testimonials: 1 },
      portrait: {
        Highlights: 6,
        Skills: 6,
        Technologies: 6,
        Testimonials: 1,
        All: 3,
        Featured: 3,
        Projects: 3,
        Photography: 3,
        Articles: 3,
        CaseStudies: 3,
      },
    },
    [heightBreakpoints.heightM]: {
      landscape: { Highlights: 4, Skills: 4, Technologies: 4, Testimonials: 1 },
      portrait: {
        Highlights: 6,
        Skills: 6,
        Technologies: 6,
        Testimonials: 1,
        All: 3,
        Featured: 3,
        Projects: 3,
        Photography: 3,
        Articles: 3,
        CaseStudies: 3,
      },
    },
    [heightBreakpoints.heightL]: {
      landscape: { Highlights: 4, Skills: 4, Technologies: 4, Testimonials: 1 },
      portrait: {
        Highlights: 8,
        Skills: 8,
        Technologies: 8,
        Testimonials: 1,
        All: 4,
        Featured: 4,
        Projects: 4,
        Photography: 4,
        Articles: 4,
        CaseStudies: 4,
      },
    },
    [heightBreakpoints.heightXL]: {
      landscape: { Highlights: 8, Skills: 8, Technologies: 8, Testimonials: 1 },
      portrait: {
        Highlights: 8,
        Skills: 8,
        Technologies: 8,
        Testimonials: 1,
        All: 3,
        Featured: 3,
        Projects: 3,
        Photography: 3,
        Articles: 3,
        CaseStudies: 3,
      },
    },
    [heightBreakpoints.heightXXL]: {
      landscape: { Highlights: 6, Skills: 6, Technologies: 6, Testimonials: 1 },
      portrait: {
        Highlights: 10,
        Skills: 10,
        Technologies: 10,
        Testimonials: 1,
        All: 5,
        Featured: 5,
        Projects: 5,
        Photography: 5,
        Articles: 5,
        CaseStudies: 5,
      },
    },
    [heightBreakpoints.heightMAX]: {
      landscape: { Highlights: 6, Skills: 6, Technologies: 6, Testimonials: 1 },
      portrait: {
        Highlights: 10,
        Skills: 10,
        Technologies: 10,
        Testimonials: 1,
        All: 6,
        Featured: 6,
        Projects: 6,
        Photography: 6,
        Articles: 6,
        CaseStudies: 6,
      },
    },
  },

  // Width <= 562px (tabletS)
  [breakpoints.tabletS]: {
    [heightBreakpoints.heightXS]: {
      landscape: { Highlights: 4, Skills: 4, Technologies: 4, Testimonials: 1 },
      portrait: {
        Highlights: 4,
        Skills: 4,
        Technologies: 4,
        Testimonials: 1,
        All: 1,
        Featured: 1,
        Projects: 1,
        Photography: 1,
        Articles: 1,
        CaseStudies: 1,
      },
    },
    [heightBreakpoints.heightS]: {
      landscape: { Highlights: 4, Skills: 4, Technologies: 4, Testimonials: 1 },
      portrait: {
        Highlights: 6,
        Skills: 6,
        Technologies: 6,
        Testimonials: 1,
        All: 2,
        Featured: 2,
        Projects: 2,
        Photography: 2,
        Articles: 2,
        CaseStudies: 2,
      },
    },
    [heightBreakpoints.heightM]: {
      landscape: { Highlights: 4, Skills: 4, Technologies: 4, Testimonials: 1 },
      portrait: {
        Highlights: 8,
        Skills: 8,
        Technologies: 8,
        Testimonials: 1,
        All: 2,
        Featured: 2,
        Projects: 2,
        Photography: 2,
        Articles: 2,
        CaseStudies: 2,
      },
    },
    [heightBreakpoints.heightL]: {
      landscape: { Highlights: 4, Skills: 4, Technologies: 4, Testimonials: 1 },
      portrait: {
        Highlights: 10,
        Skills: 10,
        Technologies: 10,
        Testimonials: 1,
        All: 3,
        Featured: 3,
        Projects: 3,
        Photography: 3,
        Articles: 3,
        CaseStudies: 3,
      },
    },
    [heightBreakpoints.heightXL]: {
      landscape: { Highlights: 8, Skills: 8, Technologies: 8, Testimonials: 1 },
      portrait: {
        Highlights: 8,
        Skills: 8,
        Technologies: 8,
        Testimonials: 1,
        All: 3,
        Featured: 3,
        Projects: 3,
        Photography: 3,
        Articles: 3,
        CaseStudies: 3,
      },
    },
    [heightBreakpoints.heightXXL]: {
      landscape: { Highlights: 6, Skills: 6, Technologies: 6, Testimonials: 1 },
      portrait: {
        Highlights: 10,
        Skills: 10,
        Technologies: 10,
        Testimonials: 1,
        All: 4,
        Featured: 4,
        Projects: 4,
        Photography: 4,
        Articles: 4,
        CaseStudies: 4,
      },
    },
    [heightBreakpoints.heightMAX]: {
      landscape: { Highlights: 6, Skills: 6, Technologies: 6, Testimonials: 1 },
      portrait: {
        Highlights: 10,
        Skills: 10,
        Technologies: 10,
        Testimonials: 1,
        All: 5,
        Featured: 5,
        Projects: 5,
        Photography: 5,
        Articles: 5,
        CaseStudies: 5,
      },
    },
  },

  // Width <= 778px (tabletM)
  [breakpoints.tabletM]: {
    [heightBreakpoints.heightMIN]: {
      landscape: {
        Highlights: 4,
        Skills: 4,
        Technologies: 4,
        Testimonials: 1,
        All: 2,
        Featured: 2,
        Projects: 2,
        Photography: 2,
        Articles: 2,
        CaseStudies: 2,
      },
    },
    [heightBreakpoints.heightXXS]: {
      landscape: {
        Highlights: 4,
        Skills: 4,
        Technologies: 4,
        Testimonials: 1,
        All: 4,
        Featured: 4,
        Projects: 4,
        Photography: 4,
        Articles: 4,
        CaseStudies: 4,
      },
      portrait: { Highlights: 4, Skills: 4, Technologies: 4, Testimonials: 1 },
    },
    [heightBreakpoints.heightXS]: {
      landscape: {
        Highlights: 4,
        Skills: 4,
        Technologies: 4,
        Testimonials: 1,
        All: 2,
        Featured: 2,
        Projects: 2,
        Photography: 2,
        Articles: 2,
        CaseStudies: 2,
      },
      portrait: {
        Highlights: 4,
        Skills: 4,
        Technologies: 4,
        Testimonials: 1,
        All: 4,
        Featured: 4,
        Projects: 4,
        Photography: 4,
        Articles: 4,
        CaseStudies: 4,
      },
    },
    [heightBreakpoints.heightS]: {
      landscape: {
        Highlights: 6,
        Skills: 6,
        Technologies: 6,
        Testimonials: 1,
        All: 6,
        Featured: 6,
        Projects: 6,
        Photography: 6,
        Articles: 6,
        CaseStudies: 6,
      },
      portrait: {
        Highlights: 6,
        Skills: 6,
        Technologies: 6,
        Testimonials: 1,
        All: 4,
        Featured: 4,
        Projects: 4,
        Photography: 4,
        Articles: 4,
        CaseStudies: 4,
      },
    },
    [heightBreakpoints.heightM]: {
      landscape: {
        Highlights: 4,
        Skills: 4,
        Technologies: 4,
        Testimonials: 1,
        All: 8,
        Featured: 8,
        Projects: 8,
        Photography: 8,
        Articles: 8,
        CaseStudies: 8,
      },
      portrait: {
        Highlights: 6,
        Skills: 6,
        Technologies: 6,
        Testimonials: 1,
        All: 6,
        Featured: 6,
        Projects: 6,
        Photography: 6,
        Articles: 6,
        CaseStudies: 6,
      },
    },
    [heightBreakpoints.heightL]: {
      landscape: { Highlights: 4, Skills: 4, Technologies: 4, Testimonials: 1 },
      portrait: {
        Highlights: 8,
        Skills: 8,
        Technologies: 8,
        Testimonials: 1,
        All: 6,
        Featured: 6,
        Projects: 6,
        Photography: 6,
        Articles: 6,
        CaseStudies: 6,
      },
    },
    [heightBreakpoints.heightXL]: {
      landscape: { Highlights: 8, Skills: 8, Technologies: 8, Testimonials: 1 },
      portrait: {
        Highlights: 8,
        Skills: 8,
        Technologies: 8,
        Testimonials: 1,
        All: 6,
        Featured: 6,
        Projects: 6,
        Photography: 6,
        Articles: 6,
        CaseStudies: 6,
      },
    },
    [heightBreakpoints.heightXXL]: {
      landscape: { Highlights: 8, Skills: 8, Technologies: 8, Testimonials: 1 },
      portrait: {
        Highlights: 8,
        Skills: 8,
        Technologies: 8,
        Testimonials: 1,
        All: 8,
        Featured: 8,
        Projects: 8,
        Photography: 8,
        Articles: 8,
        CaseStudies: 8,
      },
    },
    [heightBreakpoints.heightMAX]: {
      landscape: { Highlights: 8, Skills: 8, Technologies: 8, Testimonials: 1 },
      portrait: {
        Highlights: 8,
        Skills: 8,
        Technologies: 8,
        Testimonials: 1,
        All: 10,
        Featured: 10,
        Projects: 10,
        Photography: 10,
        Articles: 10,
        CaseStudies: 10,
      },
    },
  },

  // Width <= 950px (tabletL)
  [breakpoints.tabletL]: {
    [heightBreakpoints.heightMIN]: {
      landscape: {
        Highlights: 4,
        Skills: 4,
        Technologies: 4,
        Testimonials: 1,
        All: 2,
        Featured: 2,
        Projects: 2,
        Photography: 2,
        Articles: 2,
        CaseStudies: 2,
      },
      portrait: {
        Highlights: 4,
        Skills: 4,
        Technologies: 4,
        Testimonials: 1,
        All: 4,
        Featured: 4,
        Projects: 4,
        Photography: 4,
        Articles: 4,
        CaseStudies: 4,
      },
    },
    [heightBreakpoints.heightXXS]: {
      landscape: {
        Highlights: 4,
        Skills: 4,
        Technologies: 4,
        Testimonials: 1,
        All: 4,
        Featured: 4,
        Projects: 4,
        Photography: 4,
        Articles: 4,
        CaseStudies: 4,
      },
      portrait: { Highlights: 4, Skills: 4, Technologies: 4, Testimonials: 1 },
    },
    [heightBreakpoints.heightXS]: {
      landscape: {
        Highlights: 6,
        Skills: 6,
        Technologies: 6,
        Testimonials: 1,
        All: 4,
        Featured: 4,
        Projects: 4,
        Photography: 4,
        Articles: 4,
        CaseStudies: 4,
      },
      portrait: {
        Highlights: 4,
        Skills: 4,
        Technologies: 4,
        Testimonials: 1,
        All: 6,
        Featured: 6,
        Projects: 6,
        Photography: 6,
        Articles: 6,
        CaseStudies: 6,
      },
    },
    [heightBreakpoints.heightS]: {
      landscape: {
        Highlights: 8,
        Skills: 8,
        Technologies: 8,
        Testimonials: 1,
        All: 6,
        Featured: 6,
        Projects: 6,
        Photography: 6,
        Articles: 6,
        CaseStudies: 6,
      },
      portrait: {
        Highlights: 6,
        Skills: 6,
        Technologies: 6,
        Testimonials: 1,
        All: 4,
        Featured: 4,
        Projects: 4,
        Photography: 4,
        Articles: 4,
        CaseStudies: 4,
      },
    },
    [heightBreakpoints.heightM]: {
      landscape: {
        Highlights: 8,
        Skills: 8,
        Technologies: 8,
        Testimonials: 1,
        All: 6,
        Featured: 6,
        Projects: 6,
        Photography: 6,
        Articles: 6,
        CaseStudies: 6,
      },
      portrait: {
        Highlights: 6,
        Skills: 6,
        Technologies: 6,
        Testimonials: 1,
        All: 4,
        Featured: 4,
        Projects: 4,
        Photography: 4,
        Articles: 4,
        CaseStudies: 4,
      },
    },
    [heightBreakpoints.heightL]: {
      landscape: {
        Highlights: 10,
        Skills: 10,
        Technologies: 10,
        Testimonials: 1,
        All: 8,
        Featured: 8,
        Projects: 8,
        Photography: 8,
        Articles: 8,
        CaseStudies: 8,
      },
      portrait: {
        Highlights: 6,
        Skills: 6,
        Technologies: 6,
        Testimonials: 1,
        All: 6,
        Featured: 6,
        Projects: 6,
        Photography: 6,
        Articles: 6,
        CaseStudies: 6,
      },
    },
    [heightBreakpoints.heightXL]: {
      landscape: { Highlights: 8, Skills: 8, Technologies: 8, Testimonials: 1 },
      portrait: {
        Highlights: 8,
        Skills: 8,
        Technologies: 8,
        Testimonials: 1,
        All: 6,
        Featured: 6,
        Projects: 6,
        Photography: 6,
        Articles: 6,
        CaseStudies: 6,
      },
    },
    [heightBreakpoints.heightXXL]: {
      landscape: { Highlights: 8, Skills: 8, Technologies: 8, Testimonials: 1 },
      portrait: {
        Highlights: 8,
        Skills: 8,
        Technologies: 8,
        Testimonials: 1,
        All: 8,
        Featured: 8,
        Projects: 8,
        Photography: 8,
        Articles: 8,
        CaseStudies: 8,
      },
    },
    [heightBreakpoints.heightMAX]: {
      landscape: { Highlights: 8, Skills: 8, Technologies: 8, Testimonials: 1 },
      portrait: {
        Highlights: 8,
        Skills: 8,
        Technologies: 8,
        Testimonials: 1,
        All: 10,
        Featured: 10,
        Projects: 10,
        Photography: 10,
        Articles: 10,
        CaseStudies: 10,
      },
    },
  },

  // Width <= 1024px (laptopS)
  [breakpoints.laptopS]: {
    [heightBreakpoints.heightMIN]: {
      landscape: {
        Highlights: 4,
        Skills: 4,
        Technologies: 4,
        Testimonials: 1,
        All: 2,
        Featured: 2,
        Projects: 2,
        Photography: 2,
        Articles: 2,
        CaseStudies: 2,
      },
      portrait: {
        Highlights: 6,
        Skills: 6,
        Technologies: 6,
        Testimonials: 1,
        All: 4,
        Featured: 4,
        Projects: 4,
        Photography: 4,
        Articles: 4,
        CaseStudies: 4,
      },
    },
    [heightBreakpoints.heightXXS]: {
      landscape: {
        Highlights: 4,
        Skills: 4,
        Technologies: 4,
        Testimonials: 1,
        All: 2,
        Featured: 2,
        Projects: 2,
        Photography: 2,
        Articles: 2,
        CaseStudies: 2,
      },
      portrait: {
        Highlights: 6,
        Skills: 6,
        Technologies: 6,
        Testimonials: 1,
        All: 4,
        Featured: 4,
        Projects: 4,
        Photography: 4,
        Articles: 4,
        CaseStudies: 4,
      },
    },
    [heightBreakpoints.heightXS]: {
      landscape: {
        Highlights: 4,
        Skills: 4,
        Technologies: 4,
        Testimonials: 1,
        All: 4,
        Featured: 4,
        Projects: 4,
        Photography: 4,
        Articles: 4,
        CaseStudies: 4,
      },
      portrait: {
        Highlights: 6,
        Skills: 6,
        Technologies: 6,
        Testimonials: 1,
        All: 4,
        Featured: 4,
        Projects: 4,
        Photography: 4,
        Articles: 4,
        CaseStudies: 4,
      },
    },
    [heightBreakpoints.heightS]: {
      landscape: {
        Highlights: 4,
        Skills: 4,
        Technologies: 4,
        Testimonials: 1,
        All: 6,
        Featured: 6,
        Projects: 6,
        Photography: 6,
        Articles: 6,
        CaseStudies: 6,
      },
      portrait: {
        Highlights: 6,
        Skills: 6,
        Technologies: 6,
        Testimonials: 1,
        All: 4,
        Featured: 4,
        Projects: 4,
        Photography: 4,
        Articles: 4,
        CaseStudies: 4,
      },
    },
    [heightBreakpoints.heightM]: {
      landscape: {
        Highlights: 4,
        Skills: 4,
        Technologies: 4,
        Testimonials: 1,
        All: 6,
        Featured: 6,
        Projects: 6,
        Photography: 6,
        Articles: 6,
        CaseStudies: 6,
      },
      portrait: {
        Highlights: 6,
        Skills: 6,
        Technologies: 6,
        Testimonials: 1,
        All: 4,
        Featured: 4,
        Projects: 4,
        Photography: 4,
        Articles: 4,
        CaseStudies: 4,
      },
    },
    [heightBreakpoints.heightL]: {
      landscape: {
        Highlights: 8,
        Skills: 8,
        Technologies: 8,
        Testimonials: 1,
        All: 8,
        Featured: 8,
        Projects: 8,
        Photography: 8,
        Articles: 8,
        CaseStudies: 8,
      },
      portrait: {
        Highlights: 8,
        Skills: 8,
        Technologies: 8,
        Testimonials: 1,
        All: 6,
        Featured: 6,
        Projects: 6,
        Photography: 6,
        Articles: 6,
        CaseStudies: 6,
      },
    },
    [heightBreakpoints.heightXL]: {
      landscape: {
        Highlights: 8,
        Skills: 8,
        Technologies: 8,
        Testimonials: 1,
        All: 8,
        Featured: 8,
        Projects: 8,
        Photography: 8,
        Articles: 8,
        CaseStudies: 8,
      },
      portrait: {
        Highlights: 8,
        Skills: 8,
        Technologies: 8,
        Testimonials: 1,
        All: 6,
        Featured: 6,
        Projects: 6,
        Photography: 6,
        Articles: 6,
        CaseStudies: 6,
      },
    },
    [heightBreakpoints.heightXXL]: {
      landscape: {
        Highlights: 8,
        Skills: 8,
        Technologies: 8,
        Testimonials: 1,
        All: 8,
        Featured: 8,
        Projects: 8,
        Photography: 8,
        Articles: 8,
        CaseStudies: 8,
      },
      portrait: {
        Highlights: 8,
        Skills: 8,
        Technologies: 8,
        Testimonials: 1,
        All: 8,
        Featured: 8,
        Projects: 8,
        Photography: 8,
        Articles: 8,
        CaseStudies: 8,
      },
    },
    [heightBreakpoints.heightMAX]: {
      landscape: {
        Highlights: 8,
        Skills: 8,
        Technologies: 8,
        Testimonials: 1,
        All: 10,
        Featured: 10,
        Projects: 10,
        Photography: 10,
        Articles: 10,
        CaseStudies: 10,
      },
      portrait: {
        Highlights: 8,
        Skills: 8,
        Technologies: 8,
        Testimonials: 1,
        All: 10,
        Featured: 10,
        Projects: 10,
        Photography: 10,
        Articles: 10,
        CaseStudies: 10,
      },
    },
  },

  // Width <= 1280px (laptopM)
  [breakpoints.laptopM]: {
    [heightBreakpoints.heightMIN]: {
      landscape: {
        Highlights: 4,
        Skills: 4,
        Technologies: 4,
        Testimonials: 1,
        All: 2,
        Featured: 2,
        Projects: 2,
        Photography: 2,
        Articles: 2,
        CaseStudies: 2,
      },
      portrait: {
        Highlights: 6,
        Skills: 6,
        Technologies: 6,
        Testimonials: 1,
        All: 4,
        Featured: 4,
        Projects: 4,
        Photography: 4,
        Articles: 4,
        CaseStudies: 4,
      },
    },
    [heightBreakpoints.heightXXS]: {
      landscape: {
        Highlights: 4,
        Skills: 4,
        Technologies: 4,
        Testimonials: 1,
        All: 2,
        Featured: 2,
        Projects: 2,
        Photography: 2,
        Articles: 2,
        CaseStudies: 2,
      },
      portrait: {
        Highlights: 6,
        Skills: 6,
        Technologies: 6,
        Testimonials: 1,
        All: 4,
        Featured: 4,
        Projects: 4,
        Photography: 4,
        Articles: 4,
        CaseStudies: 4,
      },
    },
    [heightBreakpoints.heightXS]: {
      landscape: {
        Highlights: 4,
        Skills: 4,
        Technologies: 4,
        Testimonials: 1,
        All: 4,
        Featured: 4,
        Projects: 4,
        Photography: 4,
        Articles: 4,
        CaseStudies: 4,
      },
      portrait: {
        Highlights: 6,
        Skills: 6,
        Technologies: 6,
        Testimonials: 1,
        All: 4,
        Featured: 4,
        Projects: 4,
        Photography: 4,
        Articles: 4,
        CaseStudies: 4,
      },
    },
    [heightBreakpoints.heightS]: {
      landscape: {
        Highlights: 4,
        Skills: 4,
        Technologies: 4,
        Testimonials: 1,
        All: 4,
        Featured: 4,
        Projects: 4,
        Photography: 4,
        Articles: 4,
        CaseStudies: 4,
      },
      portrait: {
        Highlights: 6,
        Skills: 6,
        Technologies: 6,
        Testimonials: 1,
        All: 4,
        Featured: 4,
        Projects: 4,
        Photography: 4,
        Articles: 4,
        CaseStudies: 4,
      },
    },
    [heightBreakpoints.heightM]: {
      landscape: {
        Highlights: 4,
        Skills: 4,
        Technologies: 4,
        Testimonials: 1,
        All: 6,
        Featured: 6,
        Projects: 6,
        Photography: 6,
        Articles: 6,
        CaseStudies: 6,
      },
      portrait: {
        Highlights: 6,
        Skills: 6,
        Technologies: 6,
        Testimonials: 1,
        All: 4,
        Featured: 4,
        Projects: 4,
        Photography: 4,
        Articles: 4,
        CaseStudies: 4,
      },
    },
    [heightBreakpoints.heightL]: {
      landscape: {
        Highlights: 6,
        Skills: 6,
        Technologies: 6,
        Testimonials: 1,
        All: 6,
        Featured: 6,
        Projects: 6,
        Photography: 6,
        Articles: 6,
        CaseStudies: 6,
      },
      portrait: {
        Highlights: 8,
        Skills: 8,
        Technologies: 8,
        Testimonials: 1,
        All: 6,
        Featured: 6,
        Projects: 6,
        Photography: 6,
        Articles: 6,
        CaseStudies: 6,
      },
    },
    [heightBreakpoints.heightXL]: {
      landscape: {
        Highlights: 8,
        Skills: 8,
        Technologies: 8,
        Testimonials: 1,
        All: 8,
        Featured: 8,
        Projects: 8,
        Photography: 8,
        Articles: 8,
        CaseStudies: 8,
      },
      portrait: {
        Highlights: 8,
        Skills: 8,
        Technologies: 8,
        Testimonials: 1,
        All: 6,
        Featured: 6,
        Projects: 6,
        Photography: 6,
        Articles: 6,
        CaseStudies: 6,
      },
    },
    [heightBreakpoints.heightXXL]: {
      landscape: {
        Highlights: 8,
        Skills: 8,
        Technologies: 8,
        Testimonials: 1,
        All: 8,
        Featured: 8,
        Projects: 8,
        Photography: 8,
        Articles: 8,
        CaseStudies: 8,
      },
      portrait: {
        Highlights: 8,
        Skills: 8,
        Technologies: 8,
        Testimonials: 1,
        All: 8,
        Featured: 8,
        Projects: 8,
        Photography: 8,
        Articles: 8,
        CaseStudies: 8,
      },
    },
    [heightBreakpoints.heightMAX]: {
      landscape: {
        Highlights: 8,
        Skills: 8,
        Technologies: 8,
        Testimonials: 1,
        All: 8,
        Featured: 8,
        Projects: 8,
        Photography: 8,
        Articles: 8,
        CaseStudies: 8,
      },
      portrait: {
        Highlights: 8,
        Skills: 8,
        Technologies: 8,
        Testimonials: 1,
        All: 8,
        Featured: 8,
        Projects: 8,
        Photography: 8,
        Articles: 8,
        CaseStudies: 8,
      },
    },
  },

  // Width <= 1440px (laptopL)
  [breakpoints.laptopL]: {
    [heightBreakpoints.heightMIN]: {
      landscape: {
        Highlights: 4,
        Skills: 4,
        Technologies: 4,
        Testimonials: 1,
        All: 2,
        Featured: 2,
        Projects: 2,
        Photography: 2,
        Articles: 2,
        CaseStudies: 2,
      },
      portrait: {
        Highlights: 6,
        Skills: 6,
        Technologies: 6,
        Testimonials: 1,
        All: 4,
        Featured: 4,
        Projects: 4,
        Photography: 4,
        Articles: 4,
        CaseStudies: 4,
      },
    },
    [heightBreakpoints.heightXXS]: {
      landscape: {
        Highlights: 4,
        Skills: 4,
        Technologies: 4,
        Testimonials: 1,
        All: 2,
        Featured: 2,
        Projects: 2,
        Photography: 2,
        Articles: 2,
        CaseStudies: 2,
      },
      portrait: {
        Highlights: 6,
        Skills: 6,
        Technologies: 6,
        Testimonials: 1,
        All: 4,
        Featured: 4,
        Projects: 4,
        Photography: 4,
        Articles: 4,
        CaseStudies: 4,
      },
    },
    [heightBreakpoints.heightXS]: {
      landscape: {
        Highlights: 4,
        Skills: 4,
        Technologies: 4,
        Testimonials: 1,
        All: 2,
        Featured: 2,
        Projects: 2,
        Photography: 2,
        Articles: 2,
        CaseStudies: 2,
      },
      portrait: {
        Highlights: 6,
        Skills: 6,
        Technologies: 6,
        Testimonials: 1,
        All: 4,
        Featured: 4,
        Projects: 4,
        Photography: 4,
        Articles: 4,
        CaseStudies: 4,
      },
    },
    [heightBreakpoints.heightS]: {
      landscape: {
        Highlights: 4,
        Skills: 4,
        Technologies: 4,
        Testimonials: 1,
        All: 4,
        Featured: 4,
        Projects: 4,
        Photography: 4,
        Articles: 4,
        CaseStudies: 4,
      },
      portrait: {
        Highlights: 6,
        Skills: 6,
        Technologies: 6,
        Testimonials: 1,
        All: 4,
        Featured: 4,
        Projects: 4,
        Photography: 4,
        Articles: 4,
        CaseStudies: 4,
      },
    },
    [heightBreakpoints.heightM]: {
      landscape: {
        Highlights: 4,
        Skills: 4,
        Technologies: 4,
        Testimonials: 1,
        All: 4,
        Featured: 4,
        Projects: 4,
        Photography: 4,
        Articles: 4,
        CaseStudies: 4,
      },
      portrait: {
        Highlights: 6,
        Skills: 6,
        Technologies: 6,
        Testimonials: 1,
        All: 4,
        Featured: 4,
        Projects: 4,
        Photography: 4,
        Articles: 4,
        CaseStudies: 4,
      },
    },
    [heightBreakpoints.heightL]: {
      landscape: {
        Highlights: 6,
        Skills: 6,
        Technologies: 6,
        Testimonials: 1,
        All: 6,
        Featured: 6,
        Projects: 6,
        Photography: 6,
        Articles: 6,
        CaseStudies: 6,
      },
      portrait: {
        Highlights: 8,
        Skills: 8,
        Technologies: 8,
        Testimonials: 1,
        All: 6,
        Featured: 6,
        Projects: 6,
        Photography: 6,
        Articles: 6,
        CaseStudies: 6,
      },
    },
    [heightBreakpoints.heightXL]: {
      landscape: {
        Highlights: 6,
        Skills: 6,
        Technologies: 6,
        Testimonials: 1,
        All: 6,
        Featured: 6,
        Projects: 6,
        Photography: 6,
        Articles: 6,
        CaseStudies: 6,
      },
      portrait: {
        Highlights: 8,
        Skills: 8,
        Technologies: 8,
        Testimonials: 1,
        All: 6,
        Featured: 6,
        Projects: 6,
        Photography: 6,
        Articles: 6,
        CaseStudies: 6,
      },
    },
    [heightBreakpoints.heightXXL]: {
      landscape: {
        Highlights: 8,
        Skills: 8,
        Technologies: 8,
        Testimonials: 1,
        All: 6,
        Featured: 6,
        Projects: 6,
        Photography: 6,
        Articles: 6,
        CaseStudies: 6,
      },
      portrait: {
        Highlights: 8,
        Skills: 8,
        Technologies: 8,
        Testimonials: 1,
        All: 8,
        Featured: 8,
        Projects: 8,
        Photography: 8,
        Articles: 8,
        CaseStudies: 8,
      },
    },
    [heightBreakpoints.heightMAX]: {
      landscape: {
        Highlights: 8,
        Skills: 8,
        Technologies: 8,
        Testimonials: 1,
        All: 8,
        Featured: 8,
        Projects: 8,
        Photography: 8,
        Articles: 8,
        CaseStudies: 8,
      },
      portrait: {
        Highlights: 8,
        Skills: 8,
        Technologies: 8,
        Testimonials: 1,
        All: 8,
        Featured: 8,
        Projects: 8,
        Photography: 8,
        Articles: 8,
        CaseStudies: 8,
      },
    },
  },

  // Width <= 1920px (desktop) continued
  [breakpoints.desktop]: {
    [heightBreakpoints.heightMIN]: {
      landscape: {
        Highlights: 3,
        Skills: 3,
        Technologies: 3,
        Testimonials: 1,
        All: 3,
        Featured: 3,
        Projects: 3,
        Photography: 3,
        Articles: 3,
        CaseStudies: 3,
      },
      portrait: {
        Highlights: 6,
        Skills: 6,
        Technologies: 6,
        Testimonials: 1,
        All: 4,
        Featured: 4,
        Projects: 4,
        Photography: 4,
        Articles: 4,
        CaseStudies: 4,
      },
    },
    [heightBreakpoints.heightXXS]: {
      landscape: {
        Highlights: 3,
        Skills: 3,
        Technologies: 3,
        Testimonials: 1,
        All: 3,
        Featured: 3,
        Projects: 3,
        Photography: 3,
        Articles: 3,
        CaseStudies: 3,
      },
      portrait: {
        Highlights: 6,
        Skills: 6,
        Technologies: 6,
        Testimonials: 1,
        All: 4,
        Featured: 4,
        Projects: 4,
        Photography: 4,
        Articles: 4,
        CaseStudies: 4,
      },
    },
    [heightBreakpoints.heightXS]: {
      landscape: {
        Highlights: 3,
        Skills: 3,
        Technologies: 3,
        Testimonials: 1,
        All: 3,
        Featured: 3,
        Projects: 3,
        Photography: 3,
        Articles: 3,
        CaseStudies: 3,
      },
      portrait: {
        Highlights: 6,
        Skills: 6,
        Technologies: 6,
        Testimonials: 1,
        All: 4,
        Featured: 4,
        Projects: 4,
        Photography: 4,
        Articles: 4,
        CaseStudies: 4,
      },
    },
    [heightBreakpoints.heightS]: {
      landscape: {
        Highlights: 3,
        Skills: 3,
        Technologies: 3,
        Testimonials: 1,
        All: 6,
        Featured: 6,
        Projects: 6,
        Photography: 6,
        Articles: 6,
        CaseStudies: 6,
      },
      portrait: {
        Highlights: 6,
        Skills: 6,
        Technologies: 6,
        Testimonials: 1,
        All: 4,
        Featured: 4,
        Projects: 4,
        Photography: 4,
        Articles: 4,
        CaseStudies: 4,
      },
    },
    [heightBreakpoints.heightM]: {
      landscape: {
        Highlights: 6,
        Skills: 6,
        Technologies: 6,
        Testimonials: 1,
        All: 6,
        Featured: 6,
        Projects: 6,
        Photography: 6,
        Articles: 6,
        CaseStudies: 6,
      },
      portrait: {
        Highlights: 6,
        Skills: 6,
        Technologies: 6,
        Testimonials: 1,
        All: 4,
        Featured: 4,
        Projects: 4,
        Photography: 4,
        Articles: 4,
        CaseStudies: 4,
      },
    },
    [heightBreakpoints.heightL]: {
      landscape: {
        Highlights: 6,
        Skills: 6,
        Technologies: 6,
        Testimonials: 1,
        All: 6,
        Featured: 6,
        Projects: 6,
        Photography: 6,
        Articles: 6,
        CaseStudies: 6,
      },
      portrait: {
        Highlights: 8,
        Skills: 8,
        Technologies: 8,
        Testimonials: 1,
        All: 6,
        Featured: 6,
        Projects: 6,
        Photography: 6,
        Articles: 6,
        CaseStudies: 6,
      },
    },
    [heightBreakpoints.heightXL]: {
      landscape: {
        Highlights: 6,
        Skills: 6,
        Technologies: 6,
        Testimonials: 1,
        All: 9,
        Featured: 9,
        Projects: 9,
        Photography: 9,
        Articles: 9,
        CaseStudies: 9,
      },
      portrait: {
        Highlights: 8,
        Skills: 8,
        Technologies: 8,
        Testimonials: 1,
        All: 6,
        Featured: 6,
        Projects: 6,
        Photography: 6,
        Articles: 6,
        CaseStudies: 6,
      },
    },
    [heightBreakpoints.heightXXL]: {
      landscape: {
        Highlights: 8,
        Skills: 8,
        Technologies: 8,
        Testimonials: 1,
        All: 9,
        Featured: 9,
        Projects: 9,
        Photography: 9,
        Articles: 9,
        CaseStudies: 9,
      },
      portrait: {
        Highlights: 8,
        Skills: 8,
        Technologies: 8,
        Testimonials: 1,
        All: 8,
        Featured: 8,
        Projects: 8,
        Photography: 8,
        Articles: 8,
        CaseStudies: 8,
      },
    },
    [heightBreakpoints.heightMAX]: {
      landscape: {
        Highlights: 8,
        Skills: 8,
        Technologies: 8,
        Testimonials: 1,
        All: 9,
        Featured: 9,
        Projects: 9,
        Photography: 9,
        Articles: 9,
        CaseStudies: 9,
      },
      portrait: {
        Highlights: 8,
        Skills: 8,
        Technologies: 8,
        Testimonials: 1,
        All: 8,
        Featured: 8,
        Projects: 8,
        Photography: 8,
        Articles: 8,
        CaseStudies: 8,
      },
    },
  },

  // Width > 1920px (desktopL)
  [breakpoints.desktopL]: {
    [heightBreakpoints.heightMIN]: {
      landscape: {
        Highlights: 3,
        Skills: 3,
        Technologies: 3,
        Testimonials: 1,
        All: 2,
        Featured: 2,
        Projects: 2,
        Photography: 2,
        Articles: 2,
        CaseStudies: 2,
      },
      portrait: {
        Highlights: 6,
        Skills: 6,
        Technologies: 6,
        Testimonials: 1,
        All: 4,
        Featured: 4,
        Projects: 4,
        Photography: 4,
        Articles: 4,
        CaseStudies: 4,
      },
    },
    [heightBreakpoints.heightXXS]: {
      landscape: {
        Highlights: 3,
        Skills: 3,
        Technologies: 3,
        Testimonials: 1,
        All: 2,
        Featured: 2,
        Projects: 2,
        Photography: 2,
        Articles: 2,
        CaseStudies: 2,
      },
      portrait: {
        Highlights: 6,
        Skills: 6,
        Technologies: 6,
        Testimonials: 1,
        All: 4,
        Featured: 4,
        Projects: 4,
        Photography: 4,
        Articles: 4,
        CaseStudies: 4,
      },
    },
    [heightBreakpoints.heightXS]: {
      landscape: {
        Highlights: 3,
        Skills: 3,
        Technologies: 3,
        Testimonials: 1,
        All: 2,
        Featured: 2,
        Projects: 2,
        Photography: 2,
        Articles: 2,
        CaseStudies: 2,
      },
      portrait: {
        Highlights: 6,
        Skills: 6,
        Technologies: 6,
        Testimonials: 1,
        All: 4,
        Featured: 4,
        Projects: 4,
        Photography: 4,
        Articles: 4,
        CaseStudies: 4,
      },
    },
    [heightBreakpoints.heightS]: {
      landscape: {
        Highlights: 3,
        Skills: 3,
        Technologies: 3,
        Testimonials: 1,
        All: 3,
        Featured: 3,
        Projects: 3,
        Photography: 3,
        Articles: 3,
        CaseStudies: 3,
      },
      portrait: {
        Highlights: 6,
        Skills: 6,
        Technologies: 6,
        Testimonials: 1,
        All: 4,
        Featured: 4,
        Projects: 4,
        Photography: 4,
        Articles: 4,
        CaseStudies: 4,
      },
    },
    [heightBreakpoints.heightM]: {
      landscape: {
        Highlights: 6,
        Skills: 6,
        Technologies: 6,
        Testimonials: 1,
        All: 6,
        Featured: 6,
        Projects: 6,
        Photography: 6,
        Articles: 6,
        CaseStudies: 6,
      },
      portrait: {
        Highlights: 6,
        Skills: 6,
        Technologies: 6,
        Testimonials: 1,
        All: 4,
        Featured: 4,
        Projects: 4,
        Photography: 4,
        Articles: 4,
        CaseStudies: 4,
      },
    },
    [heightBreakpoints.heightL]: {
      landscape: {
        Highlights: 6,
        Skills: 6,
        Technologies: 6,
        Testimonials: 1,
        All: 6,
        Featured: 6,
        Projects: 6,
        Photography: 6,
        Articles: 6,
        CaseStudies: 6,
      },
      portrait: {
        Highlights: 8,
        Skills: 8,
        Technologies: 8,
        Testimonials: 1,
        All: 6,
        Featured: 6,
        Projects: 6,
        Photography: 6,
        Articles: 6,
        CaseStudies: 6,
      },
    },
    [heightBreakpoints.heightXL]: {
      landscape: {
        Highlights: 6,
        Skills: 6,
        Technologies: 6,
        Testimonials: 1,
        All: 6,
        Featured: 6,
        Projects: 6,
        Photography: 6,
        Articles: 6,
        CaseStudies: 6,
      },
      portrait: {
        Highlights: 8,
        Skills: 8,
        Technologies: 8,
        Testimonials: 1,
        All: 6,
        Featured: 6,
        Projects: 6,
        Photography: 6,
        Articles: 6,
        CaseStudies: 6,
      },
    },
    [heightBreakpoints.heightXXL]: {
      landscape: {
        Highlights: 9,
        Skills: 9,
        Technologies: 9,
        Testimonials: 1,
        All: 6,
        Featured: 6,
        Projects: 6,
        Photography: 6,
        Articles: 6,
        CaseStudies: 6,
      },
      portrait: {
        Highlights: 8,
        Skills: 8,
        Technologies: 8,
        Testimonials: 1,
        All: 8,
        Featured: 8,
        Projects: 8,
        Photography: 8,
        Articles: 8,
        CaseStudies: 8,
      },
    },
    [heightBreakpoints.heightMAX]: {
      landscape: {
        Highlights: 9,
        Skills: 9,
        Technologies: 9,
        Testimonials: 1,
        All: 8,
        Featured: 8,
        Projects: 8,
        Photography: 8,
        Articles: 8,
        CaseStudies: 8,
      },
      portrait: {
        Highlights: 8,
        Skills: 8,
        Technologies: 8,
        Testimonials: 1,
        All: 8,
        Featured: 8,
        Projects: 8,
        Photography: 8,
        Articles: 8,
        CaseStudies: 8,
      },
    },
  },
};

export default itemConfigs;
