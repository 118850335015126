// components/Menu/Button/Button.jsx

import React from "react";
import { useTheme } from "../../../context/ThemeContext";
import {
  ButtonWrapper,
  Slider,
  SliderEl,
  PerspectiveTextWrapper,
} from "./Button.styled";

export default function Button({ isActive, toggleMenu, $viewport }) {
  const { theme } = useTheme();

  return (
    <ButtonWrapper $viewport={$viewport}>
      <Slider
        animate={{ top: isActive ? "-100%" : "0%" }}
        transition={{ duration: 0.5, type: "tween", ease: [0.76, 0, 0.24, 1] }}
      >
        <SliderEl theme={theme} onClick={toggleMenu} $viewport={$viewport}>
          <PerspectiveText label="Menu" $viewport={$viewport} />
        </SliderEl>
        <SliderEl
          theme={theme}
          onClick={toggleMenu}
          isSecond
          $viewport={$viewport}
        >
          <PerspectiveText label="Close" $viewport={$viewport} />
        </SliderEl>
      </Slider>
    </ButtonWrapper>
  );
}

function PerspectiveText({ label, $viewport }) {
  const { theme } = useTheme();
  return (
    <PerspectiveTextWrapper
      theme={theme}
      className="perspectiveText"
      $viewport={$viewport}
    >
      <p>{label}</p>
      <p>{label}</p>
    </PerspectiveTextWrapper>
  );
}
