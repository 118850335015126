import React from "react";
import Markdown from "react-markdown";
import Skeleton from "react-loading-skeleton";

function GoalSection({ goal, image, loading }) {
  return (
    <div className="case-study__goal">
      {loading ? (
        <Skeleton width={500} height={300} />
      ) : (
        <figure className="case-study__goal-image">
          <img src={image} alt="goal" />
        </figure>
      )}
      <div className="case-study__goal-content">
        <h2>{loading ? <Skeleton width={200} /> : "The Goal:"}</h2>
        {loading ? <Skeleton count={3} /> : <Markdown>{goal}</Markdown>}
      </div>
    </div>
  );
}

export default GoalSection;
