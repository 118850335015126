import React from "react";
import Markdown from "react-markdown";
import Skeleton from "react-loading-skeleton";

const PhaseSection = ({ phases, loading }) => {
  const sortedPhases = [...phases].sort((a, b) => a.order - b.order);

  return (
    <section className="case-study__phases">
      {loading ? (
        <Skeleton count={3} />
      ) : (
        sortedPhases.map((phase, index) => (
          <div
            key={phase.id}
            className={`phase ${index % 2 === 0 ? "even" : "odd"} ${
              !phase.img ? "no-image" : ""
            }`}
          >
            <div className="phase-content">
              <h3>
                Phase {index + 1}: {phase.title}
              </h3>
              <div className="phase-description">
                <Markdown>{phase.description}</Markdown>
              </div>
            </div>
            {phase.img && (
              <div className="phase-image">
                <img src={phase.img} alt={phase.title} />
              </div>
            )}
          </div>
        ))
      )}
    </section>
  );
};

export default PhaseSection;
