// services/CaseStudyService.js
import axios from 'axios';

const BASE_URL = '/api/case-studies/';

export const fetchCaseStudy = async (caseStudySlug) => {
  try {
    const response = await axios.get(`${BASE_URL}${caseStudySlug}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching case study:', error);
    throw error;
  }
};

export const fetchCaseStudies = async () => {
  try {
    const response = await axios.get(`${BASE_URL}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching case studies:', error);
    throw error;
  }
};