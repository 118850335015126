// components/Resume/ResumeDetails.jsx

import React, { useState, useEffect, useMemo, useCallback } from "react";
import styled from "styled-components";

import { useTheme } from "../../context/ThemeContext";
import { useViewport } from "../../context/ViewportContext";

import { breakpoints, heightBreakpoints } from "../../styles/breakpoints";
import { hexToRgba } from "../../utils/hexToRGBA";

import ResumeTabs from "./ResumeTabs";
import ResumeHighlights from "./ResumeHighlights";
import Skills from "../Skills/Skills";
import Technologies from "../Technologies/Technologies";
import Testimonial from "../Testimonial/Testimonial";
import Pagination from "../Project/Pagination";
import itemConfigs from "../../constants/itemConfigs";

const ResumeContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 40rem 1fr 5rem;
  height: 100%;
  width: 100%;
  grid-column: 1/-1;
  grid-row: 1/-1;

  ${({ viewport }) => {
    // Helper function to check orientation
    const isLandscape = viewport.width > viewport.height;

    if (viewport.width <= breakpoints.mobileS) {
      return `
    grid-template-columns: ${isLandscape ? "1fr 1fr" : "1fr"};
    grid-template-rows: ${isLandscape ? "1fr" : "auto 1fr"};
    ${isLandscape ? "grid-auto-flow: column;" : ""}
  `;
    }
    if (viewport.width <= breakpoints.mobileM) {
      return `
    grid-template-columns: ${isLandscape ? "1fr 1fr" : "1fr"};
    grid-template-rows: ${isLandscape ? "1fr" : "auto 1fr"};
    ${isLandscape ? "grid-auto-flow: column;" : ""}
  `;
    }
    if (viewport.width <= breakpoints.mobileL) {
      return `
    grid-template-columns: ${isLandscape ? "1fr 1fr 1fr" : "1fr"};
    grid-template-rows: ${isLandscape ? "1fr" : "auto 1fr auto"};
    ${isLandscape ? "grid-auto-flow: column;" : ""}
  `;
    }
    if (viewport.width <= breakpoints.tabletS) {
      return `
    grid-template-columns: ${isLandscape ? "1fr 1fr 1fr" : "1fr 1fr"};
    grid-template-rows: ${isLandscape ? "1fr 1fr" : "auto 1fr auto"};
    ${isLandscape ? "grid-auto-flow: column;" : ""}
  `;
    }
    if (viewport.width <= breakpoints.tabletM) {
      return `
    grid-template-columns: ${isLandscape ? "1fr 1fr 1fr 1fr" : "1fr 1fr"};
    grid-template-rows: ${isLandscape ? "1fr 1fr" : "auto 1fr auto"};
    ${isLandscape ? "grid-auto-flow: column;" : ""}
  `;
    }
    if (viewport.width <= breakpoints.tabletL) {
      return `
    grid-template-columns: ${isLandscape ? "1fr 1fr 1fr 1fr" : "1fr 1fr 1fr"};
    grid-template-rows: ${isLandscape ? "1fr 1fr 1fr" : "auto 1fr auto"};
    ${isLandscape ? "grid-auto-flow: column;" : ""}
  `;
    }
    if (viewport.width <= breakpoints.laptopS) {
      return `
        grid-template-columns: 5.25rem 1fr;
      `;
    }
    if (viewport.width <= breakpoints.laptopL) {
      return `
        grid-template-columns: 5.25rem 1fr;
      `;
    }
    if (viewport.width <= breakpoints.desktop) {
      return `
        grid-template-columns: 30rem 1fr 5rem;
      `;
    }
    if (viewport.width <= breakpoints.desktopL) {
      return `
        grid-template-columns: 40rem 1fr 5rem;
      `;
    }
  }}
`;

const ContentArea = styled.div`
  position: relative;
  display: grid;
  grid-template-rows: 5.25rem 1fr 5.25rem;
  grid-column: 2/-1;
  grid-row: 1/-1;
  z-index: 1900;
  pointer-events: auto;
  align-items: center;

  ${({ $viewport }) => {
    if (!$viewport) return ""; // Add this check to prevent errors when viewport is undefined
    const { width, height, isLandscape } = $viewport;

    if (width <= breakpoints.mobileS) {
      return `
        grid-template-rows: 3rem 1fr;
        grid-template-columns: 1fr 2.5rem;
        padding: 0;
      `;
    }
    if (width <= breakpoints.mobileM) {
      return `
        grid-template-rows: 3rem 1fr;
        grid-template-columns: 1fr 2.5rem;
        padding: 0;
      `;
    }
    if (width <= breakpoints.mobileL) {
      return `
        grid-template-rows: 3rem 1fr;
        grid-template-columns: 1fr 2.5rem;
        padding: 0;
      `;
    }
    if (width <= breakpoints.tabletS) {
      return `
        grid-template-rows: 3.5rem 1fr;
        grid-template-columns: 1fr 2.5rem;
        padding: 0;
      `;
    }
    if (width <= breakpoints.tabletM) {
      return `
        grid-template-rows: ${isLandscape ? `2.75rem 1fr` : `3.5rem 1fr`};
        grid-template-columns: 1fr 2.5rem;
        padding: 0;
      `;
    }
    if (width <= breakpoints.tabletL) {
      return `
        grid-template-rows: ${isLandscape ? `2.75rem 1fr` : `3.5rem 1fr`};
        grid-template-columns: ${isLandscape ? `1fr 2.5rem` : `1fr 2.5rem`};
        padding: 0;
      `;
    }

    if (width <= breakpoints.laptopS) {
      return `
        grid-template-rows: 5.25rem 1fr;
        grid-template-columns: 1fr 2.5rem;
        padding: 0;
        grid-column: 2/-1;
      `;
    }
    if (width <= breakpoints.laptopL) {
      return `
        grid-template-rows: 5.25rem 1fr;
        grid-template-columns: 1fr 3.5rem;
        padding: 0;
        grid-column: 2/-1;
      `;
    }
    if (width <= breakpoints.desktop) {
      return `
        grid-template-rows: 5.25rem 1fr 5.25rem;
        grid-template-columns: 1fr 5.25rem;
      `;
    }
    if (width <= breakpoints.desktopL) {
      return `
        grid-template-rows: 5.25rem 1fr 5.25rem;
        grid-template-columns: 1fr 5.25rem;
      `;
    }
  }}
`;

const GlassOverlay = styled.div`
  position: relative;
  display: grid;
  width: 100%;
  height: 100%;
  grid-column: 1/-1;
  grid-row: 1/-1;
  grid-template-columns: 35.5rem 1fr 5.25rem;
  grid-template-rows: 5.25rem 1fr 5.25rem;
  background: ${({ theme }) => hexToRgba(theme.bodyBgSecondary, 0.25)};
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  margin: 0 auto;
  z-index: 900;

  ${({ $viewport }) => {
    if (!$viewport) return "";
    const { width, height } = $viewport;
    const isLandscape = width > height;

    if (width <= breakpoints.mobileS) {
      return `
        grid-template-columns: 3rem 1fr;
        grid-template-rows: 3em 1fr 3rem;
      `;
    }
    if (width <= breakpoints.mobileM) {
      return `
        grid-template-columns: 3rem 1fr;
        grid-template-rows: 3em 1fr 3rem;
      `;
    }
    if (width <= breakpoints.mobileL) {
      return `
        grid-template-columns: 3rem 1fr;
        grid-template-rows: 3em 1fr 3rem;

      `;
    }
    if (width <= breakpoints.tabletS) {
      return `
        grid-template-columns: 3.5rem 1fr;
        grid-template-rows: 3.5em 1fr 3rem;
      `;
    }
    if (width <= breakpoints.tabletM) {
      return `
        grid-template-columns: ${isLandscape ? `17.5rem 1fr` : `3.5rem 1fr`};
        grid-template-rows: 3.5em 1fr 3.5rem;
      `;
    }
    if (width <= breakpoints.tabletL) {
      return `
        grid-template-columns: ${isLandscape ? `20rem 1fr` : `3.5rem 1fr`};
        grid-template-rows: ${isLandscape ? `2.75rem 1fr 2.75rem` : `3.5em 1fr 3.5rem`};
      `;
    }

    if (width <= breakpoints.laptopS) {
      return `
        grid-template-columns: 25rem 1fr 5.25rem;
      `;
    }
    if (width <= breakpoints.laptopL) {
      return `
        grid-template-columns: ${isLandscape ? `25rem 1fr 5.25rem` : `30rem 1fr 5.25rem`};

      `;
    }
    if (width <= breakpoints.desktop) {
      return `
        grid-template-columns: 30rem 1fr 5.25rem;
      `;
    }
    if (width <= breakpoints.desktopL) {
      return `
        grid-template-columns: 35.5rem 1fr 5.25rem;

      `;
    }
  }}
`;

function ResumeDetails({
  experience,
  isEmptyState,
  isCollapsed,
  selectedTab,
  onTabChange,
}) {
  const { theme } = useTheme();
  const viewport = useViewport();
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [isDataReady, setIsDataReady] = useState(false);
  const [currentItems, setCurrentItems] = useState([]);

  const currentTab = selectedTab;

  const itemsPerPage = useMemo(() => {
    const { width, height, isLandscape } = viewport;

    // Determine width category
    const widthCategory =
      Object.entries(breakpoints)
        .sort(([, a], [, b]) => a - b)
        .find(([, value]) => width <= value)?.[1] || breakpoints.desktopL;

    // Determine height category
    const heightCategory =
      Object.entries(heightBreakpoints)
        .sort(([, a], [, b]) => a - b)
        .find(([, value]) => height <= value)?.[1] ||
      heightBreakpoints.heightXL;

    const orientation = isLandscape ? "landscape" : "portrait";

    // Get the configuration for the current viewport
    const config = itemConfigs[widthCategory]?.[heightCategory]?.[
      orientation
    ] || {
      Highlights: 6,
      Skills: 6,
      Technologies: 6,
      Testimonials: 2,
    };

    return config;
  }, [viewport]);

  useEffect(() => {
    setCurrentPage(1);
  }, [currentTab, experience]);

  useEffect(() => {
    setIsLoading(true);
    setIsDataReady(false);

    if (isEmptyState || !experience || !currentTab) {
      setCurrentItems([]);
      setIsLoading(false);
      setIsDataReady(true);
      return;
    }

    const currentItemsPerPage = itemsPerPage[currentTab] || 0;
    const indexOfLastItem = currentPage * currentItemsPerPage;
    const indexOfFirstItem = indexOfLastItem - currentItemsPerPage;

    if (currentTab && experience[currentTab.toLowerCase()]) {
      const items = experience[currentTab.toLowerCase()].slice(
        indexOfFirstItem,
        indexOfLastItem
      );
      setCurrentItems(items);
    } else {
      setCurrentItems([]);
    }

    setIsLoading(false);
    setIsDataReady(true);
  }, [experience, currentTab, currentPage, itemsPerPage, isEmptyState]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const renderSelectedComponent = () => {
    if (isEmptyState || !currentTab || !isDataReady) return null;

    const componentKey = `${experience?.id}-${currentTab}-${currentPage}-${viewport.width}-${viewport.height}`;
    const commonProps = {
      key: componentKey,
      data: currentItems || [],
      isLoading,
      error,
      itemsPerPage: itemsPerPage[currentTab] || 0,
    };

    switch (currentTab) {
      case "Highlights":
        return (
          <ResumeHighlights
            {...commonProps}
            itemsPerPage={itemsPerPage[currentTab] || 0}
          />
        );
      case "Skills":
        return (
          <Skills
            {...commonProps}
            itemsPerPage={itemsPerPage[currentTab] || 0}
          />
        );
      case "Technologies":
        return (
          <Technologies
            {...commonProps}
            itemsPerPage={itemsPerPage[currentTab] || 0}
          />
        );
      case "Testimonials":
        return (
          <Testimonial
            {...commonProps}
            itemsPerPage={itemsPerPage[currentTab] || 0}
          />
        );
      default:
        return null;
    }
  };

  // Calculate total pages
  const totalPages = useMemo(() => {
    if (!experience || !selectedTab) return 0;
    const totalItems = experience[selectedTab.toLowerCase()]?.length || 0;
    return Math.ceil(totalItems / (itemsPerPage[selectedTab] || 1));
  }, [experience, selectedTab, itemsPerPage]);

  const hasPagination = totalPages > 1;

  return (
    <ResumeContainer className={"ResumeContainer"} viewport={viewport}>
      {!isEmptyState && (
        <>
          <GlassOverlay className={"glass"} theme={theme} $viewport={viewport}>
            <ContentArea
              $viewport={viewport}
              $hasPagination={hasPagination}
              className="test"
            >
              {renderSelectedComponent()}
              {!isLoading &&
                !error &&
                experience &&
                selectedTab &&
                hasPagination && (
                  <Pagination
                    currentPage={currentPage}
                    totalItems={
                      experience[selectedTab.toLowerCase()]?.length || 0
                    }
                    itemsPerPage={itemsPerPage[selectedTab] || 0}
                    onPageChange={handlePageChange}
                    viewport={viewport}
                  />
                )}
              <ResumeTabs
                selectedTab={selectedTab}
                setSelectedTab={onTabChange}
                viewport={viewport}
                isCollapsed={isCollapsed}
              />
            </ContentArea>
          </GlassOverlay>
        </>
      )}
    </ResumeContainer>
  );
}

export default ResumeDetails;
