import React from "react";
import styled, { keyframes } from "styled-components";
import { useTheme } from "../../context/ThemeContext";

const LoaderContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.backgroundColor};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  z-index: 9999;
  opacity: ${({ isLoading }) => (isLoading ? 1 : 0)};
  transition: opacity 0.5s ease-out;
  pointer-events: ${({ isLoading }) => (isLoading ? "auto" : "none")};
`;

const LoaderContent = styled.div`
  text-align: center;
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const LoaderSpinner = styled.div`
  border: 5px solid ${({ theme }) => theme.iconColorSticker};
  border-top: 5px solid ${({ theme }) => theme.accentColorPrimary};
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: ${spin} 1s linear infinite;
  margin: 0 auto 20px;
`;

const LoaderText = styled.p`
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  font-weight: 400;
  color: ${({ theme }) => theme.textColor};
`;

const Loader = ({ isLoading, text = "Loading..." }) => {
  const { theme } = useTheme();

  return (
    <LoaderContainer isLoading={isLoading} theme={theme} className="Loader">
      <LoaderContent>
        <LoaderSpinner theme={theme} />
        <LoaderText theme={theme}>{text}</LoaderText>
      </LoaderContent>
    </LoaderContainer>
  );
};

export default Loader;
