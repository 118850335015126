// App.js

import React, { Suspense, lazy } from "react";
import { HashRouter as Router } from "react-router-dom";
import { ThemeProvider } from "./context/ThemeContext";
import { ViewportProvider } from "./context/ViewportContext";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import AppContent from "./AppContent"; // We'll create this component
import { SlideOutProvider } from "./context/SlideOutContext";

// Lazy load the Experience component
const Experience = lazy(() => import("./components/Experience/Experience"));

function App() {
  return (
    <ThemeProvider>
      <ViewportProvider>
        <SlideOutProvider>
          <Router>
            <AppContent />
          </Router>
        </SlideOutProvider>
      </ViewportProvider>
    </ThemeProvider>
  );
}

export default App;
