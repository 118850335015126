import React from "react";
import Markdown from "react-markdown";
import Skeleton from "react-loading-skeleton";

function OutcomeSection({ outcomes, loading }) {
  return (
    <div className="case-study__outcome-wrapper">
      <h2>{loading ? <Skeleton width={200} /> : "Outcome:"}</h2>
      {loading ? (
        <Skeleton count={3} />
      ) : (
        outcomes.map((outcome) => (
          <div key={outcome.id} className="case-study__outcome">
            <Markdown>{outcome.description}</Markdown>
          </div>
        ))
      )}
    </div>
  );
}

export default OutcomeSection;
