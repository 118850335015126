import React from "react";
import { useTheme } from "../../context/ThemeContext";
import { ReactComponent as AvatarSVG } from "./avatar.svg";
import styled from "styled-components";
import { themes } from "../../styles/abstracts/themes";
import { hexToRgb } from "../../utils/hexToRgb";
import { breakpoints, media } from "../../styles/breakpoints";

const StyledAvatar = styled(AvatarSVG)`
  // Root style for the largest display (desktop and above)
  transform: scale(2.25) translateY(-2.5rem) translateX(-1.5rem);
  width: clamp(12rem, 20rem, 22rem);
  height: clamp(12rem, 20rem, 22rem);
  margin: -2.5rem;

  .self-portrait--cls-1 {
    fill: ${({ theme }) => theme.hoverColorSecondary};
  }

  .self-portrait--cls-2 {
    fill: ${({ theme }) => `rgba(${hexToRgb(theme.iconColorSecondary)}, 0.8)`};
  }

  .self-portrait--cls-3 {
    fill: ${({ theme }) => theme.iconColorSticker};
  }

  .self-portrait--cls-5 {
    fill: ${({ theme }) => theme.hoverColorPrimary};
  }

  .self-portrait--cls-6 {
    fill: ${({ theme }) => `rgba(${hexToRgb(theme.iconColorSecondary)}, .5)`};
  }

  .self-portrait--cls-7 {
    fill: ${({ theme }) => theme.iconStrokeColor};
  }

  ${({ $viewport }) => {
    const { width, height, isLandscape } = $viewport;

    if (width <= breakpoints.mobileL) {
      return `
        transform: scale(1.25) translateY(-2.25rem) translateX(-1.75rem);
        width: clamp(9rem, 12.5rem, 15rem);
        height: clamp(9rem, 12.5rem, 15rem);
        margin: -1.5rem;
      `;
    }
    if (width <= breakpoints.tabletS) {
      return `
      ${
        isLandscape
          ? `
        transform: scale(1.5) translateY(-1.65rem) translateX(-0.75rem);
        width: clamp(9rem, 10.5rem, 15rem);
        height: clamp(9rem, 11.5rem, 15rem);
        margin: -1.5rem;      
      `
          : `
        transform: scale(1.5) translateY(-1.65rem) translateX(-1.15rem);
        width: clamp(9rem, 12.5rem, 15rem);
        height: clamp(9rem, 12.5rem, 15rem);
        margin: -1.5rem;      
      `
      }
      `;
    }
    if (width <= breakpoints.tabletM) {
      return `

      ${
        isLandscape
          ? `
          transform: scale(1.5) translateY(-3.75rem) translateX(-2.25rem);
          width: clamp(9rem, 12.5rem, 15rem);
          height: clamp(9rem, 15rem, 17.5rem);
          margin: 0;      
        `
          : `
          transform: scale(1.5) translateY(-1.65rem) translateX(-1.15rem);
          width: clamp(9rem, 12.5rem, 15rem);
          height: clamp(9rem, 12.5rem, 15rem);
          margin: -1.5rem;      
        `
      }
      `;
    }

    if (width <= breakpoints.tabletL) {
      return `
      ${
        isLandscape
          ? `
            transform: scale(1.1) translateY(-7rem) translateX(-4rem);
            width: clamp(10rem, 15rem, 20rem);
            height: clamp(10rem, 19rem, 20rem);
            margin: 0;
          `
          : `
            transform: scale(1.1) translateY(-10rem) translateX(-5.5rem);
            width: clamp(10rem, 17.5rem, 20rem);
            height: clamp(15rem, 25rem, 25rem);
            margin: 0;
          `
      }
      `;
    }

    if (width <= breakpoints.laptopS) {
      return `
      ${
        isLandscape
          ? `
            transform: scale(1.1) translateY(-7rem) translateX(-4rem);
            width: clamp(10rem, 15rem, 20rem);
            height: clamp(10rem, 19rem, 20rem);
            margin: 0;
          `
          : `
            transform: scale(1.1) translateY(-10rem) translateX(-5.5rem);
            width: clamp(10rem, 17.5rem, 20rem);
            height: clamp(15rem, 25rem, 25rem);
            margin: 0;
          `
      }
      `;
    }

    if (width <= breakpoints.laptopM) {
      return `
      ${
        isLandscape
          ? `
            transform: scale(1.1) translateY(-7rem) translateX(-4rem);
            width: clamp(10rem, 15rem, 20rem);
            height: clamp(10rem, 19rem, 20rem);
            margin: 0;
          `
          : `
            transform: scale(1.1) translateY(-10rem) translateX(-5.5rem);
            width: clamp(10rem, 17.5rem, 20rem);
            height: clamp(15rem, 25rem, 25rem);
            margin: 0;
          `
      }
      `;
    }

    if (width <= breakpoints.laptopL) {
      return `
      ${
        isLandscape
          ? `
            transform: scale(1.1) translateY(-10rem) translateX(-4rem);
            width: clamp(10rem, 16rem, 20rem);
            height: clamp(20rem, 25rem, 25rem);
            margin: 0;
          `
          : `
            transform: scale(1.1) translateY(-10rem) translateX(-5.5rem);
            width: clamp(10rem, 17.5rem, 20rem);
            height: clamp(15rem, 25rem, 25rem);
            margin: 0;
          `
      }
      `;
    }

    if (width <= breakpoints.desktop) {
      return `
${
  isLandscape
    ? `
            transform: scale(1.1) translateY(-10rem) translateX(-4rem);
            width: clamp(10rem, 16rem, 20rem);
            height: clamp(20rem, 25rem, 25rem);
            margin: 0;
          `
    : `
            transform: scale(1.1) translateY(-10rem) translateX(-5.5rem);
            width: clamp(10rem, 17.5rem, 20rem);
            height: clamp(15rem, 25rem, 25rem);
            margin: 0;
          `
}
      `;
    }
  }}
`;

function Avatar({ $viewport }) {
  const { theme } = useTheme();

  return <StyledAvatar theme={themes[theme]} $viewport={$viewport} />;
}

export default Avatar;
