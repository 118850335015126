import React, { useState, useEffect, useRef } from "react";
import { gsap } from "gsap";
import { TextPlugin } from "gsap/TextPlugin";
import styled, { ThemeProvider } from "styled-components";
import { useTheme } from "../../context/ThemeContext";
import { typography } from "../../styles/abstracts/typography";
import Avatar from "../Avatar/Avatar";
import {
  breakpoints,
  heightBreakpoints,
  media,
} from "../../styles/breakpoints";
import { dialogues } from "./dialogueData";
import { useViewport } from "../../context/ViewportContext";

gsap.registerPlugin(TextPlugin);

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 15rem;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.primaryColor};
  pointer-events: auto;

  ${({ $viewport }) => {
    const { width, height, isLandscape } = $viewport;

    if (width <= breakpoints.mobileS) {
      let minHeight = 225;
      let maxHeight = 55;

      if (height <= heightBreakpoints.heightXS) {
        minHeight = 200;
      }
      if (height <= heightBreakpoints.heightM) {
        maxHeight = 45;
      }
      if (height >= heightBreakpoints.heightM) {
        maxHeight = 35;
      }

      return `
        max-height: ${maxHeight}vh;
        min-height: 350px;
        grid-template-columns: 1fr;
        grid-template-rows: 5rem 1fr;
      `;
    } else if (width <= breakpoints.mobileM) {
      let minHeight = 225;
      let maxHeight = 55;

      if (height <= heightBreakpoints.heightXS) {
        minHeight = 200;
      }
      if (height >= heightBreakpoints.heightM) {
        maxHeight = 45;
      }

      return `
        max-height: ${maxHeight}vh;
        min-height: 350px;
        grid-template-columns: 1fr;
        grid-template-rows: 5rem 1fr;
      `;
    } else if (width <= breakpoints.mobileL) {
      let minHeight = 225;
      let maxHeight = 55;

      if (height <= heightBreakpoints.heightXS) {
        minHeight = 200;
      }
      if (height <= heightBreakpoints.heightM) {
        maxHeight = 35;
      }
      if (height <= heightBreakpoints.heightXL) {
        maxHeight = 35;
      }

      return `
        max-height: ${maxHeight}vh;
        min-height: 350px;
        grid-template-columns: 1fr;
        grid-template-rows: 5rem 1fr;
      `;
    } else if (width <= breakpoints.tabletS) {
      let minHeight = 225;
      let maxHeight = 50;

      if (height <= heightBreakpoints.heightXS) {
        minHeight = 200;
      }
      if (height >= heightBreakpoints.heightS) {
        maxHeight = 40;
      }
      if (height >= heightBreakpoints.heightM) {
        maxHeight = 35;
      }

      return `
        max-height: ${maxHeight}vh;
        min-height: 350px;
        grid-template-columns: 1fr;
        grid-template-rows: 6rem 1fr;
      `;
    } else if (width <= breakpoints.tabletM) {
      let minHeight = 225;
      let maxHeight = 35;

      if (height <= heightBreakpoints.heightM) {
        minHeight = 200;
        maxHeight = 30;
      }

      if (height >= heightBreakpoints.heightM) {
        minHeight = 200;
        maxHeight = 32.5;
      }

      return `
        ${
          isLandscape
            ? `
              max-height: ${maxHeight}vh;
              min-height: ${minHeight}px;
              grid-template-rows: 1fr;
              grid-template-columns: 1fr 12.5rem;
              column-gap: 1.5rem;
            `
            : `
              max-height: ${maxHeight}vh;
              min-height: 375px;
              grid-template-columns: 1fr;
              grid-template-rows: 6rem 1fr;
            `
        }
      `;
    } else if (width <= breakpoints.tabletL) {
      let minHeight = 225;
      let maxHeight = 25;

      if (height <= heightBreakpoints.heightM) {
        minHeight = 200;
        maxHeight = 30;
      }

      if (height >= heightBreakpoints.heightM) {
        minHeight = 200;
        maxHeight = 20;
      }

      return `
        ${
          isLandscape
            ? `
              max-height: ${maxHeight}vh;
              min-height: ${minHeight}px;
              grid-template-rows: 1fr;
              grid-template-columns: 1fr 12.5rem;
              column-gap: 1.5rem;  
            `
            : `
              max-height: ${maxHeight}vh;
              min-height: 250px;
              grid-template-rows: 1fr;
              grid-template-columns: 1fr 15rem;
              column-gap: 2rem;    
            `
        }
      `;
    } else if (width <= breakpoints.laptopS) {
      let minHeight = 225;
      let maxHeight = 25;

      if (height <= heightBreakpoints.heightXS) {
        minHeight = 200;
      }
      if (height >= heightBreakpoints.heightXL) {
        maxHeight = 20;
      }
      return `
        ${
          isLandscape
            ? `
              max-height: ${maxHeight}vh;
              min-height: ${minHeight}px;
              grid-template-rows: 1fr;
              grid-template-columns: 1fr 12.5rem;
              column-gap: 1.5rem;  
            `
            : `
              max-height: ${maxHeight}vh;
              min-height: 250px;
              grid-template-rows: 1fr;
              grid-template-columns: 1fr 15rem;
              column-gap: 2rem;    
            `
        }
      `;
    } else if (width <= breakpoints.laptopM) {
      let minHeight = 225;
      let maxHeight = 25;

      if (height <= heightBreakpoints.heightXS) {
        minHeight = 200;
      }
      if (height >= heightBreakpoints.heightXL) {
        maxHeight = 20;
      }
      return `
        ${
          isLandscape
            ? `
              max-height: ${maxHeight}vh;
              min-height: ${minHeight}px;
              grid-template-rows: 1fr;
              grid-template-columns: 1fr 12.5rem;
              column-gap: 1.5rem;  
            `
            : `
              max-height: ${maxHeight}vh;
              min-height: 250px;
              grid-template-rows: 1fr;
              grid-template-columns: 1fr 15rem;
              column-gap: 2rem;    
            `
        }
      `;
    } else if (width <= breakpoints.laptopL) {
      let minHeight = 225;
      let maxHeight = 25;

      if (height <= heightBreakpoints.heightXS) {
        minHeight = 200;
      }
      if (height >= heightBreakpoints.heightXL) {
        maxHeight = 20;
      }
      return `
        ${
          isLandscape
            ? `
              max-height: ${maxHeight}vh;
              min-height: ${minHeight}px;
              grid-template-rows: 1fr;
              grid-template-columns: 1fr 17.5rem;
              column-gap: 1.5rem;  
            `
            : `
              max-height: ${maxHeight}vh;
              min-height: 250px;
              grid-template-rows: 1fr;
              grid-template-columns: 1fr 12.5rem;
              column-gap: 2rem;    
            `
        }
      `;
    } else if (width <= breakpoints.desktop) {
      let minHeight = 225;
      let maxHeight = 25;

      if (height <= heightBreakpoints.heightXS) {
        minHeight = 200;
      }
      if (height >= heightBreakpoints.heightXL) {
        maxHeight = 20;
      }

      return `
        ${
          isLandscape
            ? `
              max-height: ${maxHeight}vh;
              min-height: ${minHeight}px;
              grid-template-rows: 1fr;
              grid-template-columns: 1fr 17.5rem;
              column-gap: 1.5rem;  
            `
            : `
              max-height: ${maxHeight}vh;
              min-height: 250px;
              grid-template-rows: 1fr;
              grid-template-columns: 1fr 12.5rem;
              column-gap: 2rem;    
            `
        }
      `;
    } else if (width <= breakpoints.desktopL) {
      return `
        height: 400px;
        min-height: 400px;
        flex-direction: row;
        gap: 10rem;
      `;
    } else
      return `
        max-height: 45vh;
        min-height: 350px;
        flex-direction: column-reverse;
        justify-content: flex-end;
      `;
  }}
`;

const InfoWrapper = styled.div`
  display: grid;

  ${({ $viewport }) => {
    const { width, height, isLandscape } = $viewport;

    if (width <= breakpoints.mobileL) {
      return `
        grid-row: 2;
        grid-template-columns: 1fr;
        grid-template-rows: 2.5rem 1fr 3.5rem;
      `;
    }
    if (width <= breakpoints.tabletS) {
      return `
        grid-row: 2;
        grid-template-columns: 1fr;
        grid-template-rows: 2.5rem 1fr 4rem;
      `;
    }
    if (width <= breakpoints.tabletM) {
      return `
        ${
          isLandscape
            ? `
              grid-template-rows: 2.5rem auto 1fr;
              margin-top: -1rem;
              row-gap: 1rem;
            `
            : `
              grid-row: 2;
              grid-template-columns: 1fr;
              grid-template-rows: 2.5rem 1fr 4rem;
            `
        }
      `;
    }
    if (width <= breakpoints.tabletL) {
      return `
        ${
          isLandscape
            ? `
              grid-template-rows: 2.5rem auto 1fr;
              margin-top: -1rem;
              row-gap: 1rem;
            `
            : `
              grid-column: 1;
              margin-top: -1rem;
              grid-template-columns: 1fr;
              grid-template-rows: 3.5rem auto 4rem;
            `
        }
      `;
    }
    if (width <= breakpoints.laptopS) {
      return `
        ${
          isLandscape
            ? `
              grid-template-rows: 2.5rem auto 1fr;
              margin-top: -1rem;
              row-gap: 1rem;
            `
            : `
              grid-column: 1;
              margin-top: -1rem;
              grid-template-columns: 1fr;
              grid-template-rows: 3.5rem auto 5rem;
            `
        }
      `;
    }
    if (width <= breakpoints.laptopM) {
      return `
        ${
          isLandscape
            ? `
              grid-template-rows: 2.5rem auto 1fr;
              margin-top: -1rem;
              row-gap: 1rem;
            `
            : `
              grid-column: 1;
              margin-top: -1rem;
              grid-template-columns: 1fr;
              grid-template-rows: 3.5rem auto 5rem;
            `
        }
      `;
    }
    if (width <= breakpoints.laptopL) {
      return `
        ${
          isLandscape
            ? `
              grid-template-rows: 3rem auto 1fr;
              margin-top: -1rem;
              row-gap: 1rem;
            `
            : `
              grid-column: 1;
              margin-top: -1rem;
              grid-template-columns: 1fr;
              grid-template-rows: 3.5rem auto 5rem;
            `
        }
      `;
    }
    if (width <= breakpoints.desktop) {
      return `
        ${
          isLandscape
            ? `
              grid-template-rows: 3rem auto 1fr;
              margin-top: -1rem;
              row-gap: 1rem;
            `
            : `
              grid-column: 1;
              margin-top: -1rem;
              grid-template-columns: 1fr;
              grid-template-rows: 3.5rem auto 5rem;
            `
        }
      `;
    }
  }}
`;

const Name = styled.div`
  background-color: white;
  border-radius: 0.5rem;
  color: ${({ theme }) => theme.textColorDark};
  font-family: ${typography.fontSubheadline};
  font-weight: bold;
  z-index: 45;

  ${({ $viewport }) => {
    const { width, height, isLandscape } = $viewport;

    if (width <= breakpoints.mobileS) {
      return `
        grid-row: 1;
        margin: 0 auto;
        max-width: 150px;
        padding: 0.5rem 1.5rem;
        font-size: ${typography.fontSizeSmall};
        z-index: 55;
      `;
    } else if (width <= breakpoints.mobileM) {
      return `
        grid-row: 1;
        margin: 0 auto;
        max-width: 150px;
        padding: 0.5rem 1.5rem;
        font-size: ${typography.fontSizeSmall};
        z-index: 55;
      `;
    } else if (width <= breakpoints.mobileL) {
      return `
        grid-row: 1;
        margin: 0 auto;
        max-width: 150px;
        padding: 0.5rem 1.5rem;
        font-size: ${typography.fontSizeMedium};
        z-index: 55;
      `;
    } else if (width <= breakpoints.tabletS) {
      return `
        grid-row: 1;
        margin: 0 auto;
        max-width: 150px;
        padding: 0.5rem 1.5rem;
        font-size: ${typography.fontSizeMedium};
        z-index: 55;
      `;
    } else if (width <= breakpoints.tabletM) {
      return `
        margin: ${isLandscape ? `0 auto 0 2rem` : `0 auto`};
        max-width: 200px;
        padding: 0.5rem 1.5rem;
        font-size: ${typography.fontSizeMedium};
        z-index: 55;
      `;
    } else if (width <= breakpoints.tabletL) {
      return `
      ${
        isLandscape
          ? `
            margin-left: 2.5rem;
            margin-top: 0;
            max-width: 100px;
            padding: 0.5rem 1.25rem;
            font-size: ${typography.fontSizeSmall};          
          `
          : `
            margin: 0 auto 0 2.5rem;
            max-width: 200px;
            padding: 0.75rem 1.75rem;
            font-size: ${typography.fontSizeMedium};
            z-index: 55;      
          `
      }
      `;
    } else if (width <= breakpoints.laptopS) {
      return `
      ${
        isLandscape
          ? `
            margin-left: 2.5rem;
            margin-top: 0;
            max-width: 100px;
            padding: 0.5rem 1.25rem;
            font-size: ${typography.fontSizeSmall};          
          `
          : `
            margin: 0 auto 0 2.5rem;
            max-width: 200px;
            padding: 0.75rem 1.75rem;
            font-size: ${typography.fontSizeMedium};
            z-index: 55;      
          `
      }
      `;
    } else if (width <= breakpoints.laptopM) {
      return `
      ${
        isLandscape
          ? `
            margin-left: 2.5rem;
            margin-top: 0;
            max-width: 100px;
            padding: 0.5rem 1.25rem;
            font-size: ${typography.fontSizeSmall};          
          `
          : `
            margin: 0 auto 0 2.5rem;
            max-width: 200px;
            padding: 0.75rem 1.75rem;
            font-size: ${typography.fontSizeMedium};
            z-index: 55;      
          `
      }
      `;
    } else if (width <= breakpoints.laptopL) {
      return `
      ${
        isLandscape
          ? `
            margin-left: 7.5rem;
            margin-top: 0;
            max-width: fit-content;
            padding: 0.5rem 1.25rem;
            font-size: ${typography.fontSizeLarge};          
          `
          : `
            margin: 0 auto 0 2.5rem;
            max-width: 200px;
            padding: 0.75rem 1.75rem;
            font-size: ${typography.fontSizeMedium};
            z-index: 55;      
          `
      }
      `;
    } else if (width <= breakpoints.desktop) {
      return `
      ${
        isLandscape
          ? `
            margin-left: 7.5rem;
            margin-top: 0;
            max-width: fit-content;
            padding: 0.5rem 1.25rem;
            font-size: ${typography.fontSizeLarge};          
          `
          : `
            margin: 0 auto 0 2.5rem;
            max-width: 200px;
            padding: 0.75rem 1.75rem;
            font-size: ${typography.fontSizeMedium};
            z-index: 55;      
          `
      }
      `;
    } else
      return `
      margin-left: 12.5rem;
      margin-top: -2rem;
      max-width: 200px;
      padding: 1.25rem 2.5rem;
      font-size: ${typography.fontSizeXl};
    `;
  }}
`;

const Dialogue = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid white;
  border-radius: 0.5rem;
  color: ${({ theme }) => theme.textColorLight};
  flex-grow: 1;
  gap: 0.5rem;
  overflow-y: auto;

  ${({ $viewport }) => {
    const { width, height, isLandscape } = $viewport;

    if (width <= breakpoints.mobileS) {
      return `
        margin: 1.5rem auto 0 auto;
        max-width: 175px;
        padding: 0.5rem 1.5rem;

        grid-row: 2;
      `;
    } else if (width <= breakpoints.mobileM) {
      return `
        margin: 1.125rem auto 0 auto;
        max-width: 175px;
        padding: 0.5rem 1.5rem;

        grid-row: 2;
      `;
    } else if (width <= breakpoints.mobileL) {
      return `
        margin: 1.125rem auto 0 auto;
        max-width: 225px;
        padding: 0.75rem 1.75rem;
      `;
    } else if (width <= breakpoints.tabletS) {
      return `
        margin: 1.125rem auto 0 auto;
        max-width: 300px;
        padding: 0.75rem 1.75rem;
      `;
    } else if (width <= breakpoints.tabletM) {
      return `
      ${
        isLandscape
          ? `
            margin-left: 2rem;
            margin-top: 0;
            max-width: 100%;
            max-height: 150px;
            padding: 0.5rem 1rem;
            gap: 0.5rem;
          `
          : `
            margin: 0 auto;
            margin-top: 1.25rem;
            max-width: 350px;
            max-height: 125px;
            padding: 1rem 2rem;
          `
      }
      `;
    } else if (width <= breakpoints.tabletL) {
      return `
        ${
          isLandscape
            ? `
              margin-left: 2.5rem;
              margin-top: 0;
              max-width: 100%;
              max-height: 150px;
              padding: 0.5rem 1rem;
            `
            : `
              margin-left: 2.5rem;
              margin-top: 1.25rem;
              max-width: 100%;
              max-height: 150px;
              padding: 1rem 2rem;
            `
        }
      `;
    } else if (width <= breakpoints.laptopS) {
      return `
        ${
          isLandscape
            ? `
              margin-left: 2.5rem;
              margin-top: 0;
              max-width: 100%;
              max-height: 150px;
              padding: 0.5rem 1rem;
            `
            : `
              margin-left: 2.5rem;
              margin-top: 1.25rem;
              max-width: 100%;
              max-height: 150px;
              padding: 1rem 2rem;
            `
        }
      `;
    } else if (width <= breakpoints.laptopM) {
      return `
        ${
          isLandscape
            ? `
              margin-left: 2.5rem;
              margin-top: 0;
              max-width: 100%;
              max-height: 150px;
              padding: 0.5rem 1rem;
            `
            : `
              margin-left: 2.5rem;
              margin-top: 1.25rem;
              max-width: 100%;
              max-height: 150px;
              padding: 1rem 2rem;
            `
        }
      `;
    } else if (width <= breakpoints.laptopL) {
      return `
        ${
          isLandscape
            ? `
              margin-left: 7.5rem;
              margin-top: 0;
              max-width: 100%;
              max-height: 150px;
              padding: 0.5rem 1rem;
            `
            : `
              margin-left: 2.5rem;
              margin-top: 1.25rem;
              max-width: 100%;
              max-height: 150px;
              padding: 1rem 2rem;
            `
        }
      `;
    } else if (width <= breakpoints.desktop) {
      return `
        ${
          isLandscape
            ? `
              margin-left: 7.5rem;
              margin-top: 0;
              max-width: 100%;
              max-height: 150px;
              padding: 0.5rem 1rem;
            `
            : `
              margin-left: 2.5rem;
              margin-top: 1.25rem;
              max-width: 100%;
              max-height: 150px;
              padding: 1rem 2rem;
            `
        }
      `;
    } else if (width <= breakpoints.desktopL) {
      return `
        ${
          isLandscape
            ? `
              margin-left: 7.5rem;
              margin-top: 0;
              max-width: 100%;
              max-height: 150px;
              padding: 0.5rem 1rem;
            `
            : `
              margin-left: 2.5rem;
              margin-top: 1.25rem;
              max-width: 100%;
              max-height: 150px;
              padding: 1rem 2rem;
            `
        }
      `;
    } else
      return `
      margin-left: 12.5rem;
      margin-top: 2.5rem;
      padding: 1.25rem 2.5rem;
    `;
  }}
`;

const DialogueLine = styled.p`
  padding: 0;
  line-height: 1.5;
  min-height: 1.5em;

  ${({ $viewport }) => {
    const { width, height, isLandscape } = $viewport;
    if (width <= breakpoints.mobileS) {
      return `
        font-size: ${typography.fontSizeXxs};
      `;
    } else if (width <= breakpoints.mobileL) {
      return `
        font-size: ${typography.fontSizeXs};
      `;
    } else if (width <= breakpoints.tabletS) {
      return `
        font-size: ${typography.fontSizeXs};
      `;
    } else if (width <= breakpoints.tabletM) {
      return `
        font-size: ${isLandscape ? typography.fontSizeXxs : typography.fontSizeSmall};
      `;
    } else if (width <= breakpoints.tabletL) {
      return `
        font-size: ${isLandscape ? typography.fontSizeXs : typography.fontSizeSmall};
      `;
    } else if (width <= breakpoints.laptopS) {
      return `
        font-size: ${typography.fontSizeSmall};
      `;
    } else if (width <= breakpoints.laptopL) {
      return `
        font-size: ${typography.fontSizeSmall};
      `;
    } else if (width <= breakpoints.desktop) {
      return `
        font-size: ${typography.fontSizeSmall};
      `;
    } else if (width <= breakpoints.desktopL) {
      return `
        font-size: ${typography.fontSizeMedium};
      `;
    } else
      return `
      font-size: ${typography.fontSizeSmall};
    `;
  }}
`;

const DialogueWrapper = styled.div`
  ${({ $viewport }) => {
    const { width, height, isLandscape } = $viewport;

    if (width <= breakpoints.mobileS) {
      return `
        height: 100%;
        justify-content: start;
      `;
    } else if (width <= breakpoints.mobileL) {
      return `
        height: 100%;
        justify-content: start;
        gap: 1.25rem;
      `;
    } else if (width <= breakpoints.laptopL) {
      return `
        height: 100%;
      `;
    } else if (width <= breakpoints.desktop) {
      return `
        height: 100%;
      `;
    } else if (width <= breakpoints.desktopL) {
      return `
        height: 100%;
        justify-content: flex-start;
        gap: 1.25rem;
      `;
    } else
      return `
      height: 100%;
      justify-content: space-between;
      gap: 1.5rem;
    `;
  }}
`;

const AvatarWrapper = styled.div`
  border-top-left-radius: 255px 15px;
  border-top-right-radius: 15px 225px;
  border-bottom-right-radius: 225px 15px;
  border-bottom-left-radius: 15px 255px;
  background-color: ${({ theme }) => theme.iconStrokeColor};
  z-index: 50;

  ${({ $viewport, theme }) => {
    const { width, height, isLandscape } = $viewport;
    if (width <= breakpoints.mobileS) {
      return `
        grid-row: 1;
        margin: -2.5rem auto 0 auto;
        height: 120px;
        width: 120px;
        border: 2px solid ${theme.iconColorSticker};
      `;
    } else if (width <= breakpoints.mobileL) {
      return `
        margin: -2.5rem auto 0 auto;
        height: 120px;
        width: 120px;
        border: 2px solid ${theme.iconColorSticker};
      `;
    } else if (width <= breakpoints.tabletS) {
      return `

        ${
          isLandscape
            ? `
          margin: -1.5rem auto 0 auto;
          height: 150px;
          width: 125px;
          border: 4px solid ${theme.iconColorSticker};        
        `
            : `
          margin: -2.5rem auto 0 auto;
          height: 140px;
          width: 140px;
          border: 4px solid ${theme.iconColorSticker};        
        `
        }
      `;
    } else if (width <= breakpoints.tabletM) {
      return `

        ${
          isLandscape
            ? `
          margin: -1.5rem auto 0 auto;
          height: 175px;
          width: 150px;
          border: 4px solid ${theme.iconColorSticker};        
        `
            : `
          margin: -2.5rem auto 0 auto;
          height: 140px;
          width: 140px;
          border: 4px solid ${theme.iconColorSticker};        
        `
        }
      `;
    } else if (width <= breakpoints.tabletL) {
      return `
        border: 4px solid ${theme.iconColorSticker};

        ${
          isLandscape
            ? `
              margin: -1.5rem 2rem 0 auto;
              height: 175px;
              width: 150px;

            `
            : `
              margin: -1.5rem 2rem 0 auto;
              height: 225px;
              width: 155px;
              grid-column: 2;
            `
        }
      `;
    } else if (width <= breakpoints.laptopS) {
      return `
        border: 4px solid ${theme.iconColorSticker};

        ${
          isLandscape
            ? `
              margin: -1.5rem 2rem 0 auto;
              height: 175px;
              width: 150px;

            `
            : `
              margin: -1.5rem 2rem 0 auto;
              height: 225px;
              width: 155px;
              grid-column: 2;
            `
        }
      `;
    } else if (width <= breakpoints.laptopM) {
      return `
        border: 4px solid ${theme.iconColorSticker};

        ${
          isLandscape
            ? `
              margin: -1.5rem 2rem 0 auto;
              height: 175px;
              width: 150px;

            `
            : `
              margin: -1.5rem 2rem 0 auto;
              height: 225px;
              width: 155px;
              grid-column: 2;
            `
        }
      `;
    } else if (width <= breakpoints.laptopL) {
      return `
        border: 4px solid ${theme.iconColorSticker};

        ${
          isLandscape
            ? `
              margin: -2.5rem 3.5rem 0 auto;
              height: 225px;
              width: 175px;

            `
            : `
              margin: -1.5rem 2rem 0 auto;
              height: 225px;
              width: 155px;
              grid-column: 2;
            `
        }
      `;
    } else if (width <= breakpoints.desktop) {
      return `
        border: 4px solid ${theme.iconColorSticker};

        ${
          isLandscape
            ? `
              margin: -2.5rem 3.5rem 0 auto;
              height: 225px;
              width: 175px;

            `
            : `
              margin: -1.5rem 2rem 0 auto;
              height: 225px;
              width: 155px;
              grid-column: 2;
            `
        }
      `;
    } else
      return `
      height: 95%;
      margin-right: 12.5rem;
      margin-top: -2.5rem;
    `;
  }}
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
  z-index: 100;

  ${({ $viewport }) => {
    const { width, height, isLandscape } = $viewport;
    if (width <= breakpoints.mobileS) {
      return `
        justify-content: center;
        padding-right: 0;
        grid-row: 3;
      `;
    } else if (width <= breakpoints.mobileL) {
      return `
        justify-content: center;
        padding-right: 0;
        grid-row: 3;
      `;
    } else if (width <= breakpoints.tabletS) {
      return `
        grid-row: 3;

        ${
          isLandscape
            ? `
          justify-content: center;
        `
            : `
          justify-content: center;
          padding-right: 0;
        `
        }
      `;
    } else if (width <= breakpoints.tabletM) {
      return `
        grid-row: 3;

        ${
          isLandscape
            ? `
          justify-content: end;
        `
            : `
          justify-content: center;
          padding-right: 0;
        `
        }
      `;
    } else if (width <= breakpoints.tabletL) {
      return `
        justify-content: flex-end;
        padding-right: 0;
        margin-bottom: 1.75rem;
      `;
    } else if (width <= breakpoints.laptopS) {
      return `
        justify-content: flex-end;
        padding-right: 0;
        margin-bottom: 1.75rem;
      `;
    } else if (width <= breakpoints.laptopL) {
      return `
        justify-content: flex-end;
        padding-right: 0;
        margin-bottom: 1.75rem;
      `;
    } else if (width <= breakpoints.desktop) {
      return `
        justify-content: flex-end;
        padding-right: 0;
        margin-bottom: 1.75rem;
      `;
    } else if (width <= breakpoints.desktopL) {
      return `
        justify-content: flex-end;
        padding-right: 0;
        margin-bottom: 2.75rem;
      `;
    }
  }}
`;

const Button = styled.button`
  padding: 0.5rem 1rem;
  max-height: 3rem;
  background-color: ${({ theme }) => theme.buttonColor};
  color: ${({ theme }) => theme.buttonTextColor};
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-family: ${typography.fontBody};
  font-size: ${typography.fontSizeMedium};
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({ theme }) => theme.buttonHoverColor};
  }

  ${({ $viewport }) => {
    const { width, height, isLandscape } = $viewport;

    if (width <= breakpoints.mobileS) {
      return `
        font-size: ${typography.fontSizeXs};
        padding: 0.5rem 1.25rem;
      `;
    } else if (width <= breakpoints.mobileL) {
      return `
        font-size: ${typography.fontSizeSmall};
        padding: 0.45rem 0.9rem;
      `;
    } else if (width <= breakpoints.tabletS) {
      return `
        ${
          isLandscape
            ? `
          font-size: ${typography.fontSizeSmall};
          padding: 0.25rem 1rem;
          max-height: 2.5rem
        `
            : `
          font-size: ${typography.fontSizeMedium};
          padding: 0.5rem 1rem;
        `
        }
      `;
    } else if (width <= breakpoints.tabletM) {
      return `
        ${
          isLandscape
            ? `
          font-size: ${typography.fontSizeSmall};
          padding: 0.25rem 1rem;
          max-height: 2.5rem
        `
            : `
          font-size: ${typography.fontSizeMedium};
          padding: 0.5rem 1rem;
        `
        }
      `;
    } else if (width <= breakpoints.tabletL) {
      return `
      ${
        isLandscape
          ? `
          font-size: ${typography.fontSizeSmall};
          padding: 0.25rem 1rem;
          max-height: 2.5rem
      `
          : `
        font-size: ${typography.fontSizeMedium};
        padding: 0.5rem 1rem;
      `
      }
      `;
    } else if (width <= breakpoints.laptopS) {
      return `
      ${
        isLandscape
          ? `
          font-size: ${typography.fontSizeSmall};
          padding: 0.25rem 1rem;
          max-height: 2.5rem
      `
          : `
        font-size: ${typography.fontSizeMedium};
        padding: 0.75rem 1.5rem;
      `
      }
      `;
    }
  }}
`;

function AvatarDialogue({ onClose }) {
  const { theme } = useTheme();
  const viewport = useViewport();
  const wrapperRef = useRef(null);
  const nameRef = useRef(null);
  const dialogueRef = useRef(null);
  const avatarRef = useRef(null);
  const [currentDialogue, setCurrentDialogue] = useState(0);
  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const wrapper = wrapperRef.current;
    const name = nameRef.current;
    const dialogue = dialogueRef.current;
    const avatar = avatarRef.current;

    const tl = gsap.timeline();

    tl.fromTo(
      wrapper,
      { opacity: 0, y: 300 },
      { opacity: 1, y: 0, duration: 1, ease: "power3.out" }
    )
      .fromTo(
        name,
        { opacity: 0, x: -50 },
        { opacity: 1, x: 0, duration: 0.8, ease: "back" },
        "-=0.5"
      )
      .fromTo(
        avatar,
        { opacity: 0, x: 100 },
        { opacity: 1, x: 0, duration: 0.8, ease: "back" },
        "-=0.5"
      )
      .fromTo(
        dialogue,
        { opacity: 0, y: 50 },
        { opacity: 1, y: 0, duration: 0.8, ease: "back" },
        "-=0.5"
      );

    animateDialogue();
  }, []);

  const animateDialogue = () => {
    const dialogue = dialogueRef.current;
    const lines = dialogue.children;
    const currentLines = dialogues[currentDialogue];

    gsap.set(lines, { text: "" });

    const tl = gsap.timeline();

    currentLines.forEach((line, index) => {
      tl.to(lines[index], {
        duration: 1,
        text: line,
        ease: "none",
      });
    });
  };

  useEffect(() => {
    animateDialogue();
  }, [currentDialogue]);

  const handleAdvance = () => {
    if (currentDialogue < dialogues.length - 1) {
      setCurrentDialogue(currentDialogue + 1);
    } else {
      onClose();
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Wrapper ref={wrapperRef} $viewport={viewport} className="Wrapper">
        <InfoWrapper $viewport={viewport} className="Info">
          <Name ref={nameRef} $viewport={viewport}>
            David Bickley
          </Name>
          <DialogueWrapper $viewport={viewport}>
            <Dialogue ref={dialogueRef} $viewport={viewport}>
              {dialogues[currentDialogue].map((line, index) => (
                <DialogueLine key={index} $viewport={viewport}>
                  {line}
                </DialogueLine>
              ))}
            </Dialogue>
          </DialogueWrapper>
          <ButtonWrapper $viewport={viewport}>
            <Button onClick={handleAdvance} $viewport={viewport}>
              {currentDialogue < dialogues.length - 1 ? "Next" : "Close"}
            </Button>
          </ButtonWrapper>
        </InfoWrapper>
        <AvatarWrapper
          ref={avatarRef}
          $viewport={viewport}
          className="AvatarWrapper"
        >
          <Avatar $viewport={viewport} />
        </AvatarWrapper>
      </Wrapper>
    </ThemeProvider>
  );
}

export default AvatarDialogue;
