// components/Card.js
import React from "react";
import SVGIcon from "../SVGIcon"; // Adjust the import path as necessary

// Assume 'logo', 'additionalClasses' for the Card, and 'iconClasses' for the SVGIcon, alongside any other props
const Card = React.memo(
  ({
    item,
    renderImage,
    renderContent,
    renderButton,
    logo,
    additionalClasses = [],
    iconClasses,
  }) => {
    // Determine the root class name based on the presence of an icon, image, or logo
    let rootClassName =
      item.icon || item.image || logo ? "card card--with-media" : "card";

    // Append additional classes to the card if provided
    if (additionalClasses) {
      if (Array.isArray(additionalClasses)) {
        rootClassName += ` ${additionalClasses.join(" ")}`;
      } else {
        rootClassName += ` ${additionalClasses}`;
      }
    }

    // Ensure iconClasses is an array for SVGIcon
    const svgIconClassesArray = Array.isArray(iconClasses)
      ? iconClasses
      : [iconClasses].filter(Boolean);

    return (
      <div className={rootClassName}>
        {logo && (
          <div>
            <SVGIcon
              iconId={logo.logo_id}
              spriteSheetName={logo.sprite_sheet.filename}
              additionalClasses={svgIconClassesArray} // Ensure this is an array
              ariaLabel={logo.name} // Optional, for accessibility
            />
          </div>
        )}
        {renderImage && <div>{renderImage(item)}</div>}
        <div>{renderContent && renderContent(item)}</div>
        {renderButton && renderButton(item)}
      </div>
    );
  }
);

export default Card;
