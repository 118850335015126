// components/SlideOut/SlideOutLogoContainer.jsx

import React, { useEffect } from "react";
import styled from "styled-components";
import { breakpoints, heightBreakpoints } from "../../styles/breakpoints";
import { typography } from "../../styles/abstracts/typography";
import { useTheme } from "../../context/ThemeContext";
import SlideOutLogo from "./SlideOutLogo";
import { useLocation } from "react-router-dom";

let itemHeight = "";
let itemWidth = "";

const StyledLogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0;
  grid-column: 1;
  grid-row: 2;

  ${({ $viewport }) => {
    const { width, height, isLandscape } = $viewport;
    if (width <= breakpoints.mobileS) {
      return `
        padding: 0.5rem 0;
      `;
    }
    if (width <= breakpoints.mobileM) {
      return `
        padding: 0.5rem 0;
      `;
    }
    if (width <= breakpoints.mobileL) {
      return `
        padding: "0.5rem 0";
      `;
    }
    if (width <= breakpoints.tabletS) {
      return `
        padding: 0.5rem 0.25rem;
      `;
    }
    if (width <= breakpoints.tabletM) {
      return `
        padding: ${isLandscape ? "0.5rem" : "0.5rem"} 0.75rem;
        padding: 0.5rem 0.25rem;
      `;
    }
    if (width <= breakpoints.tabletL) {
      return `
        padding: 0.5rem 0.25rem;
      `;
    }
    if (width <= breakpoints.laptopS) {
      return `
      `;
    }
    if (width <= breakpoints.laptopL) {
      return `

      `;
    }
    if (width <= breakpoints.desktop) {
      return `

      `;
    }
    if (width <= breakpoints.desktopL) {
      return `

      `;
    }
  }}
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme, $isActive, $isHovered }) =>
    $isActive || $isHovered ? theme.accentColorPrimary : theme.primaryColor};
  border-radius: 5px;
  height: 40px;
  width: 40px;
  padding: 0.5rem;
  margin: 0.625rem auto;
  transition: background-color 0.3s ease;
  cursor: pointer;

  ${({ $viewport }) => {
    const { width, height, isLandscape } = $viewport;

    if (width <= breakpoints.mobileS) {
      return `
        height: 2rem;
        width: 2rem;
        padding: 0.25rem 0.125rem;
        margin: 0.5rem auto;
        ${
          isLandscape
            ? `
              height: 1.5rem;
              width: 1.5rem;
              padding: 0.125rem;
              margin: 0.25rem auto;
            `
            : ""
        }
      `;
    }
    if (width <= breakpoints.mobileM) {
      return `
        height: 2rem;
        width: 2rem;
        padding: 0.25rem 0rem;
        margin: 0.5rem auto;
        ${
          isLandscape
            ? `
              height: 0.5rem;
              width: 0.5rem;
              padding: 0.125rem;
              margin: 0.125rem auto;
            `
            : ""
        }
      `;
    }
    if (width <= breakpoints.mobileL) {
      return `
        height: 2rem;
        width: 2rem;
        padding: 0.25rem 0.125rem;
        margin: 0.5rem auto;
        ${
          isLandscape
            ? `
              height: 1.5rem;
              width: 1.5rem;
              padding: 0.125rem;
              margin: 0.25rem auto;
            `
            : ""
        }
      `;
    }
    if (width <= breakpoints.tabletS) {
      return `
        height: 2.5rem;
        width: 2.5rem;
        padding: 0.25rem 0.125rem;
        margin: 0.5rem auto;
        ${
          isLandscape
            ? `
              height: 2.125rem;
              width: 2rem;
              padding: 0.25rem;
              margin: 0.5rem auto;
            `
            : ""
        }
      `;
    }
    if (width <= breakpoints.tabletM) {
      return `
        height: 2.5rem;
        width: 2.5rem;
        padding: 0.25rem 0.125rem;
        margin: 0.5rem auto;
        ${
          isLandscape
            ? `
              max-height: 2rem;
              width: 2rem;
              padding: 0.25rem;
              margin: 0.225rem auto;
            `
            : ""
        }
      `;
    }
    if (width <= breakpoints.tabletL) {
      if (height <= heightBreakpoints.heightMIN) {
        itemHeight = "1.25rem";
        itemWidth = "1.25rem";
      } else {
        itemHeight = "2rem";
        itemWidth = "2rem";
      }

      return `
        height: 2.5rem;
        width: 2.5rem;
        padding: 0.25rem 0.125rem;
        margin: 0.5rem auto;
        ${
          isLandscape
            ? `
              height: ${itemHeight};
              width: ${itemWidth};
              padding: 0.25rem;
              margin: 0.25rem auto;
            `
            : ""
        }
      `;
    }
    return "";
  }}
`;

const Fallback = styled.div`
  font-size: ${typography.fontSizeXxxs};
`;

const SlideOutLogoContainer = ({
  items,
  selectedItem,
  hoveredItem,
  onItemClick,
  onItemHover,
  onItemLeave,
  viewport,
}) => {
  const { theme } = useTheme();

  return (
    <StyledLogoContainer $viewport={viewport}>
      {items?.map((item) => {
        const isActive = selectedItem === item.id;
        const isHovered = hoveredItem === item.id;

        return (
          <LogoWrapper
            key={item.id}
            $isActive={isActive}
            $isHovered={isHovered}
            theme={theme}
            $viewport={viewport}
            onClick={() => onItemClick(item.id)}
            onMouseEnter={() => onItemHover(item.id)}
            onMouseLeave={onItemLeave}
          >
            {item.logo &&
            item.logo.logo_id &&
            item.logo.sprite_sheet &&
            item.logo.sprite_sheet.filename ? (
              <SlideOutLogo
                logoId={item.logo.logo_id}
                spriteSheetName={item.logo.sprite_sheet.filename}
                isActive={isActive}
                isHovered={isHovered}
                theme={theme}
              />
            ) : (
              <Fallback>No Logo</Fallback>
            )}
          </LogoWrapper>
        );
      })}
    </StyledLogoContainer>
  );
};

export default SlideOutLogoContainer;
