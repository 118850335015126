import React from "react";
import BackstorySection from "./BackstorySection";
import GapSection from "./GapSection";
import GoalSection from "./GoalSection";
import GambleSection from "./GambleSection";
import ApproachSection from "./ApproachSection";
import PhaseSection from "./PhaseSection";
import TestimonialSection from "./TestimonialSection";
import Skeleton from "react-loading-skeleton";

function ContentSection({ caseStudy, loading }) {
  const testimonials = caseStudy?.testimonials;

  // Sort the testimonials array based on the order field
  const sortedTestimonials =
    testimonials && [...testimonials].sort((a, b) => a.order - b.order);

  return (
    <div className="case-study__content-wrapper">
      {loading || !sortedTestimonials ? (
        <Skeleton count={3} />
      ) : (
        sortedTestimonials.length > 0 && (
          <TestimonialSection testimonial={sortedTestimonials[0]} />
        )
      )}
      <div className="case-study__content">
        {loading || !caseStudy?.backstory ? (
          <Skeleton count={3} />
        ) : (
          <BackstorySection
            backstory={caseStudy.backstory.content}
            image={caseStudy.backstory.img}
          />
        )}
        {loading || !caseStudy?.goal ? (
          <Skeleton count={3} />
        ) : (
          <GoalSection
            goal={caseStudy.goal.content}
            image={caseStudy.goal.img}
          />
        )}
        {loading || !caseStudy?.gap ? (
          <Skeleton count={3} />
        ) : (
          <GapSection gap={caseStudy.gap.content} image={caseStudy.gap.img} />
        )}
        {loading || !caseStudy?.gamble ? (
          <Skeleton count={3} />
        ) : (
          <GambleSection
            gamble={caseStudy.gamble.content}
            image={caseStudy.gamble.img}
          />
        )}
        {loading || !caseStudy?.approach ? (
          <Skeleton count={3} />
        ) : (
          <ApproachSection approach={caseStudy.approach} />
        )}
        {loading || !caseStudy?.phases ? (
          <Skeleton count={3} />
        ) : (
          caseStudy.phases.length > 0 && (
            <PhaseSection phases={caseStudy.phases} />
          )
        )}
      </div>
      {loading || !sortedTestimonials ? (
        <Skeleton count={3} />
      ) : (
        sortedTestimonials.length > 1 && (
          <TestimonialSection testimonial={sortedTestimonials[1]} />
        )
      )}
    </div>
  );
}

export default ContentSection;
