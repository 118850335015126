// components/Resume/ResumeTabs.jsx

import React from "react";
import styled from "styled-components";
import { useTheme } from "../../context/ThemeContext";
import { useViewport } from "../../context/ViewportContext";
import { breakpoints } from "../../styles/breakpoints";
import { typography } from "../../styles/abstracts/typography";

const StyledResumeTabs = styled.div`
  grid-column: 2/-1;
  grid-row: 3;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1.5rem;
  z-index: 980;
  justify-content: space-between;

  ${({ $viewport }) => {
    const { width, isLandscape } = $viewport;
    if (width <= breakpoints.mobileS) {
      return `
         flex-wrap: wrap;
        justify-content: space-between;
        width: 85%;
        justify-self: flex-end;

        gap: 0.5rem;
        grid-column: 1/-1;

        margin: 0 0.5rem 0.5rem 0.5rem;

        ${
          isLandscape
            ? `
          // Landscape-specific styles for mobileS
          bottom: 0.25rem;
          right: 0.25rem;
        `
            : ""
        }
      `;
    }
    if (width <= breakpoints.mobileM) {
      return `
        flex-wrap: wrap;
        justify-content: space-between;
        width: 85%;
        justify-self: flex-end;

        gap: 0.5rem;
        grid-column: 1/-1;

        margin: 0 0.5rem 0.5rem 0.5rem;

        ${
          isLandscape
            ? `
          // Landscape-specific styles for mobileM
          width: 50%;
          bottom: 0.25rem;
        `
            : ""
        }
      `;
    }
    if (width <= breakpoints.mobileL) {
      return `
        flex-wrap: wrap;
        justify-content: space-between;
        width: 85%;
        justify-self: flex-end;

        gap: 0.5rem;
        grid-column: 1/-1;

        margin: 0 0.5rem 0.5rem 0.5rem;

        ${
          isLandscape
            ? `
          width: 50%;
          bottom: 0.25rem;
        `
            : ""
        }
      `;
    }
    if (width <= breakpoints.tabletS) {
      return `
        flex-wrap: wrap;
        justify-content: space-between;
        width: 85%;
        justify-self: flex-end;

        gap: 0.5rem;
        grid-column: 1/-1;

        margin: 0 0.5rem 0.5rem 0.5rem;

        ${
          isLandscape
            ? `
          width: 90%;
          bottom: 0.25rem;
        `
            : ""
        }
      `;
    }
    if (width <= breakpoints.tabletM) {
      return `
        flex-wrap: wrap;
        justify-content: space-between;
        width: 85%;
        justify-self: flex-end;

        gap: 0.5rem;
        grid-column: 1/-1;

        margin: 0 0.5rem 0.5rem 0.5rem;

        ${
          isLandscape
            ? `
              width: 90%;
              bottom: 0.25rem;
            `
            : ""
        }
      `;
    }
    if (width <= breakpoints.tabletL) {
      return `
        flex-wrap: wrap;
        justify-content: space-between;
        width: 85%;
        justify-self: flex-end;

        gap: 0.5rem;
        grid-column: 1/-1;

        margin: 0 0.5rem 0.5rem 0.5rem;

        ${
          isLandscape
            ? `
              width: 90%;
              bottom: 0.25rem;
            `
            : ""
        }
      `;
    }

    if (width <= breakpoints.laptopS) {
      return `
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        justify-self: flex-end;

        gap: 0.5rem;
        grid-column: 1/-1;

        margin: 0 0.5rem 0.5rem 0.5rem;

        ${
          isLandscape
            ? `
              width: 90%;
              bottom: 0.25rem;
            `
            : ""
        }
      `;
    }

    if (width <= breakpoints.laptopM) {
      return `
        flex-wrap: wrap;
        justify-content: space-between;
        width: 95%;
        justify-self: flex-end;

        gap: 0.5rem;
        grid-column: 1/-1;

        margin: 0 0.5rem 0.5rem 0.5rem;
      `;
    }

    if (width <= breakpoints.laptopL) {
      return `
        flex-wrap: wrap;
        justify-content: space-between;
        width: 95%;
        justify-self: flex-end;

        gap: 0.75rem;
        grid-column: 1/-1;

        margin: 0 0.5rem 0.5rem 0.5rem;
      `;
    }
    if (width <= breakpoints.desktop) {
      return `
        flex-wrap: wrap;
        justify-content: space-between;
        width: 95%;
        justify-self: flex-end;

        gap: 0.75rem;
        grid-column: 1/-1;

        margin: 1.5rem 1.25rem 0.5rem 0.5rem;
      `;
    }
    if (width <= breakpoints.desktopL) {
      return `
        flex-wrap: wrap;
        justify-content: space-between;
        width: 95%;
        justify-self: flex-end;

        gap: 0.5rem;
        grid-column: 1/-1;

        margin: 0 0.5rem 0.5rem 0.5rem;
      `;
    }
  }}
`;

const Tab = styled.div`
  background-color: ${({ theme, selected }) =>
    selected ? theme.accentColorPrimary : theme.secondaryColor};
  border-radius: 5px;
  color: ${({ theme, selected }) =>
    selected ? theme.textColorDark : theme.textColorLight};
  padding: 0.25rem 0.75rem;
  cursor: pointer;
  transition:
    background-color 0.1s ease,
    transform 0.1s ease,
    color 0.1s ease;
  flex-grow: 1;
  text-align: center;
  align-content: center;

  &:hover {
    background-color: ${({ theme, selected }) =>
      selected ? theme.accentColorPrimary : theme.accentColorSecondary};
    transform: ${({ selected }) => (selected ? "none" : "scale(1.02)")};
    color: ${({ theme, selected }) =>
      selected ? theme.textColorDark : theme.accentColorPrimary};
  }

  & p {
    font-size: ${typography.fontSizeXxs};
    white-space: nowrap;
    margin: 0;
  }

  ${({ $viewport }) => {
    const { width, isLandscape } = $viewport;

    if (width <= breakpoints.mobileS) {
      return `
        flex-basis: calc(20% - 0.25rem);
        padding: 0.25rem 0.5rem;
        &:last-child {
          flex-basis: 100%;
        }

        & p {
          font-size: ${typography.fontSizeMin}
        }
      `;
    }

    if (width <= breakpoints.mobileM) {
      return `
        flex-basis: calc(20% - 0.25rem);
        padding: 0.25rem 0.5rem;
        &:last-child {
          flex-basis: 100%;
        }

        & p {
          font-size: ${typography.fontSizeXxxs}
        }
      `;
    }

    if (width <= breakpoints.mobileL) {
      return `
        flex-basis: calc(25% - 0.25rem);
        padding: 0.25rem 0.5rem;
        &:last-child {
          flex-basis: 100%;
        }
        & p {
          font-size: ${typography.fontSizeXxxs};
        }

      `;
    }

    if (width <= breakpoints.tabletS) {
      return `
        flex-basis: calc(20% - 0.25rem);
        padding: 0.25rem 0.5rem;

        & p {
          font-size: ${typography.fontSizeXxxs};
        }
      `;
    }

    if (width <= breakpoints.tabletM) {
      return `
        flex-basis: calc(20% - 0.25rem);
        padding: 0.25rem 0.5rem;

        & p {
          font-size: ${typography.fontSizeXxs}
        }

        ${
          isLandscape
            ? `
              flex-basis: calc(15% - 0.25rem);
              padding: 0.25rem 0.5rem;

              & p {
                font-size: ${typography.fontSizeMin}
              }
            `
            : ""
        }
      `;
    }

    if (width <= breakpoints.tabletL) {
      return `
        padding: 0.25rem 0.5rem;
        flex-basis: calc(15% - 0.25rem);

        & p {
          font-size: ${typography.fontSizeXxxs}
        }

      `;
    }

    if (width <= breakpoints.laptopS) {
      return `
        padding: 0.25rem 0.5rem;
        flex-basis: calc(15% - 0.25rem);

        & p {
          font-size: ${typography.fontSizeXxs}
        }
      `;
    }

    if (width <= breakpoints.laptopM) {
      return `
        padding: 0rem 1rem;
        flex-basis: calc(10% - 0.25rem);
        height: 2.5rem;

        & p {
          font-size: ${typography.fontSizeXxs}
        }
      `;
    }

    if (width <= breakpoints.laptopL) {
      return `
        padding: 0rem 2.25rem;
        flex-basis: calc(10% - 0.25rem);
        height: 2.5rem;

        & p {
          font-size: ${typography.fontSizeXxs}
        }
      `;
    }

    if (width <= breakpoints.desktop) {
      return `
        padding: 0rem 2.25rem;
        flex-basis: calc(15% - 0.25rem);
        height: 2.5rem;
      `;
    }

    if (width <= breakpoints.desktopL) {
      return `
        padding: 0rem 2.25rem;
        flex-basis: calc(15% - 0.25rem);
        height: 3.5rem;
      `;
    }
  }}
`;

function ResumeTabs({ selectedTab, setSelectedTab, isCollapsed }) {
  const { theme } = useTheme();
  const viewport = useViewport();

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  const tabs = ["Highlights", "Skills", "Technologies", "Testimonials"];

  return (
    <StyledResumeTabs
      className="ResumeTabs"
      $viewport={viewport}
      isVisible={!isCollapsed || viewport.width <= breakpoints.tabletL}
    >
      {tabs.map((tab) => (
        <Tab
          key={tab}
          theme={theme}
          selected={selectedTab === tab}
          onClick={() => handleTabClick(tab)}
          $viewport={viewport}
        >
          <p>{tab}</p>
        </Tab>
      ))}
    </StyledResumeTabs>
  );
}

export default ResumeTabs;
