import React from "react";
import Markdown from "react-markdown";
import Skeleton from "react-loading-skeleton";

function ClosingSection({ closingThoughts, loading }) {
  return (
    <div className="case-study__closing">
      <h2>{loading ? <Skeleton width={200} /> : "Closing Thoughts"}</h2>
      {loading ? (
        <Skeleton count={3} />
      ) : (
        <Markdown>{closingThoughts}</Markdown>
      )}
    </div>
  );
}

export default ClosingSection;
