import React from "react";
import Markdown from "react-markdown";
import Skeleton from "react-loading-skeleton";

function GambleSection({ gamble, image, loading }) {
  return (
    <div className="case-study__gamble">
      {loading ? (
        <Skeleton width={500} height={300} />
      ) : (
        <figure className="case-study__gamble-image">
          <img src={image} alt="gamble" />
        </figure>
      )}
      <div className="case-study__gamble-content">
        <h2>{loading ? <Skeleton width={200} /> : "The Gamble:"}</h2>
        {loading ? <Skeleton count={3} /> : <Markdown>{gamble}</Markdown>}
      </div>
    </div>
  );
}

export default GambleSection;
