import * as THREE from "three";
import { createContext, useContext, useRef, useState } from "react";
import { useFrame } from "@react-three/fiber";
import { Cloud } from "@react-three/drei";
import { random } from "maath";

const context = createContext();

export default function ThunderCloud({
  seed,
  color,
  lightColor,
  opacity,
  maxLightIntensity = 10000, // Adjustable max intensity
  vec = new THREE.Vector3(),
  ...props
}) {
  const light = useRef();
  const rig = useContext(context);
  const [flash] = useState(() => {
    const count = Math.floor(Math.random() * 2) + 2;
    const minDuration = Math.floor(Math.random() * 100) + 100;
    const maxDuration = Math.floor(Math.random() * 500) + 500;
    return new random.FlashGen({ count, minDuration, maxDuration });
  });
  const [lightPosition, setLightPosition] = useState(
    new THREE.Vector3(0, 0, 0.5)
  );

  const randomRange = (min, max) => {
    return Math.random() * (max - min) + min;
  };

  useFrame((state, delta) => {
    const impulse = flash.update(state.clock.elapsedTime, delta);
    light.current.intensity = impulse * maxLightIntensity; // Use the adjustable maxLightIntensity
    if (impulse === 1) rig?.current?.setIntensity(1);

    // Generate random flash at random intervals
    if (Math.random() < 0.00025) {
      flash.burst();
      // Update the position of the point light randomly within a certain range
      setLightPosition(
        new THREE.Vector3(
          randomRange(-2, 2),
          randomRange(-2, 2),
          randomRange(0, 1)
        )
      );
    }
    // Continuously update light position for subtle movement
    setLightPosition(
      new THREE.Vector3(
        lightPosition.x + randomRange(-0.01, 0.01),
        lightPosition.y + randomRange(-0.05, 0.05),
        lightPosition.z + randomRange(-0.05, 0.05)
      )
    );
  });

  return (
    <group {...props}>
      <Cloud
        seed={seed}
        fade={30}
        speed={0.05}
        growth={4}
        segments={40}
        volume={6}
        opacity={0.2}
        position={[0, -1, 0]}
        color={color}
      />
      <Cloud
        seed={seed + 1}
        fade={30}
        position={[0, 1, 0]}
        speed={0.05}
        growth={4}
        volume={20}
        opacity={0.4}
        color={color}
      />
      <pointLight position={lightPosition} ref={light} color={lightColor} />
    </group>
  );
}
