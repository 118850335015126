/**
 * TestimonialItem.js
 *
 * Provides a responsive testimonial component for the application.
 * It displays a quote, author information, and avatar in a styled container.
 * The component adapts to various screen sizes and orientations using context/ViewportContext.js
 */

import React, { useEffect, useState, useRef, useCallback } from "react";
import styled from "styled-components";
import { hexToRgb } from "../../utils/hexToRgb";
import { breakpoints } from "../../styles/breakpoints";
import { typography } from "../../styles/abstracts/typography";
import Scrollbar from "../Scrollbar/Scrollbar";

const StyledTestimonial = styled.div`
  position: relative;
  flex: 1 1 300px;
  z-index: 950;
  pointer-events: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 600px;
  height: 100%;
  overflow: hidden;

  ${({ $viewport }) => {
    const { width, isLandscape } = $viewport;

    if (width <= breakpoints.mobileS) {
      return `
  
        `;
    }
    if (width <= breakpoints.mobileM) {
      return `
        `;
    }
    if (width <= breakpoints.mobileL) {
      return `
        `;
    }
    if (width <= breakpoints.tabletS) {
      return `
        `;
    }
    if (width <= breakpoints.tabletM) {
      return `
        `;
    }
    if (width <= breakpoints.tabletL) {
      return `
        `;
    }
    if (width <= breakpoints.laptopS) {
      return `
        `;
    }
    if (width <= breakpoints.laptopM) {
      return `
        `;
    }
    if (width <= breakpoints.laptopL) {
      return `
        `;
    }
    if (width <= breakpoints.desktop) {
      return `
        `;
    }
    if (width <= breakpoints.desktopL) {
      return `
        `;
    }
    return `
  
      `;
  }}
`;

const TestimonialQuote = styled.div`
  background-color: ${({ theme }) => theme.bodyBgPrimary};
  border-radius: 15px;
  margin-bottom: -1.75rem;
  overflow-y: auto;
  position: relative;
  scrollbar-width: thin;
  scrollbar-color: ${({ theme }) =>
    `${theme.primaryColor} ${theme.bodyBgSecondary}`};

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 30px;
    background: ${({ theme }) =>
      `linear-gradient(to top, ${theme.navBgSecondary}, transparent)`};
    pointer-events: none;
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.bodyBgSecondary};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.primaryColor};
    border-radius: 4px;
    border: 3px solid ${({ theme }) => theme.bodyBgSecondary};
  }

  & p {
    margin: 0;
    padding: 0;
  }

  ${({ $viewport }) => {
    const { width, isLandscape } = $viewport;

    if (width <= breakpoints.mobileS) {
      return `
          margin-bottom: -1rem;
          padding: 1rem 0.75rem;
          border-radius: 10px;
  
         & p{
          font-size: ${typography.fontSizeXxxs};
          line-height: ${typography.fontHeightXxxs};
         }
        `;
    }
    if (width <= breakpoints.mobileM) {
      return `
          margin: 1rem 0 -1rem 0;
          padding: 0.25rem 0;
          border-radius: 10px;
          max-height: 75vh;
  
         & p{
          font-size: ${typography.fontSizeXxs};
          line-height: ${typography.fontHeightXxs};
         }
        `;
    }
    if (width <= breakpoints.mobileL) {
      return `
          margin: 1rem 0 -1rem 0;
          padding: 0.25rem 0 0.75rem 0;
          border-radius: 10px;
          max-height: min(450px, 50vh);
  
         & p{
          font-size: ${typography.fontSizeXs};
          line-height: ${typography.fontHeightXs};
         }
        `;
    }
    if (width <= breakpoints.tabletS) {
      return `
          margin: 1rem 0 -1rem 0;
          padding: 0.25rem 0 0.75rem 0;
          border-radius: 10px;
          max-height: min(450px, 60vh);
  
         & p{
          font-size: ${typography.fontSizeXs};
          line-height: ${typography.fontHeightXs};
         }
        `;
    }
    if (width <= breakpoints.tabletM) {
      return `
          margin: 1rem 0 -1rem 0;
          border-radius: 10px;
  
          ${
            isLandscape
              ? `
            margin: 1rem 0 -0.75rem 0;
            padding: 0.5rem 0 0.5rem 0;
            max-height: min(450px, 40vh);
              & p{
                font-size: ${typography.fontSizeXxs};
                line-height: ${typography.fontHeightXs};
              }
            `
              : `
              padding: 0.5rem 0 0.75rem 0;
              max-height: min(450px, 60vh);        
  
              & p{
                font-size: ${typography.fontSizeSmall};
                line-height: ${typography.fontHeightSm};
              }
            `
          }
        `;
    }
    if (width <= breakpoints.tabletL) {
      return `
          margin: 1rem 0 -1rem 0;
          border-radius: 10px;
  
          ${
            isLandscape
              ? `
              padding: 0.5rem 0 0.5rem 0;
              max-height: min(450px, 40vh);
              & p{
                font-size: ${typography.fontSizeXxs};
                line-height: ${typography.fontHeightXs};
              }
            `
              : `
              padding: 0.5rem 0 0.75rem 0;
              max-height: min(450px, 60vh);        
  
              & p{
                font-size: ${typography.fontSizeSmall};
                line-height: ${typography.fontHeightSm};
              }
            `
          }
        `;
    }
    if (width <= breakpoints.laptopS) {
      return `
          margin: 1rem 0 -1rem 0;
          border-radius: 10px;
  
          ${
            isLandscape
              ? `
            padding: 0.5rem 0 0.5rem 0;
            max-height: min(450px, 40vh);
            & p{
              font-size: ${typography.fontSizeXxs};
              line-height: ${typography.fontHeightXs};
            }
          `
              : `
            padding: 0.5rem 0 0.75rem 0;
            max-height: min(450px, 60vh);        
  
            & p{
              font-size: ${typography.fontSizeSmall};
              line-height: ${typography.fontHeightSm};
            }
          `
          }
  
        `;
    }
    if (width <= breakpoints.laptopM) {
      return `
          margin: 1rem 0 -1rem 0;
          border-radius: 10px;
  
          ${
            isLandscape
              ? `
            padding: 0.5rem 0 0.5rem 0;
            max-height: min(450px, 40vh);
            & p{
              font-size: ${typography.fontSizeXxs};
              line-height: ${typography.fontHeightXs};
            }
          `
              : `
            padding: 0.5rem 0 0.75rem 0;
            max-height: min(450px, 60vh);        
  
            & p{
              font-size: ${typography.fontSizeSmall};
              line-height: ${typography.fontHeightSm};
            }
          `
          }
  
        `;
    }
    if (width <= breakpoints.laptopL) {
      return `
          margin: 1rem 0 -1rem 0;
          border-radius: 10px;
  
          ${
            isLandscape
              ? `
            padding: 0.5rem 0 0.5rem 0;
            max-height: min(450px, 40vh);
            & p{
              font-size: ${typography.fontSizeXxs};
              line-height: ${typography.fontHeightXs};
            }
          `
              : `
            padding: 0.5rem 0 0.75rem 0;
            max-height: min(450px, 60vh);        
  
            & p{
              font-size: ${typography.fontSizeSmall};
              line-height: ${typography.fontHeightSm};
            }
          `
          }
  
        `;
    }
    if (width <= breakpoints.desktop) {
      return `
          margin: 1rem 0 -1rem 0;
          border-radius: 10px;
  
          ${
            isLandscape
              ? `
            padding: 0.5rem 0 0.5rem 0;
            max-height: min(450px, 40vh);
            & p{
              font-size: ${typography.fontSizeSmall};
              line-height: ${typography.fontHeightSm};
            }
          `
              : `
            padding: 0.5rem 0 0.75rem 0;
            max-height: min(450px, 60vh);        
  
            & p{
              font-size: ${typography.fontSizeSmall};
              line-height: ${typography.fontHeightSm};
            }
          `
          }
  
        `;
    }
    if (width <= breakpoints.desktopL) {
      return `
          margin: 1rem 0 -1rem 0;
          border-radius: 10px;
  
          ${
            isLandscape
              ? `
                padding: 0.5rem 0 0.5rem 0;
                max-height: min(450px, 40vh);
                & p{
                  font-size: ${typography.fontSizeXxs};
                  line-height: ${typography.fontHeightXs};
                }
              `
              : `
            padding: 0.5rem 0 0.75rem 0;
            max-height: min(450px, 60vh);        
  
            & p{
              font-size: ${typography.fontSizeSmall};
              line-height: ${typography.fontHeightSm};
            }
          `
          }
  
        `;
    }
    return `
  
      `;
  }}
`;

const QuoteContent = styled.div`
  padding: 0.5rem 1rem 0.75rem 1rem;
  background-color: ${(props) =>
    props.debug ? "rgba(0, 255, 0, 0.1)" : "transparent"};

  ${({ $viewport }) => {
    const { width, isLandscape } = $viewport;

    if (width <= breakpoints.tabletL) {
      return `
        ${
          isLandscape
            ? `
            padding: 0.25rem 1.25rem 0.75rem 1rem;
          `
            : `
            padding: 0.5rem 1.125rem;
          `
        }`;
    }
  }}
`;

const TestimonialAuthorContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  gap: 1.25rem;
  position: relative;

  ${({ $viewport }) => {
    const { width, isLandscape } = $viewport;

    if (width <= breakpoints.mobileS) {
      return `
          gap: 0.5rem;
          padding-right: 0.25rem
        `;
    }
    if (width <= breakpoints.mobileM) {
      return `
          gap: 0.5rem;
          padding-right: 0.5rem;
        `;
    }
    if (width <= breakpoints.mobileL) {
      return `
          gap: 0.5rem;
          padding-right: 0.5rem;
        `;
    }
    if (width <= breakpoints.tabletS) {
      return `
          gap: 0.5rem;
          padding-right: 0.5rem;
        `;
    }
    if (width <= breakpoints.tabletM) {
      return `
          gap: 0.5rem;
          padding-right: 0.5rem;
        `;
    }
    if (width <= breakpoints.tabletL) {
      return `
          gap: 0.5rem;
          padding-right: 0.5rem;
        `;
    }
    if (width <= breakpoints.laptopS) {
      return `
          gap: 0.5rem;
          padding-right: 0.5rem;
        `;
    }
    if (width <= breakpoints.laptopM) {
      return `
          gap: 0.5rem;
          padding-right: 0.5rem;
        `;
    }
    if (width <= breakpoints.laptopL) {
      return `
          gap: 0.5rem;
          padding-right: 0.5rem;
        `;
    }
    if (width <= breakpoints.desktop) {
      return `
          gap: 0.5rem;
          padding-right: 0.5rem;
        `;
    }
    if (width <= breakpoints.desktopL) {
      return `
          gap: 0.5rem;
          padding-right: 0.5rem;
        `;
    }
    return `
        padding-right: 2.5rem;
  
      `;
  }}
`;

const TestimonialAuthor = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  gap: 0;
  padding-bottom: 0.5rem;

  & p {
    margin: 0;
    text-align: right;
  }
  & h3 {
    margin: 0;
    text-align: right;
    font-size: 1.8rem;
    padding: 0;
    color: ${({ theme }) => theme.primaryColor};
    line-height: 1.2rem;
  }

  ${({ $viewport }) => {
    const { width, isLandscape } = $viewport;

    if (width <= breakpoints.mobileS) {
      return `
          padding-bottom: 0;
        & h3 {
          font-size: ${typography.fontSizeSmall};
          line-height: ${typography.fontHeightXxxs};
        }
        
         & p {
          font-size: ${typography.fontSizeMin};
          line-height: ${typography.fontHeightXxxs};
         }
        `;
    }
    if (width <= breakpoints.mobileM) {
      return `
          padding-bottom: 0;
  
          & h3 {
            font-size: ${typography.fontSizeSmall};
            line-height: ${typography.fontHeightXxxs};
          }
  
          & p {
            font-size: ${typography.fontSizeMin};
            line-height: ${typography.fontHeightXxxs};
          }
        `;
    }
    if (width <= breakpoints.mobileL) {
      return `
          padding-bottom: 0;
  
          & h3 {
            font-size: ${typography.fontSizeSmall};
            line-height: ${typography.fontHeightXxxs};
          }
  
          & p {
            font-size: ${typography.fontSizeMin};
            line-height: ${typography.fontHeightXxxs};
          }
        `;
    }
    if (width <= breakpoints.tabletS) {
      return `
          padding-bottom: 0;
  
          & h3 {
            font-size: ${typography.fontSizeMedium};
            line-height: ${typography.fontHeightXxs};
          }
  
          & p {
            font-size: ${typography.fontSizeXxxs};
            line-height: ${typography.fontHeightXxs};
          }
        `;
    }
    if (width <= breakpoints.tabletM) {
      return `
          padding-bottom: 0;
          margin-top: 1.125rem;
  
          & h3 {
            font-size: ${typography.fontSizeSmall};
            line-height: ${typography.fontHeightXxxs};
          }
  
          & p {
            font-size: ${typography.fontSizeMin};
            line-height: ${typography.fontHeightXxxs};
          }
        `;
    }
    if (width <= breakpoints.tabletL) {
      return `
          padding-bottom: 0;
  
          & h3 {
            font-size: ${typography.fontSizeMedium};
            line-height: ${typography.fontHeightXxs};
          }
  
          & p {
            font-size: ${typography.fontSizeXxxs};
            line-height: ${typography.fontHeightXxs};
          }
        `;
    }
    if (width <= breakpoints.laptopS) {
      return `
          padding-bottom: 0;
  
          & h3 {
            font-size: ${typography.fontSizeMedium};
            line-height: ${typography.fontHeightXxs};
          }
  
          & p {
            font-size: ${typography.fontSizeXxxs};
            line-height: ${typography.fontHeightXxs};
          }
        `;
    }
    if (width <= breakpoints.laptopM) {
      return `
          padding-bottom: 0;
  
          & h3 {
            font-size: ${typography.fontSizeMedium};
            line-height: ${typography.fontHeightXxs};
          }
  
          & p {
            font-size: ${typography.fontSizeXxxs};
            line-height: ${typography.fontHeightXxs};
          }
        `;
    }
    if (width <= breakpoints.laptopL) {
      return `
          padding-bottom: 0;
  
          & h3 {
            font-size: ${typography.fontSizeMedium};
            line-height: ${typography.fontHeightXxs};
          }
  
          & p {
            font-size: ${typography.fontSizeXxxs};
            line-height: ${typography.fontHeightXxs};
          }
        `;
    }
    if (width <= breakpoints.desktop) {
      return `
          padding-bottom: 0;
  
          & h3 {
            font-size: ${typography.fontSizeMedium};
            line-height: ${typography.fontHeightXxs};
          }
  
          & p {
            font-size: ${typography.fontSizeXxxs};
            line-height: ${typography.fontHeightXxs};
          }
        `;
    }
    if (width <= breakpoints.desktopL) {
      return `
          padding-bottom: 0;
  
          & h3 {
            font-size: ${typography.fontSizeMedium};
            line-height: ${typography.fontHeightXxs};
          }
  
          & p {
            font-size: ${typography.fontSizeXxxs};
            line-height: ${typography.fontHeightXxs};
          }
        `;
    }
    return `
  
      `;
  }}
`;

const AuthorAvatar = styled.div`
  width: 100px;
  height: 100px;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 25px;
    border: 4px solid
      rgba(${({ theme }) => hexToRgb(theme.accentColorPrimary)}, 1);
  }

  ${({ $viewport }) => {
    const { width, isLandscape } = $viewport;

    if (width <= breakpoints.mobileS) {
      return `
         max-width: 50px;
         max-height: 50px;
  
         & img {
          border-radius: 10px;
         }
        `;
    }
    if (width <= breakpoints.mobileM) {
      return `
         max-width: 50px;
         max-height: 50px;
  
         & img {
          border-radius: 10px;
         }
        `;
    }
    if (width <= breakpoints.mobileL) {
      return `
          max-width: 50px;
          max-height: 50px;
  
          & img {
            border-radius: 10px;
          }
        `;
    }
    if (width <= breakpoints.tabletS) {
      return `
          max-width: 55px;
          max-height: 55px;
  
          & img {
            border-radius: 10px;
          }
        `;
    }
    if (width <= breakpoints.tabletM) {
      return `
          & img {
            border-radius: 10px;
          }
  
          ${
            isLandscape
              ? `
            max-width: 40px;
            max-height: 40px;                
          `
              : `
            max-width: 55px;
            max-height: 55px;        
          `
          }
  
        `;
    }
    if (width <= breakpoints.tabletL) {
      return `
        ${
          isLandscape
            ? `
              max-width: 55px;
              max-height: 55px;      
            `
            : `
              max-width: 65px;
              max-height: 65px;
            `
        }
  
          & img {
            border-radius: 10px;
          }
        `;
    }
    if (width <= breakpoints.laptopS) {
      return `
        ${
          isLandscape
            ? `
              max-width: 55px;
              max-height: 55px;      
            `
            : `
              max-width: 65px;
              max-height: 65px;
            `
        }
  
          & img {
            border-radius: 10px;
          }
        `;
    }
    if (width <= breakpoints.laptopM) {
      return `
        ${
          isLandscape
            ? `
              max-width: 55px;
              max-height: 55px;      
            `
            : `
              max-width: 65px;
              max-height: 65px;
            `
        }
  
          & img {
            border-radius: 10px;
          }
        `;
    }
    if (width <= breakpoints.laptopL) {
      return `
        ${
          isLandscape
            ? `
              max-width: 55px;
              max-height: 55px;      
            `
            : `
              max-width: 65px;
              max-height: 65px;
            `
        }
  
          & img {
            border-radius: 10px;
          }
        `;
    }
    if (width <= breakpoints.desktop) {
      return `
        ${
          isLandscape
            ? `
              max-width: 55px;
              max-height: 55px;      
            `
            : `
              max-width: 65px;
              max-height: 65px;
            `
        }
  
          & img {
            border-radius: 10px;
          }
        `;
    }
    if (width <= breakpoints.desktopL) {
      return `
        ${
          isLandscape
            ? `
              max-width: 55px;
              max-height: 55px;      
            `
            : `
              max-width: 65px;
              max-height: 65px;
            `
        }
  
          & img {
            border-radius: 10px;
          }
        `;
    }
    return `
  
      `;
  }}
`;

const TestimonialItem = React.memo(
  ({ testimonial, theme, viewport, index, debug = true }) => {
    const quoteRef = useRef(null);
    const contentRef = useRef(null);
    const [dimensions, setDimensions] = useState({});
    const [shouldShowScrollbar, setShouldShowScrollbar] = useState(false);

    const updateDimensions = useCallback(() => {
      if (quoteRef.current && contentRef.current) {
        const quoteRect = quoteRef.current.getBoundingClientRect();
        const contentRect = contentRef.current.getBoundingClientRect();

        const newDimensions = {
          quoteHeight: quoteRect.height,
          quoteClientHeight: quoteRef.current.clientHeight,
          quoteScrollHeight: quoteRef.current.scrollHeight,
          contentHeight: contentRect.height,
          isScrollable:
            quoteRef.current.scrollHeight > quoteRef.current.clientHeight,
        };

        setDimensions(newDimensions);

        console.log("TestimonialItem Dimensions:", {
          ...newDimensions,
          testimonialId: testimonial.id,
          viewportWidth: viewport.width,
          viewportHeight: viewport.height,
        });
      }
    }, [testimonial.id, viewport]);

    useEffect(() => {
      const handleResize = () => {
        setTimeout(updateDimensions, 100);
      };

      updateDimensions();
      window.addEventListener("resize", handleResize);

      const resizeObserver = new ResizeObserver(handleResize);
      if (quoteRef.current) resizeObserver.observe(quoteRef.current);
      if (contentRef.current) resizeObserver.observe(contentRef.current);

      return () => {
        window.removeEventListener("resize", handleResize);
        resizeObserver.disconnect();
      };
    }, [updateDimensions, viewport, testimonial]);

    return (
      <StyledTestimonial $viewport={viewport}>
        <TestimonialQuote
          ref={quoteRef}
          theme={theme}
          $viewport={viewport}
          className="TestimonialQuote"
          debug={debug}
        >
          <Scrollbar
            theme={theme}
            key={`scrollbar-${testimonial.id}-${viewport.width}-${viewport.height}`}
            forceScrollbar={shouldShowScrollbar}
          >
            <QuoteContent $viewport={viewport} ref={contentRef}>
              <p>"{testimonial.quote}"</p>
            </QuoteContent>
          </Scrollbar>
        </TestimonialQuote>
        <TestimonialAuthorContainer $viewport={viewport}>
          <AuthorAvatar theme={theme} $viewport={viewport}>
            {testimonial.avatar && (
              <img
                src={testimonial.avatar}
                alt={`${testimonial.author_name}'s avatar`}
              />
            )}
          </AuthorAvatar>
          <TestimonialAuthor theme={theme} $viewport={viewport}>
            <h3>{testimonial.author_name}</h3>
            <p>
              {testimonial.role}, {testimonial.company}
            </p>
          </TestimonialAuthor>
        </TestimonialAuthorContainer>
      </StyledTestimonial>
    );
  }
);

export default TestimonialItem;
