import { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { breakpoints } from "../styles/breakpoints";

const useResponsiveGSAP = (animationConfigs, dependencies) => {
  const matchMediaRef = useRef(null);

  useEffect(() => {
    matchMediaRef.current = gsap.matchMedia();

    // Sort breakpoints in descending order
    const sortedBreakpoints = Object.entries(breakpoints).sort(
      ([, a], [, b]) => b - a
    );

    sortedBreakpoints.forEach(([breakpointName, breakpointValue], index) => {
      const minWidth =
        index === sortedBreakpoints.length - 1
          ? 0
          : sortedBreakpoints[index + 1][1];
      const maxWidth = breakpointValue;

      matchMediaRef.current.add(
        `(min-width: ${minWidth}px) and (max-width: ${maxWidth}px)`,
        () => {
          const timeline = gsap.timeline();
          // Check if animations exist for this breakpoint
          if (
            animationConfigs[breakpointName] &&
            Array.isArray(animationConfigs[breakpointName])
          ) {
            animationConfigs[breakpointName].forEach((animation) => {
              if (typeof animation.animate === "function") {
                // Wrap the animation in a context to ensure elements exist
                gsap.context(() => {
                  animation.animate(timeline);
                });
              }
            });
          }
        }
      );
    });

    return () => {
      matchMediaRef.current.kill();
    };
  }, dependencies);
};

export default useResponsiveGSAP;
