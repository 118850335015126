/**
 * Scrollbar.js
 *
 * Creates a stylized, interactive scrollbar that can be used in place of the default browser scrollbar.
 */

import React, { useRef, useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

/**
 * ScrollbarContainer
 *
 * Styled component for the outer container of the scrollbar.
 * It sets up a relative positioning context and hides overflow.
 */
const ScrollbarContainer = styled.div`
  position: relative;
  height: 100%;
  overflow: hidden;
`;

/**
 * ScrollbarContent
 *
 * Styled component for the content container.
 * It enables vertical scrolling while hiding the default scrollbar.
 */
const ScrollbarContent = styled.div`
  height: 100%;
  overflow-y: scroll;
  padding-right: 20px;
  margin-right: -20px;
  box-sizing: content-box;
  -webkit-overflow-scrolling: touch;

  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

/**
 * ScrollbarTrack
 *
 * Styled component for the scrollbar track.
 * It provides a background for the scrollbar thumb to move along.
 */
const ScrollbarTrack = styled.div`
  position: absolute;
  top: 2px;
  right: 2px;
  bottom: 2px;
  width: 8px;
  background-color: ${({ theme }) => theme.bodyBgSecondary};
  border-radius: 4px;
  opacity: 1;
  transition: opacity 0.2s;
  z-index: 1000;
`;

/**
 * ScrollbarThumb
 *
 * Styled component for the scrollbar thumb.
 * It represents the draggable part of the scrollbar.
 */
const ScrollbarThumb = styled.div`
  position: absolute;
  width: 8px;
  background-color: ${({ theme }) => theme.primaryColor};
  border-radius: 4px;
  cursor: pointer;
  opacity: 0.7;
  transition:
    opacity 0.2s,
    top 0.1s;
  z-index: 1001;

  &:hover {
    opacity: 1;
  }
`;

/**
 * Scrollbar Component
 *
 * A custom scrollbar component that provides a stylized alternative to the browser's default scrollbar.
 *
 * @param {React.ReactNode} children - The content to be scrolled
 * @param {Object} theme - The theme object for styling
 * @param {boolean} forceScrollbar - Whether to always show the scrollbar, even when content doesn't overflow
 * @param {Object} style - Additional styles for the scrollbar container
 * @returns {React.Component} The Scrollbar component
 */
const Scrollbar = React.forwardRef(
  ({ children, theme, forceScrollbar, style }, ref) => {
    const contentRef = useRef(null);
    const thumbRef = useRef(null);
    const trackRef = useRef(null);
    const [thumbHeight, setThumbHeight] = useState(20);
    const [thumbTop, setThumbTop] = useState(0);
    const [isDragging, setIsDragging] = useState(false);
    const [startY, setStartY] = useState(0);

    /**
     * Updates the scrollbar thumb's height and position based on content scroll
     */
    const updateScrollbarThumb = useCallback(() => {
      if (!contentRef.current || !trackRef.current) return;

      const { scrollTop, scrollHeight, clientHeight } = contentRef.current;
      const trackHeight = trackRef.current.clientHeight;

      const newThumbHeight = Math.max(
        (clientHeight / scrollHeight) * trackHeight,
        20
      );
      const scrollRatio = scrollTop / (scrollHeight - clientHeight);
      const newThumbTop = scrollRatio * (trackHeight - newThumbHeight);

      setThumbHeight(newThumbHeight);
      setThumbTop(newThumbTop);
    }, []);

    /**
     * Effect to handle initial setup and window resize events
     */
    useEffect(() => {
      updateScrollbarThumb();
      window.addEventListener("resize", updateScrollbarThumb);
      return () => window.removeEventListener("resize", updateScrollbarThumb);
    }, [updateScrollbarThumb]);

    /**
     * Effect to update scrollbar when children change
     */
    useEffect(() => {
      updateScrollbarThumb();
    }, [children, updateScrollbarThumb]);

    /**
     * Handles scroll events on the content
     */
    const handleScroll = useCallback(
      (e) => {
        e.stopPropagation();
        if (!isDragging) {
          updateScrollbarThumb();
        }
      },
      [isDragging, updateScrollbarThumb]
    );

    /**
     * Handles the start of dragging the scrollbar thumb
     */
    const handleMouseDown = useCallback(
      (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(true);
        setStartY(e.clientY - thumbTop);
      },
      [thumbTop]
    );

    /**
     * Handles the dragging of the scrollbar thumb
     */
    const handleMouseMove = useCallback(
      (e) => {
        if (!isDragging || !contentRef.current || !trackRef.current) return;

        const { scrollHeight, clientHeight } = contentRef.current;
        const trackHeight = trackRef.current.clientHeight;

        const newTop = e.clientY - startY;
        const maxTop = trackHeight - thumbHeight;
        const boundedTop = Math.max(0, Math.min(newTop, maxTop));
        const scrollRatio = boundedTop / maxTop;

        contentRef.current.scrollTop =
          scrollRatio * (scrollHeight - clientHeight);
        setThumbTop(boundedTop);
      },
      [isDragging, startY, thumbHeight]
    );

    /**
     * Handles the end of dragging the scrollbar thumb
     */
    const handleMouseUp = useCallback(() => {
      setIsDragging(false);
    }, []);

    /**
     * Handles clicks on the scrollbar track
     */
    const handleTrackClick = useCallback((e) => {
      e.preventDefault();
      e.stopPropagation();
      if (!contentRef.current || !trackRef.current) return;

      const { clientHeight, scrollHeight } = contentRef.current;
      const trackRect = trackRef.current.getBoundingClientRect();
      const clickRatio = (e.clientY - trackRect.top) / trackRect.height;

      contentRef.current.scrollTop = clickRatio * (scrollHeight - clientHeight);
    }, []);

    /**
     * Effect to add and remove mouse event listeners for dragging
     */
    useEffect(() => {
      if (isDragging) {
        window.addEventListener("mousemove", handleMouseMove);
        window.addEventListener("mouseup", handleMouseUp);
      } else {
        window.removeEventListener("mousemove", handleMouseMove);
        window.removeEventListener("mouseup", handleMouseUp);
      }
      return () => {
        window.removeEventListener("mousemove", handleMouseMove);
        window.removeEventListener("mouseup", handleMouseUp);
      };
    }, [isDragging, handleMouseMove, handleMouseUp]);

    return (
      <ScrollbarContainer style={style}>
        <ScrollbarContent ref={contentRef} onScroll={handleScroll}>
          {children}
        </ScrollbarContent>
        {(forceScrollbar ||
          (contentRef.current &&
            contentRef.current.scrollHeight >
              contentRef.current.clientHeight)) && (
          <ScrollbarTrack
            ref={trackRef}
            className="scrollbar-track"
            onClick={handleTrackClick}
          >
            <ScrollbarThumb
              ref={thumbRef}
              className="scrollbar-thumb"
              style={{
                height: `${thumbHeight}px`,
                top: `${thumbTop}px`,
              }}
              onMouseDown={handleMouseDown}
            />
          </ScrollbarTrack>
        )}
      </ScrollbarContainer>
    );
  }
);

Scrollbar.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.object.isRequired,
  forceScrollbar: PropTypes.bool,
  style: PropTypes.object,
};

Scrollbar.displayName = "Scrollbar";

export default Scrollbar;

// Usage example:
//
// import Scrollbar from './Scrollbar';
// import { ThemeProvider } from 'styled-components';
//
// const theme = {
//   bodyBgSecondary: '#f0f0f0',
//   primaryColor: '#007bff'
// };
//
// const MyComponent = () => {
//   return (
//     <ThemeProvider theme={theme}>
//       <div style={{ height: '400px' }}>
//         <Scrollbar>
//           {/* Your scrollable content goes here */}
//           <div style={{ height: '1000px' }}>
//             {/* Long content that requires scrolling */}
//           </div>
//         </Scrollbar>
//       </div>
//     </ThemeProvider>
//   );
// };
//
// This example shows how to use the Scrollbar component within a ThemeProvider.
// The Scrollbar will automatically appear when the content exceeds the container's height.
// We can customize the scrollbar's appearance by modifying the theme object.
