import React, { useState } from "react";
import {
  OrbitControls,
  OrthographicCamera,
  Billboard,
  PerspectiveCamera,
  Sky,
} from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import PlainCardPortal from "../Portals/PlainCardPortal";
import * as THREE from "three";
import CloudRenderTarget from "../RenderTargets/CloudRenderTarget";
import PortalsRenderTarget from "../RenderTargets/PortalsRenderTarget";
import Testimonial from "../Testimonial/Testimonial";
import CloudStream from "../Clouds/CloudStream";
import { useTheme } from "../../context/ThemeContext";

export default function Experience() {
  const { theme } = useTheme();
  return (
    <Canvas className="canvas">
      <color attach="background" args={[theme.bodyBgPrimary]} />
      <ambientLight intensity={2} />
      <directionalLight intensity={1} position={[-5, 4, -8]} />
      {/* <PlainCardPortal name="Basic" position={[0, 0, 2]} scale={0.5} /> */}
      {/* <PortalsRenderTarget /> */}
      {/* <Billboard>
        <CloudRenderTarget />
      </Billboard> */}
      <CloudStream />
      <PerspectiveCamera
        makeDefault
        position={[0, -0.5, 10]}
        fov={30}
        near={0.1}
        far={1000}
      />
      {/* <OrbitControls /> */}
    </Canvas>
  );
}
