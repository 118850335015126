import { Cloud, Clouds, PerspectiveCamera, Sky } from "@react-three/drei";
import * as THREE from "three";
import React, { useRef } from "react";
import { useTheme } from "../../context/ThemeContext";
import ThunderCloud from "./ThunderCloud";

function CloudStream({ texture }) {
  const { theme } = useTheme();
  const groupRef = useRef();

  return (
    <>
      <PerspectiveCamera position={[3, 3, 8]} near={0.5} />
      <ambientLight intensity={10} color={theme.lightColor3} />
      <group ref={groupRef}>
        <ThunderCloud
          seed={10}
          position={[0, 5, 0.32]}
          groupRef={groupRef}
          color={theme.thunderColor1}
          lightColor={theme.lightColor1}
          texture={texture}
          maxLightIntensity={1000}
        />
        <ThunderCloud
          seed={20}
          position={[10, -5, 0.32]}
          groupRef={groupRef}
          color={theme.thunderColor2}
          lightColor={theme.lightColor1}
          texture={texture}
          maxLightIntensity={1000}
        />
        <ThunderCloud
          seed={30}
          position={[-10, -5, 0.475]}
          groupRef={groupRef}
          color={theme.thunderColor3}
          lightColor={theme.lightColor1}
          texture={texture}
          maxLightIntensity={1000}
        />
        <Clouds
          limit={400}
          material={THREE.MeshBasicMaterial}
          texture={texture}
        >
          <Cloud
            position={[0, -5, 0.25]}
            speed={0.05}
            opacity={0.5}
            segments={20}
            width={30}
            depth={1.5}
            volume={10}
            color={theme.cloudColor1}
            texture={texture}
          />
          <Cloud
            position={[0, 0, -0.5]}
            speed={0.05}
            opacity={0.5}
            segments={20}
            width={50}
            depth={1.5}
            volume={10}
            color={theme.cloudColor2}
            texture={texture}
          />
          <Cloud
            position={[-10, -5, -0.5]}
            speed={0.05}
            opacity={0.5}
            segments={20}
            width={40}
            depth={1.5}
            volume={10}
            color={theme.cloudColor3}
            texture={texture}
          />
          <Cloud
            position={[10, 0, -0.5]}
            speed={0.05}
            opacity={0.5}
            segments={20}
            width={40}
            depth={1.5}
            volume={10}
            color={theme.cloudColor1}
            texture={texture}
          />
        </Clouds>
      </group>
    </>
  );
}

export default CloudStream;
