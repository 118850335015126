import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { fetchCaseStudy } from "../../services/CaseStudyService";
import HeaderSection from "./HeaderSection";
import ContentSection from "./ContentSection";
import ChallengesSection from "./ChallengesSection";
import CreditsSection from "./CreditsSection";
import MissionSection from "./MissionSection";
import OutcomeSection from "./OutcomeSection";
import ImpactSection from "./ImpactSection";
import ProjectsSection from "./ProjectsSection";
import ScopeSection from "./ScopeSection";
import ClosingSection from "./ClosingSection";
import Skeleton from "react-loading-skeleton";

const CaseStudyDetail = () => {
  const [caseStudy, setCaseStudy] = useState(null);
  const [loading, setLoading] = useState(true);
  const { caseStudySlug } = useParams();

  useEffect(() => {
    const getCaseStudy = async () => {
      try {
        const data = await fetchCaseStudy(caseStudySlug);
        setCaseStudy(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching case study detail:", error);
        setLoading(false);
      }
    };
    getCaseStudy();
  }, [caseStudySlug]);

  if (loading)
    return (
      <div className="full-bleed--container">
        <div className="case-study__wrapper">
          <HeaderSection loading={loading} />
          <div className="case-study__summary">
            <MissionSection loading={loading} />
            <ChallengesSection loading={loading} />
            <ScopeSection loading={loading} />
            <OutcomeSection loading={loading} />
            <ImpactSection loading={loading} />
          </div>
          <ContentSection loading={loading} />
          <ProjectsSection loading={loading} />
          <ClosingSection loading={loading} />
        </div>
      </div>
    );

  if (!caseStudy) return <div>Case study not found.</div>;

  return (
    <div className="full-bleed--container">
      <div className="case-study__wrapper">
        <HeaderSection
          name={caseStudy.name}
          company={caseStudy.company}
          image={caseStudy.featured_img}
          loading={loading}
        />
        <div className="case-study__summary">
          <MissionSection mission={caseStudy.mission} loading={loading} />
          {caseStudy.challenges && caseStudy.challenges.length > 0 && (
            <ChallengesSection
              challenges={caseStudy.challenges}
              loading={loading}
            />
          )}
          {caseStudy.scope && caseStudy.scope.length > 0 && (
            <ScopeSection scope={caseStudy.scope} loading={loading} />
          )}
          {caseStudy.outcomes && caseStudy.outcomes.length > 0 && (
            <OutcomeSection outcomes={caseStudy.outcomes} loading={loading} />
          )}
          {caseStudy.impacts && caseStudy.impacts.length > 0 && (
            <ImpactSection impacts={caseStudy.impacts} loading={loading} />
          )}
        </div>
        <ContentSection caseStudy={caseStudy} loading={loading} />
        {caseStudy.projects && caseStudy.projects.length > 0 && (
          <ProjectsSection projects={caseStudy.projects} loading={loading} />
        )}
        {caseStudy.closing_thoughts && (
          <ClosingSection
            closingThoughts={caseStudy.closing_thoughts}
            loading={loading}
          />
        )}
        {/* {caseStudy.credits && caseStudy.credits.length > 0 && (
          <CreditsSection credits={caseStudy.credits} loading={loading} />
        )} */}
      </div>
    </div>
  );
};

export default CaseStudyDetail;
