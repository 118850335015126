// components/SlideOut/SlideOut.jsx

import React, { useRef, useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { useTheme } from "../../context/ThemeContext";
import { useViewport } from "../../context/ViewportContext";
import useResponsiveGSAP from "../../hooks/useResponsiveGSAP";
import { X, ChevronLeft, ChevronRight } from "lucide-react";
import { breakpoints } from "../../styles/breakpoints";
import { hexToRgba } from "../../utils/hexToRGBA";

const StyledSlideOut = styled.div`
  position: absolute;
  grid-column: 1;
  display: grid;
  height: 100%;
  max-height: 100vh;
  z-index: 985;
  pointer-events: auto;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-right: 2px solid ${({ theme }) => theme.bodyBgPrimary};

  ${({ $viewport, theme, isCollapsed }) => {
    const { width, isLandscape } = $viewport;

    let mainBg = hexToRgba(theme.navBg, 0.25);
    if (!isCollapsed) {
      mainBg = hexToRgba(theme.navBg, 0.95);
    }
    if (isLandscape) {
      mainBg = hexToRgba(theme.navBg, 0.25);
    }

    if (width <= breakpoints.mobileL) {
      return `
        background-color: ${mainBg};
        grid-template-columns: ${isCollapsed ? "3rem" : "3rem 1fr"};
        grid-template-rows: ${isLandscape ? "2.75rem 1fr 2.75rem" : "3rem 1fr 3rem"};
        width: ${isCollapsed ? "3rem" : "100%"};
        border-right: ${isCollapsed ? "none" : `2px solid ${theme.primaryColor}`};
      `;
    } else if (width <= breakpoints.tabletS) {
      return `
        background-color: ${mainBg};
        grid-template-columns: ${isCollapsed ? "3.5rem" : "3.5rem 1fr"};
        grid-template-rows: ${isLandscape ? "3rem 1fr 3rem" : "3.5rem 1fr"};
        width: ${isCollapsed ? "3.5rem" : "100%"};
        border-right: ${isCollapsed ? "none" : `2px solid ${({ theme }) => theme.primaryColor}`};
      `;
    } else if (width <= breakpoints.tabletM) {
      return `
        background-color: ${mainBg};
        grid-template-columns: ${
          isCollapsed ? "3.5rem" : isLandscape ? "2.75rem" : "3.5rem 1fr 3.5rem"
        };
        grid-template-rows: ${isLandscape ? "3.25rem 1fr 3.25rem" : "3.5rem 1fr 3.5rem"};
        width: ${isCollapsed ? "2.75rem" : "100%"};
        border-right: ${isCollapsed ? "none" : `2px solid ${({ theme }) => theme.primaryColor}`};
      `;
    } else if (width <= breakpoints.tabletL) {
      return `
        background-color: ${mainBg};
        grid-template-columns: ${isCollapsed ? "3.5rem" : isLandscape ? "2.75rem" : "3.5rem 1fr 3.5rem"};
        grid-template-rows: 3.5rem 1fr 3.5rem;
        width: ${isCollapsed ? "3.5rem" : isLandscape ? "2.75rem" : "100%"};
        border-right: ${isCollapsed ? "none" : `2px solid ${({ theme }) => theme.primaryColor}`};
      `;
    } else if (width <= breakpoints.laptopL) {
      return `
        grid-template-columns: 5.25rem 1fr;
        grid-template-rows: 5.25rem 1fr 5.25rem;
        width: ${isCollapsed ? "5.25rem" : "25rem"};
        border-right: 2px solid ${({ theme }) => theme.primaryColor};
      `;
    } else if (width <= breakpoints.desktop && !isCollapsed) {
      return `
        width: 25rem;
        grid-template-columns: 5.25rem 1fr;
        grid-template-rows: 5.25rem 1fr;
      `;
    } else if (width <= breakpoints.desktopL && !isCollapsed) {
      return `
        width: 25rem;
        grid-template-columns: 5.25rem 1fr;
      `;
    } else {
      return `
        background-color: ${mainBg};
      `;
    }
  }}
`;

const CollapseButton = styled.div`
  position: absolute;
  bottom: 1.25rem;
  right: -2.25rem;
  background-color: ${({ theme }) => theme.primaryColor};
  height: 50px;
  width: 35px;
  border-radius: 0 10px 10px 0;
  z-index: 950;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition:
    right 0.3s ease-in-out,
    background-color 0.2s ease-in-out;
  color: ${({ theme }) => theme.textColorLight};

  &:hover {
    color: ${({ theme }) => theme.accentColorPrimary};
  }

  ${({ $viewport, isCollapsed }) => {
    if ($viewport.width <= breakpoints.mobileS) {
      return `
        right: ${isCollapsed ? "1rem" : "0"};
        bottom: ${isCollapsed ? "0.5rem" : "0.5rem"};
        height: 40px;
        width: 40px;
        ${
          $viewport.isLandscape
            ? `
            bottom: 0.25rem;
            height: 35px;
            width: 35px;
          `
            : ""
        }
      `;
    }
    if ($viewport.width <= breakpoints.mobileM) {
      return `
        right: ${isCollapsed ? "1rem" : "0"};
        bottom: ${isCollapsed ? "0.5rem" : "0.5rem"};
        height: 40px;
        width: 40px;
      `;
    }
    if ($viewport.width <= breakpoints.mobileL) {
      return `
        right: ${isCollapsed ? "1rem" : "0"};
        bottom: ${isCollapsed ? "0.5rem" : "0.5rem"};
        height: 40px;
        width: 40px;
      `;
    }
    if ($viewport.width <= breakpoints.tabletS) {
      return `
        right: ${isCollapsed ? "1rem" : "0"};
        bottom: ${isCollapsed ? "0.5rem" : "0.5rem"};
        height: 40px;
        width: 40px;
      `;
    }
    if ($viewport.width <= breakpoints.tabletM) {
      return `
        right: ${isCollapsed ? "1rem" : "0"};
        bottom: ${isCollapsed ? "0.5rem" : "0.5rem"};
        height: 30px;
        width: 30px;
        border-radius: ${isCollapsed ? "5px" : "0 5px 5px 0"};

      `;
    }
    if ($viewport.width <= breakpoints.tabletL) {
      return `
        right: ${isCollapsed ? "1rem" : "0"};
        bottom: ${isCollapsed ? "0.5rem" : "0.5rem"};
        height: 40px;
        width: 40px;

      `;
    }
    if ($viewport.width <= breakpoints.laptopS) {
      return `
        right: ${isCollapsed ? "1rem" : "0"};
        bottom: ${isCollapsed ? "0.5rem" : "0.5rem"};
        height: 40px;
        width: 40px;
        right: ${isCollapsed ? "1rem" : "0"};
        bottom: ${isCollapsed ? "0.5rem" : "0.5rem"};
        height: 40px;
        width: 40px;
      `;
    }
    if ($viewport.width <= breakpoints.laptopL) {
      return `
        right: ${isCollapsed ? "1rem" : "0"};
        bottom: ${isCollapsed ? "0.5rem" : "0.75rem"};
        height: 2.75rem;
        width: 2.25rem;

      `;
    }
    if ($viewport.width <= breakpoints.desktop) {
      return `

      `;
    }
    if ($viewport.width <= breakpoints.desktopL) {
      return `

      `;
    }
  }}
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.primaryColor};
  transition: color 0.2s ease-in-out;

  &:hover {
    color: ${({ theme }) => theme.accentColorPrimary};
  }
`;

const SlideOut = ({
  children,
  isCollapsed,
  onCollapse,
  animations,
  selectedItem,
  onItemSelect,
  onBackClick,
  isLoading,
  showCloseButton,
}) => {
  const { theme } = useTheme();
  const viewport = useViewport();
  const slideOutRef = useRef(null);
  const collapseButtonRef = useRef(null);
  const [isContentReady, setIsContentReady] = useState(false);

  useResponsiveGSAP(animations, [isCollapsed, theme, viewport, isContentReady]);

  useEffect(() => {
    setIsContentReady(true);
  }, []);

  const childrenProps = {
    selectedItem,
    onItemSelect,
    onBackClick,
    isCollapsed,
    showCloseButton,
    theme,
    closeButton: showCloseButton && selectedItem && (
      <CloseButton onClick={onBackClick} theme={theme}>
        <X size={16} />
      </CloseButton>
    ),
  };

  return (
    <StyledSlideOut
      className="slide-out"
      theme={theme}
      $viewport={viewport}
      ref={slideOutRef}
      isCollapsed={isCollapsed}
    >
      <CollapseButton
        className="collapse-button"
        theme={theme}
        onClick={onCollapse}
        ref={collapseButtonRef}
        $viewport={viewport}
        isCollapsed={isCollapsed}
      >
        {isCollapsed ? <ChevronRight size={20} /> : <ChevronLeft size={20} />}
      </CollapseButton>
      {typeof children === "function"
        ? children(childrenProps)
        : React.Children.map(children, (child) =>
            React.cloneElement(child, childrenProps)
          )}
    </StyledSlideOut>
  );
};

export default SlideOut;
