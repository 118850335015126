// Pagination.js

import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import { useTheme } from "../../context/ThemeContext";
import { useViewport } from "../../context/ViewportContext";
import { breakpoints, heightBreakpoints } from "../../styles/breakpoints";

let gridColumn = 2;

const PaginationWrapper = styled.div`
  margin: 1.25rem 1rem 5rem 1rem;
  grid-column: ${gridColumn};
  grid-row: 2/-1;
  gap: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  pointer-events: auto;
  z-index: 1300;

  ${({ $viewport }) => {
    const { width, height, isLandscape } = $viewport;

    if (width <= breakpoints.mobileS) {
      return `
        margin: 0 0.75rem 0 0;
        align-items: center;
      `;
    } else if (width <= breakpoints.mobileM) {
      return `
        margin: 0 0.25rem 0 0;
        align-items: center;
      `;
    } else if (width <= breakpoints.mobileL) {
      return `
        margin: 0 0.25rem 0 0;
        align-items: center;
      `;
    } else if (width <= breakpoints.tabletS) {
      return `
        margin: 0 0.75rem 5rem 0;
        align-items: flex-start;

        ${
          isLandscape
            ? `
              align-items: center;
              margin-top: 0;
              height: 100%;
            `
            : ""
        }
      `;
    } else if (width <= breakpoints.tabletM) {
      if (isLandscape) {
        gridColumn = 2;
      }
      return `
        grid-column: ${gridColumn};
        margin: 0 0.25rem 0 0;
        align-items: flex-start;

        ${
          isLandscape
            ? `
              align-items: center;
              margin-top: 0;
              height: 100%;
              grid-column: ${gridColumn};
            `
            : ""
        }
      `;
    } else if (width <= breakpoints.tabletL) {
      if (isLandscape || height <= heightBreakpoints.heightXS) {
        gridColumn = 3;
      } else {
        gridColumn = 2;
      }

      return `
        grid-column: ${gridColumn};
        margin: 0 0.25rem 0 0;
        align-items: flex-start;

        ${
          isLandscape
            ? `
              grid-column: ${gridColumn};
              align-items: center;
              margin-top: 0;
              height: 100%;
            `
            : ""
        }
      `;
    } else if (width <= breakpoints.laptopS) {
      return `
        grid-column: ${gridColumn};
        margin: 0 0.75rem 0 0;
      `;
    } else if (width <= breakpoints.laptopL) {
      return `
        grid-column: ${gridColumn};
        margin: 0 0.75rem 0 0;
      `;
    } else if (width <= breakpoints.desktop) {
      return `
        grid-column: ${gridColumn};
        margin: 0 0.75rem 0 0;
      `;
    } else if (width <= breakpoints.desktopL) {
      return `
        grid-column: 3;
        margin: 0 0.75rem 0 0;
      `;
    }
  }}
`;

const PaginationButton = styled.button`
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.accentColorPrimary : theme.primaryColor};
  color: ${({ theme, isActive }) =>
    isActive ? theme.textColorDark : theme.textColorLight};
  border: none;
  border-radius: 7.5px;
  width: 2.5rem;
  height: 2.5rem;
  margin: 0.25rem;
  cursor: ${({ isActive }) => (isActive ? "default" : "pointer")};
  transition: background-color 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: ${({ theme, isActive }) =>
      isActive ? theme.accentColorPrimary : theme.accentColorSecondary};
  }

  ${({ $viewport }) => {
    if ($viewport.width <= breakpoints.laptopS) {
      return `
        width: 2rem;
        height: 2rem;
        margin: 0.125rem;
      `;
    }
  }}
`;

function Pagination({ currentPage, totalItems, itemsPerPage, onPageChange }) {
  const { theme } = useTheme();
  const viewport = useViewport();
  const [maxVisiblePages, setMaxVisiblePages] = useState(5);

  useEffect(() => {
    if (viewport.width <= breakpoints.mobileL) {
      setMaxVisiblePages(5);
    } else if (viewport.width <= breakpoints.tabletM) {
      setMaxVisiblePages(6);
    } else if (viewport.width <= breakpoints.laptopS) {
      setMaxVisiblePages(7);
    } else {
      setMaxVisiblePages(8);
    }
  }, [viewport.width]);

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const pageNumbers = useMemo(() => {
    if (totalPages <= 1) return [];

    const numbers = [];
    let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
    let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

    if (endPage - startPage + 1 < maxVisiblePages) {
      startPage = Math.max(1, endPage - maxVisiblePages + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      numbers.push(i);
    }

    return numbers;
  }, [currentPage, totalPages, maxVisiblePages]);

  if (totalPages <= 1 || totalItems <= 0 || itemsPerPage <= 0) {
    return null;
  }

  return (
    <PaginationWrapper
      $viewport={viewport}
      role="navigation"
      aria-label="Pagination"
    >
      {pageNumbers.map((number) => (
        <PaginationButton
          key={number}
          theme={theme}
          isActive={currentPage === number}
          onClick={() => onPageChange(number)}
          $viewport={viewport}
          aria-label={`Go to page ${number}`}
          aria-current={currentPage === number ? "page" : undefined}
        >
          {number}
        </PaginationButton>
      ))}
    </PaginationWrapper>
  );
}

export default Pagination;
