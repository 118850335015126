import React from "react";
import SVGIcon from "../SVGIcon";
import ImageRender from "../ImageRender";
import Card from "../Card/Card";

const CaseStudyCard = React.memo(({ caseStudy }) => {
  const renderImage = (caseStudy) => (
    <div className="case-study__img-wrapper">
      {caseStudy.company_logo && (
        <SVGIcon
          iconId={caseStudy.company_logo.icon_id}
          spriteSheetName={caseStudy.company_logo.sprite_sheet.filename}
          additionalClasses={["icon-placeholder", "case-study__logo"]}
        />
      )}
      {caseStudy.thumbnail_img && (
        <ImageRender
          item={caseStudy}
          imgField="thumbnail_img"
          additionalClasses={["case-study__img"]}
        />
      )}
    </div>
  );

  const renderContent = (caseStudy) => (
    <div className="case-study__info">
      <h3 className="subheading-2">{caseStudy.name}</h3>
      <p className="case-study__excerpt">{caseStudy.excerpt}</p>
    </div>
  );

  const renderButton = (caseStudy) => (
    <div className="case-study__footer">
      <a href={`#/case-studies/${caseStudy.slug}`} className="btn btn--primary">
        Read More
      </a>
    </div>
  );

  return (
    <Card
      item={caseStudy}
      renderImage={renderImage}
      renderContent={renderContent}
      renderButton={renderButton}
      additionalClasses="case-study"
    />
  );
});

export default CaseStudyCard;
