import React from "react";
import Markdown from "react-markdown";
import Skeleton from "react-loading-skeleton";

const TestimonialSection = ({ testimonial, loading }) => {
  return (
    <section className="case-study__testimonial">
      <div className="testimonial">
        {loading ? (
          <Skeleton count={3} />
        ) : (
          <>
            <blockquote>{testimonial.quote}</blockquote>
            <div className="testimonial__author">
              {testimonial.avatar && (
                <img src={testimonial.avatar} alt={testimonial.author_name} />
              )}
              <div className="testimonial__author-details">
                <p className="testimonial__author-name subheading-1">
                  {testimonial.author_name}
                </p>
                <Markdown className="testimonial__author-role subheading-3">
                  {`${testimonial.role}, ${testimonial.company}`}
                </Markdown>
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default TestimonialSection;
