// CaseStudyPage.jsx
import CaseStudyList from "../components/CaseStudy/CaseStudyList";


const CaseStudiesPage = () => {
  return (
    <div className='full-bleed--container'>
      <CaseStudyList />
    </div>
  );
};

export default CaseStudiesPage;
