// context/ThemeContext.jsx

import React, { createContext, useContext, useState, useEffect } from "react";
import { themes } from "../styles/abstracts/themes";

/**
 * Theme Context
 *
 * Provides a React context and custom hook for managing the application's theme.
 * It allows components to access and toggle the current theme from anywhere in the component tree.
 */

// Create a context for the theme
const ThemeContext = createContext();

/**
 * Custom hook to use the theme
 *
 * This hook allows components to easily access the current theme and toggle function.
 *
 * @returns {Object} An object containing the current theme and toggleTheme function
 * @throws {Error} If used outside of a ThemeProvider
 */
export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }
  return context;
};

/**
 * ThemeProvider component
 *
 * This component wraps all or part of the app to provide the theme state to all child components.
 * It also handles theme persistence using localStorage.
 *
 * @param {Object} props - The component props
 * @param {React.ReactNode} props.children - The child components
 */
export const ThemeProvider = ({ children }) => {
  // State to hold the current theme
  const [theme, setTheme] = useState(themes.light);

  // Effect to load the saved theme on component mount
  useEffect(() => {
    // Retrieve the saved theme name from localStorage, default to 'light' if not found
    const savedThemeName = localStorage.getItem("themeName") || "light";
    // Get the corresponding theme object
    const savedTheme = themes[savedThemeName];
    // Set the theme state
    setTheme(savedTheme);
    // Apply the theme class to the body element
    document.body.className = savedThemeName;
  }, []);

  /**
   * Toggle between light and dark themes
   * This function switches the current theme, saves it to localStorage,
   * and updates the body class.
   */
  const toggleTheme = () => {
    // Determine the new theme name
    const newThemeName = theme === themes.light ? "dark" : "light";
    // Get the new theme object
    const newTheme = themes[newThemeName];
    // Update the theme state
    setTheme(newTheme);
    // Save the new theme name to localStorage
    localStorage.setItem("themeName", newThemeName);
    // Update the body class to reflect the new theme
    document.body.className = newThemeName;
  };

  // Provide the theme and toggle function to all children
  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

// Usage example:
//
// import { ThemeProvider, useTheme } from './context/ThemeContext';
//
// // In app's root component:
// function App() {
//   return (
//     <ThemeProvider>
//       {/* Your app components */}
//     </ThemeProvider>
//   );
// }
//
// // In any child component:
// function ChildComponent() {
//   const { theme, toggleTheme } = useTheme();
//
//   return (
//     <div style={{ backgroundColor: theme.backgroundColor, color: theme.textColor }}>
//       <button onClick={toggleTheme}>Toggle Theme</button>
//       {/* Rest of your component */}
//     </div>
//   );
// }
