/**
 * SlideOut Context and Hook
 *
 * Provides a React context and custom hook for managing a slide-out component's state.
 * It allows components to access and update the slide-out state from anywhere in the component tree.
 */

import React, { createContext, useContext, useState, useCallback } from "react";

// Create a context for the slide-out state
const SlideOutContext = createContext();

/**
 * SlideOutProvider component
 *
 * This component wraps all or part of the app to provide the slide-out state to all child components.
 *
 * @param {Object} props - The component props
 * @param {React.ReactNode} props.children - The child components
 */
export const SlideOutProvider = ({ children }) => {
  // State to track if the slide-out is present in the DOM
  const [isSlideOutPresent, setIsSlideOutPresent] = useState(false);

  // State to track if the slide-out is collapsed (closed) or expanded
  const [isSlideOutCollapsed, setIsSlideOutCollapsed] = useState(true);

  /**
   * Update both slide-out states at once
   *
   * @param {boolean} isPresent - Whether the slide-out should be present in the DOM
   * @param {boolean} isCollapsed - Whether the slide-out should be collapsed
   */
  const updateSlideOutState = useCallback((isPresent, isCollapsed) => {
    setIsSlideOutPresent(isPresent);
    setIsSlideOutCollapsed(isCollapsed);
  }, []);

  // Provide the state and update function to all children
  return (
    <SlideOutContext.Provider
      value={{
        isSlideOutPresent,
        isSlideOutCollapsed,
        updateSlideOutState,
      }}
    >
      {children}
    </SlideOutContext.Provider>
  );
};

/**
 * Custom hook to use the slide-out state
 *
 * This hook allows components to easily access and update the slide-out state.
 *
 * @returns {Object} An object containing the slide-out state and update function
 * @throws {Error} If used outside of a SlideOutProvider
 */
export const useSlideOut = () => {
  const context = useContext(SlideOutContext);
  if (!context) {
    throw new Error("useSlideOut must be used within a SlideOutProvider");
  }
  return context;
};

// Usage example:
//
// import { SlideOutProvider, useSlideOut } from './path-to-this-file';
//
// // In app's root component:
// function App() {
//   return (
//     <SlideOutProvider>
//       {/* Your app components */}
//     </SlideOutProvider>
//   );
// }
//
// // In any child component:
// function ChildComponent() {
//   const { isSlideOutPresent, isSlideOutCollapsed, updateSlideOutState } = useSlideOut();
//
//   // Use the state or update it
//   const openSlideOut = () => updateSlideOutState(true, false);
//
//   return (
//     <button onClick={openSlideOut}>Open Slide-out</button>
//   );
// }
