/**
 * hexToRgb.js
 *
 * Converts a hexadecimal color code to an RGB color string.
 * Supports both shorthand (e.g., #FFF) and full (e.g., #FFFFFF) hex codes.
 *
 * @param {string} hex - The hexadecimal color code to convert (with or without the leading '#')
 * @returns {string|null} A comma-separated RGB string (e.g., "255, 255, 255") or null if invalid input
 * @throws {TypeError} If the input is not a string
 */
export function hexToRgb(hex) {
  if (typeof hex !== "string") {
    throw new TypeError("Input must be a string");
  }

  // Convert shorthand hex to full form
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);

  // Parse the hex values
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  // Convert to RGB if valid, otherwise return null
  return result
    ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(
        result[3],
        16
      )}`
    : null;
}

// Usage example:
//
// import { hexToRgb } from './color-converter.js';
//
// const rgbColor = hexToRgb('#FF5733');
// console.log(rgbColor); // Output: "255, 87, 51"
//
// const shorthandRgb = hexToRgb('#F00');
// console.log(shorthandRgb); // Output: "255, 0, 0"
//
// const invalidHex = hexToRgb('not a hex color');
// console.log(invalidHex); // Output: null

/**
 * Note: This function assumes that the input is a valid hexadecimal color code.
 * It handles both formats with or without the leading '#'.
 * For invalid inputs, it returns null instead of throwing an error to allow for easier error handling.
 *
 * Common pitfalls:
 * 1. Ensure the input is a string, or a TypeError will be thrown.
 * 2. The function does not validate if the hex code represents a real color.
 * 3. Alpha channel values (e.g., #FF5733AA) are not supported and will return null.
 */
